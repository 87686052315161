import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    useApplicationRoleApplications,
    useCheckApplicationRoleAccess,
    useTargetPerson,
} from 'hooks'
import { ApplicationsList, ItemDetails, TCodesList } from 'components'
import useSubcomponents from 'useSubcomponents'
import cartHelpers from 'containers/Cart/cartHelpers'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { AddToCart } from 'components/AddToCart'
import { Skeleton } from '@material-ui/lab'
import { Box } from '@material-ui/core'
import ActivateNowButton from '../ActivateNowButton'
import useConfiguration from 'useConfiguration'

const ApplicationRoleDetails = ({ applicationRole, toggleDrawer }) => {
    const { t } = useTranslation()
    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType('ApplicationRoles')

    const registry = useRegistry()

    const applicationRoleType = registry.get(
        ResourceTypesNamespace,
        'ApplicationRoles',
    )

    const { hasAccessToTCodesGrid, canSeeApplications } = useSubcomponents()

    const [targetPerson] = useTargetPerson()

    const {
        data: assignmentStatus,
        isLoading: isCheckingAccess,
    } = useCheckApplicationRoleAccess(applicationRole.id, targetPerson.id)

    const [activeTab, setActiveTab] = useState('overview')

    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.appRoleToCartItem({
            targetPerson,
            assignmentType: 'Add',
            applicationRole,
            assignmentStatus,
            ...baseObj,
        })

        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const getPreValidationMessage = () => {
        if (assignmentStatus && assignmentStatus.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }
        return ''
    }

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                {hasAccessToTCodesGrid && (
                    <ItemDetails.Tabs.Tab
                        value="tCodes"
                        label={t('Common_TCodes')}
                        data-protectedsubcomponent={hasAccessToTCodesGrid}
                    />
                )}

                {canSeeApplications && (
                    <ItemDetails.Tabs.Tab
                        value="applications"
                        label={t('Common_Applications')}
                        data-protectedsubcomponent={canSeeApplications}
                    />
                )}
            </ItemDetails.Tabs>
            {activeTab === 'overview' && (
                <OverviewSection applicationRole={applicationRole} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection applicationRole={applicationRole} />
            )}
            {activeTab === 'tCodes' && (
                <TCodesList resourceId={applicationRole.id} />
            )}
            {activeTab === 'applications' && (
                <ApplicationsList
                    resource={applicationRole}
                    useDataHook={useApplicationRoleApplications}
                />
            )}
            {isCheckingAccess ? (
                <Box padding={'24px'}>
                    <Skeleton height={40} />
                </Box>
            ) : applicationRole.canActivateNow ? (
                <ActivateNowButton
                    resource={applicationRole}
                    resourceType={currentResourceType}
                    forDetailsDrawer={true}
                    width="300px"
                />
            ) : (
                <AddToCart
                    resourceType={applicationRoleType}
                    resource={applicationRole}
                    preAdd={preAdd}
                    postAdd={toggleDrawer}
                    preValidate={getPreValidationMessage}
                />
            )}
        </Fragment>
    )
}

export default ApplicationRoleDetails
