import { Button } from 'components'
import { Typography } from '@material-ui/core'
import { useTerminateSession } from 'hooks'
import { useTranslation } from 'react-i18next'

const styles = {
    normal: {
        backgroundColor: '#307fc1',
        color: '#fff',
        border: 'solid 1px #307fc1',
        borderRadius: '5px',
        padding: '5px 8px 4px',
    },
    disabled: {
        backgroundColor: '#c5cbda !important',
        border: 'solid 1px #c5cbda',
        color: '#ffffff !important',
        opacity: '0.8',
    },

    label: {
        fontSize: '11px',
        lineHeight: 'normal',
    },
}

const ComputersTerminateButton = ({ data }) => {
    const { t } = useTranslation()
    const item = data?.resource

    const [terminateSession] = useTerminateSession()

    const handleTerminateSession = () => {
        terminateSession(item.id)
    }
    return (
        <>
            {item?.canTerminateSession ? (
                <Button
                    onClick={() => handleTerminateSession()}
                    style={styles.normal}
                    disabledStyles={styles.disabled}
                >
                    <Typography style={styles.label}>
                        {t('Common_Terminate')}
                    </Typography>
                </Button>
            ) : (
                <></>
            )}
        </>
    )
}

export default ComputersTerminateButton
