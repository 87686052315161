import { FC } from 'react'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Icon } from 'packages/eid-icons'
import { getFullImageUrl } from 'utils'
import { Tooltip } from 'packages/eid-ui'
import { Instructions } from 'components/ApplicationBanner/Instructions'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'

export interface IApplicationImageProps {
    attribute: any
    data: any
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '62px',
            minHeight: '32px',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
        icon: {
            position: 'absolute',
            right: '-8px',
            top: '-10px',
        },
    }),
)

const ApplicationImage: FC<IApplicationImageProps> = (props) => {
    const classes = useStyles()

    const { t } = useTranslation()

    const { attribute, data } = props

    const url = data[attribute.name]

    const getTooltipTitle = () => t('Common_MoreInfo')
    return (
        <Box className={classes.root}>
            <img
                style={{ maxHeight: '48px' }}
                src={
                    isNilOrEmpty(url)
                        ? getFullImageUrl(
                              '/webcdn/Images/AppLogos/Genric-1.png',
                          )
                        : getFullImageUrl(url)
                }
                alt="app logo"
            />

            {data && data.instructions && (
                <Instructions
                    title={data.friendlyName}
                    instructions={data.instructions}
                    button={(onClick: any) => (
                        <Tooltip title={getTooltipTitle()}>
                            <Box
                                className={classes.icon}
                                onClick={() => onClick(true)}
                            >
                                <Icon
                                    name="Info"
                                    color="#8b909a"
                                    width="24px"
                                    height="25px"
                                ></Icon>
                            </Box>
                        </Tooltip>
                    )}
                />
            )}
        </Box>
    )
}
export default ApplicationImage
