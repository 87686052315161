import { Pagination as MuiPagination } from '@material-ui/lab'
import {
    Box,
    InputBase,
    makeStyles,
    Typography,
    withStyles,
} from '@material-ui/core'
import DefaultItem from './PaginationItem'
import { useEffect, useState } from 'react'
import { Icon } from 'packages/eid-icons'
import { Select } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const StyledInput = withStyles(() => ({
    root: {
        width: 'auto',
        height: '40px',
        margin: '0px 8px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        textAlignLast: 'center',
        border: 'solid 1px #ebebed',
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
        },
        '& input[type=number]': {
            MozAppearance: 'textfield',
        },
    },
    input: {
        position: 'relative',
        fontSize: 16,
        color: '#aab0b4',
        padding: '9.5px 6px',
    },

    focused: {
        boxShadow: '0 2px 8px 0 rgba(48, 127, 193, 0.15)',
        border: 'solid 1px #307fc1',
    },
    error: {
        border: 'solid 1px red',
    },
}))(InputBase)

const useStyles = makeStyles({
    ul: (props) => ({
        ...props.styles,
    }),
})

const useGoToStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '30px',
        width: '24px',
        height: '40px',
        fontSize: '8px',
        color: '#8b909a',
        textTransform: 'uppercase',
        wordBreak: 'break-word',
    },
    button: {
        width: '40px',
        height: '40px',
        padding: '12px',
        borderRadius: '4px',
        border: 'solid 1px #01ae8f',
        backgroundColor: '#01ae8f',
        '&:hover': {
            cursor: 'pointer',
        },
    },
})

const usePagePerItemStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '30px',
        height: '40px',
        fontSize: '8px',
        color: '#8b909a',
        textTransform: 'uppercase',
        wordBreak: 'break-word',
    },
    select: {
        width: '100%',
        marginTop: '-8px',
    },
})
const useItenCountStyles = makeStyles({
    paginationCount: {
        marginLeft: '30px',
        '& p': {
            fontSize: '12px !important',
            color: '#5d6870 !important',
            marginTop: '8px',
            letterSpacing: '2px',
        },
        '& h5': {
            fontSize: '8px',
            textTransform: 'uppercase',
            color: '#8b909a',
        },
    },
})

const Pagination = (props) => {
    const {
        size,
        listStyles,
        roundedStyles,
        PaginationItemComponent: PaginationItem,
        variant,
        parentIndex,
        showPageSelection = false,
        onPageSelection,
        page,
        count,
        itemsPerPage,
        showItemsPerPageSelection = false,
        onItemsPerPageChange,
        totalCount,
        showCountStat = false,
        ...rest
    } = props

    const [goToPage, setGoToPage] = useState(page)

    useEffect(() => {
        setGoToPage(page)
    }, [page])

    const invalidGoToPage = !goToPage || goToPage < 1 || goToPage > count

    let itemsPerPageOptions = [
        {
            id: 15,
            value: 15,
        },
        {
            id: 25,
            value: 25,
        },
        {
            id: 50,
            value: 50,
        },
        {
            id: 75,
            value: 75,
        },
        {
            id: 100,
            value: 100,
        },
    ]

    if (showItemsPerPageSelection && itemsPerPage) {
        if (
            itemsPerPageOptions.findIndex((x) => x.value === itemsPerPage) < 0
        ) {
            itemsPerPageOptions.unshift({
                id: itemsPerPage,
                name: itemsPerPage.toString(),
                value: itemsPerPage,
            })
            itemsPerPageOptions = itemsPerPageOptions.sort(
                (a, b) => a.value - b.value,
            )
        }
    }

    const getOptionLabel = (option) => option.id.toString()

    const { t } = useTranslation()

    const defaultListStyles = {
        borderRadius: '5px',
        backgroundColor: '#ffffff',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        '& >li:first-child': {
            '& > button': {
                borderRadius: '5px 0px 0px 5px !important',
            },
        },
        '& >li:last-child': {
            '& > button': {
                borderRadius: '0px 5px 5px 0px !important',
            },
        },
        ...listStyles,
    }

    const defaultRoundedStyles = {
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        height: '24px',
        fontSize: '12px !important',
        boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.08)',
        justifyContent: 'space-evenly',
        '& >li': {
            '& > button': {
                borderRadius: '50%',
                height: '15px',
                minWidth: '15px',
                margin: '0px 5px !important',
                fontSize: '12px !important',
                border: 'none !important',
            },
            '& > div': {
                borderRadius: '50%',
                height: '15px',
                minWidth: '15px',
                border: 'none !important',
                margin: '0px 5px !important',
            },
        },
        ...roundedStyles,
    }

    const styles =
        variant === 'rounded' ? defaultRoundedStyles : defaultListStyles

    const classes = useStyles({ styles, variant })
    const gotoClasses = useGoToStyles()
    const pagePerItemClasses = usePagePerItemStyles()
    const pagePerCountClasses = useItenCountStyles()

    const getStartOfPage = () => {
        if (page === 1) {
            return 1
        }

        return (page - 1) * itemsPerPage + 1
    }
    const getEndOfPage = () => {
        return page * itemsPerPage > totalCount
            ? totalCount
            : page * itemsPerPage
    }
    return (
        <Box display="flex">
            <MuiPagination
                style={{
                    zIndex: parentIndex ? parentIndex : 9999,
                }}
                classes={classes}
                renderItem={(item) => (
                    <PaginationItem size={size} page={page} {...item} />
                )}
                size={size}
                page={page}
                count={count}
                {...rest}
            />
            {showPageSelection && onPageSelection && (
                <Box display="flex">
                    <Box className={gotoClasses.root}>{t('GoToPage')}</Box>
                    <StyledInput
                        value={goToPage}
                        onChange={(e) => setGoToPage(e.target.value)}
                        type="number"
                        inputProps={{ min: 1, max: count }}
                        error={invalidGoToPage}
                    />
                    {!invalidGoToPage && goToPage !== page && (
                        <Box
                            onClick={() => onPageSelection(Number(goToPage))}
                            className={gotoClasses.button}
                        >
                            <Icon
                                name="ArrowLong"
                                color="#fff"
                                direction="right"
                            />
                        </Box>
                    )}
                </Box>
            )}
            {itemsPerPage && showItemsPerPageSelection && onItemsPerPageChange && (
                <Box display="flex">
                    <Box className={pagePerItemClasses.root}>
                        {t('ItemsPerPage')}
                    </Box>
                    <Box className={pagePerItemClasses.select}>
                        <Select
                            value={{
                                id: itemsPerPage,
                                name: itemsPerPage.toString(),
                                value: itemsPerPage,
                            }}
                            options={itemsPerPageOptions}
                            getOptionLabel={getOptionLabel}
                            onChange={(e) => onItemsPerPageChange(e.value)}
                        />
                    </Box>
                </Box>
            )}
            {showCountStat && totalCount && (
                <Box className={pagePerCountClasses.paginationCount}>
                    <Typography variant="body1">{`${getStartOfPage()}-${getEndOfPage()}/${totalCount}`}</Typography>
                    <Typography variant="h5">{t('Items')}</Typography>
                </Box>
            )}
        </Box>
    )
}

Pagination.defaultProps = {
    PaginationItemComponent: DefaultItem,
}

export default Pagination
