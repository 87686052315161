import { useEffect, useState } from 'react'
import CookieEnableModal from 'packages/eid-ui/CookieEnableModal'
import { isChrome, isSafari, isFirefox, isEdge } from 'react-device-detect'

const CookieChecker = ({ config, children }) => {
    const [isCookieEnabled, setIsCookieEnabled] = useState(null)

    const ChromEnableSteps = {
        btnLabel: 'OK, I Changed my Cookie Settings, Reload Page',
        iconName: 'ChromeBrowser',
        title: 'Please <span>Change your Cookie Settings</span> to continue',
        subTitle:
            'For using <span>Chrome</span> you will need to <span>change your cookies settings</span>:',
        items: [
            'At the top right, click <span>More Settings</span>.',
            'Under "<span>Privacy and Security</span>" click <span>Cookies and other site data</span>.',
            'Select option: <span>Allow all cookies</span>.',
        ],
    }

    const SafariEnableSteps = {
        btnLabel: 'OK, I Changed my Cookie Settings, Reload Page',
        iconName: 'SafariBrowser',
        title: 'Please <span>Enable Third Party Cookies</span> to continue',
        subTitle:
            'In order to proceed with <span>Safari</span> you will need to enable 3rd-party cookies:',
        items: [
            'Open safari <span>Preference</span>.',
            'Select <span>Privacy</span>.',
            'Turn off <span>Prevent Cross-Site Tracking</span> and <span>Block All Cookies</span>.',
        ],
    }

    const FirefoxEnableSteps = {
        btnLabel: 'OK, I Changed my Cookie Settings, Reload Page',
        iconName: 'FireFoxBrowser',
        title: 'Please <span>Change your Cookie Settings</span> to continue',
        subTitle:
            'For using <span>Firefox</span> you will need to <span>change your cookies settings</span>:',
        items: [
            'At the top right, click <span>Tools > Settings</span>.',
            'Click <span>Privacy & Security</span> in the left panel.',
            ' Select the checkbox labeled ‘<span>Standard</span>.’',
        ],
    }

    const EdgeEnableSteps = {
        btnLabel: 'OK, I Changed my Cookie Settings, Reload Page',
        iconName: 'EdgeBrowser',
        title: 'Please <span>Change your Cookie Settings</span> to continue',
        subTitle:
            'For using <span>Microsoft Edge</span> you will need to <span>change your cookies settings</span>:',
        items: [
            'At the top right, click <span>More</span> and select <span>Settings</span>.',
            'Click on <span>Cookies & Site Permissions</span>.',
            'Click on <span>Manage and delete cookies and site data</span>.',
            'DeSelect the option <span>Block third-party cookies</span>.',
            "<span>Restart</span> your browser.'",
        ],
    }

    const DefaultSteps = {
        btnLabel: 'OK, I Changed my Cookie Settings, Reload Page',
        iconName: 'ComputerDefault',
        title: 'Please <span>Change your Cookie Settings</span> to continue',
        subTitle: 'For using you will need to change your cookies settings:',
        items: [
            'At the top right, click More Settings.',
            'Under "Privacy and Security" click Cookies and other site data.',
            'Select option: Allow all cookies.',
        ],
    }

    useEffect(() => {
        window.addEventListener('message', function listen(event) {
            try {
                if (event.data === 'MM:3PCunsupported') {
                    setIsCookieEnabled(false)
                } else if (event.data === 'MM:3PCsupported') {
                    setIsCookieEnabled(true)
                }
            } catch (err) {
                setIsCookieEnabled(true)
            }
        })

        let isSameOrigin = false

        const baseUrlDomain = new URL(config.BASE_EID_URL).hostname
        const apiUrlDomain = new URL(config.API_URL).hostname

        if (baseUrlDomain === apiUrlDomain) {
            isSameOrigin = true
        }

        try {
            if (navigator && navigator.cookieEnabled === false) {
                setIsCookieEnabled(false)
            } else {
                if (document && !isSameOrigin) {
                    let frame = document.createElement('iframe')
                    frame.id = '3pc'
                    frame.src = `${config.BASE_EID_URL}/3rdpartycookiecheck/start.html`
                    frame.style.display = 'none'
                    frame.style.position = 'fixed'
                    document.body.appendChild(frame)
                } else {
                    setIsCookieEnabled(true)
                }
            }
        } catch (err) {}

        return window.removeEventListener('message', function listen(event) {
            console.log('error', event)
        })
    }, [])

    const handleAccpet = () => {
        window.location.reload()
    }

    const bindUI = () => {
        if (isCookieEnabled === null) {
            return <></>
        } else if (isCookieEnabled) {
            return children
        } else {
            return (
                <CookieEnableModal
                    bannerData={
                        isChrome
                            ? ChromEnableSteps
                            : isSafari
                            ? SafariEnableSteps
                            : isFirefox
                            ? FirefoxEnableSteps
                            : isEdge
                            ? EdgeEnableSteps
                            : DefaultSteps
                    }
                    handleAccpet={() => handleAccpet()}
                />
            )
        }
    }

    return <>{bindUI()}</>
}

export default CookieChecker
