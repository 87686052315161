import { Fragment, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { queryCache } from 'react-query'

export const RemainingTime = ({ endTime }) => {
    const { t } = useTranslation()
    const currentTime = new Date().toISOString()
    const duration = moment.duration(
        moment.utc(endTime).diff(moment.utc(currentTime)),
    )

    useEffect(() => {
        const updateCredential = setTimeout(() => {
            queryCache.invalidateQueries((c) =>
                c.queryKey.includes('/api/credentials/allAssignedResources'),
            )
        }, duration.asMilliseconds())
        return () => {
            clearTimeout(updateCredential)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRemainingTime = (currentTime, endTime) => {
        if (!endTime) {
            return '-' // Can this be changed to Expired ? API returns EndTime as null after it expires
        }
        if (currentTime > endTime) {
            return t('Expired')
        }

        const hours = duration.hours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })
        const minutes = duration.minutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })

        const time = `${hours} ${t('hours')} ${minutes} ${t('mins')}`
        const daysLabel = duration.days() > 1 ? t('days') : t('day')

        const durationInDays = duration.days() + ' ' + daysLabel + ' ' + time

        return duration.days() > 0 ? durationInDays : time
    }
    return <Fragment>{getRemainingTime(currentTime, endTime)}</Fragment>
}

const CredentialRemainingTime = ({ data }) => {
    return (
        <div>
            {data.resource.canCheckIn && data.resource.checkOutEndTimeUtc ? (
                <RemainingTime endTime={data.resource?.checkOutEndTimeUtc} />
            ) : (
                <div>-</div>
            )}
        </div>
    )
}

export default CredentialRemainingTime
