import { FC, useState } from 'react'
import { Box, Button, createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import CheckOutButton from 'components/DynamicComponents/CheckOutButton'
import { useHistory } from 'react-router'
import appConfig from 'config'
import MasterLockOverlay from 'packages/eid-ui/MasterLockOverlay'
import { PasswordModal } from 'components/PasswordModal'
import { useAppState } from 'appContext'

export type PreApprovedItemsAccessTileProps = {
    item?: any
    showArrows?: boolean
    accessName?: string
    accessType?: string
    handleViewRequest: () => void
    showCount?: boolean
}

const useStyles = makeStyles((theme) =>
    createStyles({
        preapprovecard: {
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
            borderRadius: '8px',
            padding: '16px 16px 9px',
            background: '#fff',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '16px 0',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab936e 7%, #2c83bd47 91%, rgba(48, 127, 193, 0))',
            borderImageSlice: '1',
            borderBottomWidth: '1px',
            borderStyle: 'solid',
            borderTopWidth: '0',
        },
        preapprovecardleft: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        preapprovecardright: {
            display: 'flex',
        },
        preapprovedCheckout: {
            display: 'flex',
            '& button': {
                '&:nth-child(1)': {
                    paddingLeft: '6px',
                    borderRadius: '5px',
                    fontSize: '11px',
                    '& span': {
                        fontSize: '10px',
                        '& p': {
                            paddingRight: '5px',
                        },
                    },
                },
                '&:nth-child(2)': {
                    background: '#fff',
                    padding: '7px 15px 7px 13px',
                    border: 'solid 1px #307fc1',
                    fontSize: '14px',
                    lineHeight: '1',
                    color: '#307fc1',
                    marginLeft: '15px',

                    '& span': {
                        fontSize: '12px',
                    },
                },
            },
        },

        viewrequestbtn: {
            '& button': {
                background: '#fff',
                padding: '7px 15px 7px 13px',
                border: 'solid 1px #307fc1',
                fontSize: '14px',
                lineHeight: '1',
                color: '#307fc1',
                marginLeft: '15px',
                '& span': {
                    fontSize: '12px',
                },
            },
        },
        preapprovebtn: {
            border: 'solid 1px #aab0b4',
            fontSize: '12px',
            lineHeight: '1',
            padding: '4px 16px',
            minWidth: '120px',
            textAlign: 'center',
        },
        preApprovedbtn: {
            background: '#fff',
            borderRadius: '5px',
            color: theme.palette.secondary.main,
        },
        checkoutsbtn: {
            background: '#fff',
            borderRadius: '5px',
            color: '#1b1f1f',
        },
        preapproveadmin: {
            marginLeft: '15px',
            fontSize: '14px',
            color: '#000',
        },
        preapprovedsection: {
            display: 'flex',
            alignItems: 'center',
        },
        taskbadgesection: {
            position: 'relative',
        },
        taskbadge: {
            position: 'absolute',
            top: '-15px',
            right: '-10px',
            background: theme.palette.quaternary.main,
            height: '15px',
            width: '15px',
            borderRadius: '50%',
            fontSize: '10px',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '1px',
        },
        custommasterlock: {
            position: 'relative',
            top: '0px',
        },
    }),
)

export const PreApprovedItemsAccessTile: FC<PreApprovedItemsAccessTileProps> = (
    props,
) => {
    const { t } = useTranslation()
    const history = useHistory()
    const {
        item,
        showArrows = true,
        accessType,
        accessName,
        handleViewRequest,
        showCount = false,
    } = props
    const classes = useStyles()

    const [{ masterPassword }]: any = useAppState()
    const [open, setOpen] = useState('')

    const handleMasterUnlock = () => {
        setOpen('enterPassword')
    }

    const manageButton = () => {
        return (
            <Button
                onClick={() =>
                    history.push(
                        `${appConfig.APP_SUBPATH}/credentials/manageAccess`,
                    )
                }
                endIcon={
                    <Icon
                        name="ArrowSmall"
                        width="20px"
                        fill="#307fc1"
                        direction="right"
                    />
                }
                style={{ width: '91px', height: '24px' }}
            >
                {t('Common_Manage')}
            </Button>
        )
    }

    const checkOutButton = () => {
        return (
            <>
                {!masterPassword && (
                    <PasswordModal
                        showDefaultContent={false}
                        open={open}
                        setOpen={setOpen}
                    />
                )}
                {!masterPassword ? (
                    <div style={{ position: 'relative', bottom: '6px' }}>
                        <MasterLockOverlay
                            btnLabel={t('Common_Unlock')}
                            showMasterLock={!masterPassword}
                            handleMasterUnlock={() => handleMasterUnlock()}
                        >
                            <CheckOutButton
                                data={{ resource: item }}
                                drawerView={false}
                                checkedOutBySomeoneElse={false}
                                parentStyle={{ marginBottom: '5px' }}
                            />
                        </MasterLockOverlay>
                    </div>
                ) : (
                    <CheckOutButton
                        data={{ resource: item }}
                        drawerView={false}
                        checkedOutBySomeoneElse={false}
                    />
                )}
            </>
        )
    }

    const renderButtons = () => {
        if (item.type === 'preapprovedCheckout') {
            return (
                <Box className={classes.preapprovedCheckout}>
                    {checkOutButton()}
                    {manageButton()}
                </Box>
            )
        } else if (item.type === 'checkoutRecords') {
            return (
                <Box className={classes.viewrequestbtn}>
                    <Button
                        onClick={() => handleViewRequest()}
                        endIcon={
                            <Icon
                                name="ArrowSmall"
                                width="20px"
                                fill="#307fc1"
                                direction="right"
                            />
                        }
                        size={'medium'}
                    >
                        {t('Common_ViewRequest')}
                    </Button>
                </Box>
            )
        } else {
            return null
        }
    }

    return (
        <>
            <Box className={classes.preapprovecard}>
                <Box className={classes.preapprovecardleft}>
                    <Box className={classes.preapprovedsection}>
                        <Box
                            className={classNames(classes.preapprovebtn, {
                                [classes.preApprovedbtn]:
                                    item.type === 'preapprovedCheckout',
                                [classes.checkoutsbtn]:
                                    item.type === 'checkoutRecords',
                            })}
                        >
                            {accessType}
                        </Box>
                        {showArrows && (
                            <Box marginLeft="15px">
                                <Icon name="ArrowIconGreen" />
                            </Box>
                        )}
                    </Box>
                    <Box className={classes.taskbadgesection}>
                        <Box className={classes.preapproveadmin}>
                            {accessName}
                        </Box>
                        {showCount && (
                            <span className={classes.taskbadge}>
                                {item.checkOutCount ?? 0}
                            </span>
                        )}
                    </Box>
                </Box>
                <Box className={classes.preapprovecardright}>
                    {renderButtons()}
                </Box>
            </Box>
        </>
    )
}
