import React from 'react'
import Button from './index'
import { Typography, useTheme } from '@material-ui/core'

const ExtendListingButton = ({
    children,
    label,
    parentStyle = {},
    ...rest
}) => {
    const theme = useTheme()

    const styles = {
        normal: {
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            border: `solid 1px ${theme.palette.primary.main}`,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        disabled: {
            backgroundColor: '#c5cbda',
            border: 'solid 1px #c5cbda',
            color: '#ffffff !important',
            opacity: '0.8',
        },

        label: {
            fontSize: '11px',
            lineHeight: 'normal',
        },
    }

    return (
        <Button
            style={{ ...styles.normal, ...parentStyle }}
            disabledStyles={styles.disabled}
            {...rest}
        >
            {!children ? (
                <>
                    <Typography style={styles.label}>{label}</Typography>
                </>
            ) : (
                children
            )}
        </Button>
    )
}

export default ExtendListingButton
