import React from 'react'
import { Header } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { useResourceTypeContext } from 'resourceTypeContext'
import useConfiguration from 'useConfiguration'
import {
    SubPathLevel,
    AREANAME_URL_INDEX,
    RESOURCE_URL_INDEX,
} from 'appConfiguration'
import appConfig from 'config'

const Search = ({ history, ...rest }) => {
    const {
        location: { pathname },
    } = history

    const { t } = useTranslation()
    const { resourceTypes } = useConfiguration()

    const [{ searchTerm }, dispatch] = useResourceTypeContext()

    let subPaths = pathname.split('/')

    const handleSearch = (searchKey) =>
        dispatch({
            type: 'SET_SEARCH_TERM',
            payload: searchKey,
        })

    // Not removing, can be used in future
    // eslint-disable-next-line
    const handleSelect = (option) => {
        history.push(`${appConfig.APP_SUBPATH}${option.route}`)
    }

    const areaName =
        subPaths.length > AREANAME_URL_INDEX + SubPathLevel
            ? subPaths[AREANAME_URL_INDEX + SubPathLevel].toLowerCase()
            : 'requestAccess'

    const isActive = (name) => {
        const resourceName =
            subPaths.length > RESOURCE_URL_INDEX + SubPathLevel &&
            subPaths[RESOURCE_URL_INDEX + SubPathLevel]

        return resourceName.toLowerCase() === name.toLowerCase()
    }
    let requestAccessResourceTypes = []
    requestAccessResourceTypes = requestAccessResourceTypes.concat(
        resourceTypes.map((r) => ({
            title: t(r.title),
            route: r.route,
            icon: r.icon,
            selected: isActive(r.name),
        })),
    )

    const manageAccessResourceTypes = resourceTypes.map((r) => ({
        title: t(r.title),
        route: `${r.route}/manageAccess`,
        icon: r.icon,
        selected: isActive(r.name),
    }))

    const workflowsResourceTypes = resourceTypes.map((r) => ({
        title: t(r.title),
        route: `${r.route}/workflows`,
        icon: r.icon,
        selected: isActive(r.name),
    }))

    // Not removing, can be used in future
    // eslint-disable-next-line
    const options =
        areaName === 'manageaccess'
            ? manageAccessResourceTypes
            : areaName !== 'workflows'
            ? requestAccessResourceTypes
            : workflowsResourceTypes

    return (
        <Header.Search
            placeholder={t('Common_Search')}
            handleSearch={handleSearch}
            value={searchTerm}
            showClearIcon={false}
            {...rest}
        />
    )
}

export default withRouter(Search)
