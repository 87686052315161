import React from 'react'
import PropTypes from 'prop-types'
import { Link, Box, useTheme, styled } from '@material-ui/core'
import { CardViewIcon, ListViewIcon } from '../../eid-icons'
import { Tooltip } from 'packages/eid-ui'

const ViewsIndicator = ({
    view,
    onCardViewClick,
    onListViewClick,
    cardViewLabel,
    listViewLabel,
}) => {
    const theme = useTheme()
    const getColor = (active) => {
        return active ? theme.palette.primary.main : '#959598'
    }

    const iconStyles = (theme) => ({
        '&': {
            transition: 'color 0.15s ease-in-out',
            marginTop: '6px',
        },
        '&:hover, &:focus': {
            color: theme.palette.primary.main,
        },
    })

    /*
    Overriding default hover/focus props to fix tooltip "hanging" after click.
    Revisit after MUI v5 update.
    Also see below on the HTML elements.
    */
    const StyledCardViewIcon = styled(CardViewIcon)(({ theme }) =>
        iconStyles(theme),
    )
    const StyledListViewIcon = styled(ListViewIcon)(({ theme }) =>
        iconStyles(theme),
    )
    const [showListViewTooltip, setShowListViewTooltip] = React.useState(false)
    const [showCardViewTooltip, setShowCardViewTooltip] = React.useState(false)

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Link
                component="button"
                onClick={() => {
                    setShowListViewTooltip(false)
                    setShowCardViewTooltip(false)
                    onListViewClick()
                }}
            >
                <Tooltip
                    title={listViewLabel}
                    aria-label={listViewLabel}
                    placement="top"
                    open={showListViewTooltip}
                    disableHoverListener
                    onMouseEnter={() => setShowListViewTooltip(true)}
                    onMouseLeave={() => setShowListViewTooltip(false)}
                >
                    <Box padding="0 20px 0 22px">
                        <StyledListViewIcon color={getColor(view === 'list')} />
                    </Box>
                </Tooltip>
            </Link>
            <Link
                component="button"
                onClick={() => {
                    setShowListViewTooltip(false)
                    setShowCardViewTooltip(false)
                    onCardViewClick()
                }}
            >
                <Tooltip
                    title={cardViewLabel}
                    aria-label={cardViewLabel}
                    placement="top"
                    open={showCardViewTooltip}
                    disableHoverListener
                    onMouseEnter={() => setShowCardViewTooltip(true)}
                    onMouseLeave={() => setShowCardViewTooltip(false)}
                >
                    <Box padding="0">
                        <StyledCardViewIcon color={getColor(view === 'card')} />
                    </Box>
                </Tooltip>
            </Link>
        </div>
    )
}

ViewsIndicator.propTypes = {
    view: PropTypes.oneOf(['list', 'card']),
    onCardViewClick: PropTypes.func.isRequired,
    onListViewClick: PropTypes.func.isRequired,
    cardViewLabel: PropTypes.string,
    listViewLabel: PropTypes.string,
}

ViewsIndicator.defaultProps = {
    view: 'list',
}

export default ViewsIndicator
