import { styled } from '@material-ui/core'
import { HorizontalTabs } from 'packages/eid-ui'

const Tabs = styled(HorizontalTabs)(({ theme }) => ({
    backgroundColor: '#fbfbfd',
    '& .MuiTabs-flexContainer': {
        height: '61px',
        paddingLeft: '13px',
        paddingRight: '13px',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
    },
    borderBottom: 'solid 1px #d8dadd',
    '& .Mui-selected': {
        fontWeight: 'bold',
    },
}))

export default Tabs
