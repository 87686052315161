import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { Box } from '@material-ui/core'
import ApplicationAccessLevel from './ApplicationAccessLevel'
import { ApplicationOverView } from './ApplicationOverView'
import { ApplicationAdditionalInformation } from './AdditionalDetails'
import { ApplicationActions } from './ApplicationActions'
import cartHelpers from 'containers/Cart/cartHelpers'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { RenewRevoke } from 'components/RenewRevoke'
import { ProtectedApplicationAssignments } from './ProtectedApplicationAssignments/ProtectedApplicationAssignments'
import { useAccessRequestPolicy } from 'hooks'

const ApplicationDetails = ({
    application,
    accessLevel,
    accessLevels,
    setAccessLevelValue,
    targetPerson,
    isCheckingAccess,
    applicationId,
    isManageAccess,
    item,
    toggleDrawer,
}) => {
    const { t } = useTranslation()
    const registry = useRegistry()
    const applicationsType = registry.get(
        ResourceTypesNamespace,
        'Applications',
    )

    const requestPolicy = useAccessRequestPolicy(
        accessLevel?.resourceAssignment?.requestPolicyId,
    )

    const preRevoke = () => {
        const itemToRevoke = cartHelpers.applicationsAccessToCartItem({
            targetPerson,
            assignmentType: 'Remove',
            managementRole: item?.resource,
            assignmentStatus: item?.assignment,
        })
        if (!itemToRevoke) return undefined

        return itemToRevoke
    }
    const [activeTab, setActiveTab] = useState('overview')

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                <ItemDetails.Tabs.Tab
                    value="action"
                    label={t('Common_Actions')}
                />
            </ItemDetails.Tabs>
            {activeTab === 'overview' && (
                <ApplicationOverView
                    item={item}
                    isManageAccess={isManageAccess}
                    data={application}
                    policy={requestPolicy}
                />
            )}
            {activeTab === 'additionalInformation' && (
                <ApplicationAdditionalInformation
                    isAzureApplication={application.isAzureApplication}
                    applicationId={applicationId}
                />
            )}
            {activeTab === 'action' && (
                <ApplicationActions data={application} />
            )}

            {!isManageAccess && applicationId && (
                <ProtectedApplicationAssignments
                    application={application}
                    applicationsType={applicationsType}
                    applicationId={applicationId}
                />
            )}

            {!isManageAccess && application && (
                <Box minWidth={'100%'}>
                    <ApplicationAccessLevel
                        accessLevel={accessLevel}
                        accessLevels={accessLevels?.filter(
                            (a) => !a.isAssigned,
                        )}
                        setAccessLevelValue={(val) => setAccessLevelValue(val)}
                        isCheckingAccess={isCheckingAccess}
                        application={application}
                        targetPerson={targetPerson}
                    />
                </Box>
            )}

            {isManageAccess && (
                <RenewRevoke
                    resourceType={applicationsType}
                    resource={item.resource}
                    assignment={item.assignment}
                    postRenew={toggleDrawer}
                    preRevoke={preRevoke}
                    postRevoke={toggleDrawer}
                    item={item}
                />
            )}
        </Fragment>
    )
}

export default ApplicationDetails
