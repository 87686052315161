import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'packages/eid-ui'

const NullableBooleanFilter = ({ value, onChange, label, optionConfig }) => {
    const { t } = useTranslation()

    const nullableBooleanOptions = [
        { id: 1, name: optionConfig.trueLabel, value: 'true' },
        { id: 0, name: optionConfig.falseLabel, value: 'false' },
        { id: -1, name: optionConfig.nullLabel, value: 'null' },
    ]

    const defaultValue = nullableBooleanOptions.find((a) => a?.value === 'null')
    const [currentOption, setCurrentOption] = useState()

    useEffect(() => {
        if (!value) {
            setCurrentOption(defaultValue)
        } else if (value !== currentOption?.value) {
            const selectedOption = nullableBooleanOptions.find(
                (a) => a?.value === value,
            )
            setCurrentOption(selectedOption)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if (currentOption) {
            onChange(currentOption.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOption])

    const handleSelectionChange = (option) => {
        setCurrentOption(option)
    }

    return (
        <>
            {currentOption && (
                <Select
                    placeholder={label}
                    options={nullableBooleanOptions}
                    onChange={handleSelectionChange}
                    value={currentOption}
                    getOptionLabel={(option) => t(option.name)}
                />
            )}
        </>
    )
}

export default NullableBooleanFilter
