import { Box, makeStyles, styled, Button } from '@material-ui/core'
import {
    useAllApplications,
    useCheckAccessToCheckInOperation,
    useComputerCheckIn,
    useCurrentPerson,
} from 'hooks'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComputerSessionRequestDetails from '../SessionRequestDetail'
import appConfig from 'config'
import MasterLockOverlay from 'packages/eid-ui/MasterLockOverlay'
import { useAppState } from 'appContext'
import { PasswordModal } from 'components/PasswordModal'
import { Avatar } from 'packages/eid-ui'
import { getFullImageUrl } from 'utils'
import ComputerConnectBtn from 'components/DynamicComponents/ComputerConnectButton'
import ComputersCheckInButton from 'components/DynamicComponents/ComputersCheckInButton'
import { useIsSmallScreen, useUrlGenerator } from 'packages/core'
import classNames from 'classnames'
const smallScreenWidth = '1022px'

const PaddedDiv = styled(Box)((props: any) => ({
    padding: props.isSmallScreen ? '0 16px' : '0 31px',
}))

const useStyles = makeStyles((theme) => ({
    requestmaincontainer: {
        borderRadius: '8px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        border: 'solid 1px rgba(0, 0, 0, 0.11)',
        background: '#fff',
        marginTop: '16px',
        paddingBottom: '20px',
        overflow: 'hidden',
    },

    requestHeadertitle: {
        fontSize: '14px',
        fontWeight: 600,
        marginTop: '5px',
        marginBottom: '5px',
    },
    requestHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.11)',
        padding: '4px 20px ',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
    },
    requestHeadertitlebutton: {
        '& button': {
            fontSize: '11px',
            color: '#fff',
            background: theme.palette.secondary.main,
            borderRadius: '5px',
            padding: '1px 4px 1px',
            '&:hover': {
                background: theme.palette.secondary.main,
            },
        },
    },
    requestitemshow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 20px ',
        marginTop: '10px',
        [`@media (max-width:${smallScreenWidth})`]: {
            flexDirection: 'column',
            alignItems: 'start',
            gap: '16px',
        },
    },
    requestleftshow: {
        // TODO: Commenting until we get new design
        // width: '25%',
    },
    requestitemtitle: {
        fontSize: '12px',
        fontWeight: 500,
        color: '#9b9b9b',
        margin: '0px',
        textTransform: 'uppercase',
        [`@media (max-width:${smallScreenWidth})`]: {
            fontWeight: '600',
        },
    },
    requestitemheading: {
        fontSize: '14px',
        fontWeight: 500,
        margin: '0px',
    },
    requestitemheadingLink: {
        '& a': {
            color: '#307fc1 !important',
            textDecoration: 'none !important',
        },
    },
    requestitemtime: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#000',
        margin: '0px',
        '& button': {
            fontSize: '11px',
            borderRadius: '5px',
            padding: '1px 4px 1px',
            '&:hover': {
                background: theme.palette.secondary.main,
            },
        },
    },
    checkinbutton: {
        fontSize: '11px',
        color: '#fff',
        background: '#307fc1',
        borderRadius: '5px',
        '&:hover': {
            background: '#307fc1',
        },
    },
    avtarsection: {
        display: 'flex',
        alignItems: 'center',
        ' & > div': {
            height: '20px',
            width: '20px',
        },
    },
    avtarname: {
        marginLeft: '5px',
    },
    requestconnectbtn: {
        ' & button': {
            padding: '0',
            ' & div': {
                maxWidth: 'auto !important',
            },
        },
    },
}))

export interface ComputerSessionRequestTileProps {
    item: any
    parent: any
}

export const ComputerSessionRequestTile: FC<ComputerSessionRequestTileProps> = (
    props,
) => {
    const { item, parent } = props
    item.checkOutId = item.id
    const [{ masterPassword }]: any = useAppState()
    const [open, setOpen] = useState('')
    const classes = useStyles()
    const { t } = useTranslation()
    const [showDetailsDialog, setShowDetalsDialog] = useState(false)
    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)
    const isSmallScreen = useIsSmallScreen()

    const { data: currentPerson } = useCurrentPerson()
    const handleDetailClick = () => {
        setShowDetalsDialog(true)
    }
    const isRequestedBySelf = item.isSelfRequested

    const { data: hasAccessToCheckIn } = useCheckAccessToCheckInOperation(
        item.externalCredentialID,
        isRequestedBySelf === false && item.showCheckIn,
    )
    const [
        checkInComputer,
        { data: computerChkIn, isError },
    ] = useComputerCheckIn()

    const handleCheckIn = () => {
        checkInComputer(parent.checkOutId)
    }

    useEffect(() => {
        if (computerChkIn && !isError) {
            setShowDetalsDialog(false)
        }
    }, [computerChkIn, isError])

    const handleConnect = () => {
        const wfParams = []
        wfParams.push(`TargetPersonGuid=${currentPerson.id}`)
        wfParams.push(`AccessRequestPolicyID=${item.requestPolicyId}`)
        wfParams.push(`TargetComputerGuid=${item.id}`)
        wfParams.push(`IsAdvanceMode=${false}`)
        const fullWorkflowUrl = constructApprovalWorkflowUrl(
            appConfig.BASE_EID_URL,
            'OpenPSMSessionWF',
            wfParams,
        )
        window.open(fullWorkflowUrl, '_blank', 'noopener,noreferrer')
    }

    const handleMasterUnlock = () => {
        setOpen('enterPassword')
    }
    return (
        <>
            {!masterPassword && (
                <PasswordModal
                    showDefaultContent={false}
                    open={open}
                    setOpen={setOpen}
                />
            )}
            <PaddedDiv isSmallScreen={isSmallScreen}>
                <Box className={classes.requestmaincontainer}>
                    <Box className={classes.requestHeader}>
                        <Box
                            component="h2"
                            className={classes.requestHeadertitle}
                        >
                            {item.credentialFriendlyName
                                ? item.credentialFriendlyName
                                : '---'}
                        </Box>
                        <Box className={classes.requestHeadertitlebutton}>
                            <Button onClick={() => handleDetailClick()}>
                                {t('ManageAccess_Details')}
                            </Button>
                        </Box>
                    </Box>
                    <Box className={classes.requestitemshow}>
                        <Box className={classes.requestleftshow}>
                            <Box
                                component="h2"
                                className={classes.requestitemtitle}
                            >
                                {t('Common_BusinessRequest')}
                            </Box>
                            <Box
                                component="h2"
                                className={classNames(
                                    classes.requestitemheading,
                                    {
                                        [classes.requestitemheadingLink]:
                                            item?.businessRequestId &&
                                            item.businessRequestName,
                                    },
                                )}
                            >
                                {item?.businessRequestId &&
                                item.businessRequestName ? (
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={getMyTasksUrl(
                                            `/myRequests/businessRequests?id=${item.businessRequestId}`,
                                        )}
                                    >
                                        {item.businessRequestName
                                            ? item.businessRequestName
                                            : '---'}
                                    </a>
                                ) : item.businessRequestName ? (
                                    item.businessRequestName
                                ) : (
                                    '---'
                                )}
                            </Box>
                        </Box>
                        <Box className={classes.requestleftshow}>
                            <Box
                                component="h2"
                                className={classes.requestitemtitle}
                            >
                                {t('Common_BusinessRequestItem')}
                            </Box>
                            <Box
                                component="h2"
                                className={classNames(
                                    classes.requestitemheading,
                                    {
                                        [classes.requestitemheadingLink]:
                                            item?.businessRequestId &&
                                            item.businessRequestItemName,
                                    },
                                )}
                            >
                                {item?.businessRequestItemId &&
                                item.businessRequestItemName ? (
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={getMyTasksUrl(
                                            `/myRequests/businessRequestItems?id=${item.businessRequestItemId}`,
                                        )}
                                    >
                                        {item.businessRequestItemName
                                            ? item.businessRequestItemName
                                            : '---'}
                                    </a>
                                ) : item.businessRequestItemName ? (
                                    item.businessRequestItemName
                                ) : (
                                    '---'
                                )}
                            </Box>
                        </Box>
                        <Box className={classes.requestleftshow}>
                            <Box
                                component="h2"
                                className={classes.requestitemtitle}
                            >
                                {t('CheckedOutBy')}
                            </Box>
                            <Box className={classes.avtarsection}>
                                <Avatar
                                    size="small"
                                    src={getFullImageUrl(
                                        item.requestedByPersonImageThumbUrl,
                                    )}
                                />
                                <span className={classes.avtarname}>
                                    {item.requestorFriendlyName
                                        ? item.requestorFriendlyName
                                        : '---'}
                                </span>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.requestitemshow}>
                        <Box className={classes.requestleftshow}>
                            <Box
                                component="h2"
                                className={classes.requestitemtitle}
                            >
                                {t('Common_StartTime')}
                            </Box>
                            <Box
                                component="h2"
                                className={classes.requestitemtime}
                            >
                                {item.startTimeUtc
                                    ? moment
                                          .utc(item.startTimeUtc)
                                          .local()
                                          .format('L LT')
                                    : '---'}
                            </Box>
                        </Box>
                        <Box className={classes.requestleftshow}>
                            <Box
                                component="h2"
                                className={classes.requestitemtitle}
                            >
                                {t('Common_EndTime')}
                            </Box>
                            <Box
                                component="h2"
                                className={classes.requestitemtime}
                            >
                                {item.endTimeUtc
                                    ? moment
                                          .utc(item.endTimeUtc)
                                          .local()
                                          .format('L LT')
                                    : '---'}
                            </Box>
                        </Box>
                        {item.showFutureCheckOutTime ? (
                            <Box>
                                <Box
                                    component="h2"
                                    className={classes.requestitemtitle}
                                >
                                    {t('Common_ActualCheckOutTime')}
                                </Box>
                                <Box
                                    component="h2"
                                    className={classes.requestitemtime}
                                >
                                    {t('Common_WillBeAvailableForCheckOut')}
                                    {item.userCheckedOutTimeUtc
                                        ? moment
                                              .utc(item.userCheckedOutTimeUtc)
                                              .local()
                                              .format('L LT')
                                        : '---'}
                                </Box>
                            </Box>
                        ) : (
                            <Box>
                                <Box
                                    component="h2"
                                    className={classes.requestitemtitle}
                                >
                                    {t('Common_ActualCheckOutTime')}
                                </Box>
                                <Box
                                    component="h2"
                                    className={classes.requestitemtime}
                                >
                                    {item.userCheckedOutTimeUtc
                                        ? moment
                                              .utc(item.userCheckedOutTimeUtc)
                                              .local()
                                              .format('L LT')
                                        : '---'}
                                </Box>
                            </Box>
                        )}
                        {item.showActualCheckInTime && (
                            <Box className={classes.requestleftshow}>
                                <Box
                                    component="h2"
                                    className={classes.requestitemtitle}
                                >
                                    {t('Common_ActualCheckInTime')}
                                </Box>
                                <Box
                                    component="h2"
                                    className={classes.requestitemtime}
                                >
                                    {item.userCheckedInTimeUtc
                                        ? moment
                                              .utc(item.userCheckedInTimeUtc)
                                              .local()
                                              .format('L LT')
                                        : '---'}
                                </Box>
                            </Box>
                        )}
                        {item.showStatus && (
                            <Box className={classes.requestleftshow}>
                                <Box
                                    component="h2"
                                    className={classes.requestitemtitle}
                                >
                                    {t('Status')}
                                </Box>
                                <Box
                                    component="h2"
                                    className={classes.requestitemtime}
                                >
                                    <MasterLockOverlay
                                        btnLabel={t('Common_Unlock')}
                                        showMasterLock={!masterPassword}
                                        handleMasterUnlock={handleMasterUnlock}
                                    >
                                        <Box
                                            className={
                                                classes.requestconnectbtn
                                            }
                                        >
                                            <ComputerConnectBtn
                                                parentStyle=""
                                                showPrefixIcon={false}
                                                data={item}
                                                drawerView={false}
                                                showCalenderIcon={false}
                                            />
                                        </Box>
                                    </MasterLockOverlay>
                                </Box>
                            </Box>
                        )}
                        {item.showCheckIn && hasAccessToCheckIn?.hasAccess && (
                            <Box className={classes.requestleftshow}>
                                <Box
                                    component="h2"
                                    className={classes.requestitemtitle}
                                >
                                    {t('Status')}
                                </Box>
                                <Box
                                    component="h2"
                                    className={classes.requestitemtime}
                                >
                                    <MasterLockOverlay
                                        btnLabel={t('Common_Unlock')}
                                        showMasterLock={!masterPassword}
                                        handleMasterUnlock={handleMasterUnlock}
                                    >
                                        <Box
                                            className={
                                                classes.requestconnectbtn
                                            }
                                        >
                                            <ComputersCheckInButton
                                                data={{ resource: item }}
                                            />
                                        </Box>
                                    </MasterLockOverlay>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </PaddedDiv>
            <ComputerSessionRequestDetails
                parent={parent}
                masterPassword={masterPassword}
                handleMasterUnlock={handleMasterUnlock}
                handleConnect={() => handleConnect()}
                handleCheckIn={handleCheckIn}
                canCheckIn={item.canCheckIn && hasAccessToCheckIn?.hasAccess}
                showStatus={item.showStatus}
                item={item}
                open={showDetailsDialog}
                onClose={() => setShowDetalsDialog(false)}
                title={
                    item.credentialFriendlyName
                        ? `${item.credentialFriendlyName} ${
                              item.userCheckedInTimeUtc
                                  ? t('Common_StartingAt') +
                                    ' ' +
                                    moment
                                        .utc(item.userCheckedInTimeUtc)
                                        .local()
                                        .format('L LT')
                                  : ''
                          }`
                        : '---'
                }
            />
        </>
    )
}

const constructApprovalWorkflowUrl = (
    baseEidUrl: string,
    workflowName: string,
    workflowParams: string[],
) => {
    let queryParams = ['popupNav=true']

    if (workflowParams) {
        queryParams = queryParams.concat(workflowParams)
    }

    const queryString = queryParams.join('&')
    return `${baseEidUrl}/ui?hideHeader=true&wfMsContext=true&wfMsUrl=${window.location.origin}#w/${workflowName}?${queryString}`
}

export default ComputerSessionRequestTile
