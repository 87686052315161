import Radio from '@material-ui/core/Radio'
import {
    List,
    ListItem,
    styled,
    makeStyles,
    Box,
    useTheme,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'packages/eid-ui'

const StyledRadioIcon = styled(Radio)({
    padding: '0px 12px 0px 0px',
})

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxHeight: '208px',
        overflow: 'auto',
        borderRadius: '5px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            margin: '4px',
            '-webkit-border-radius': '3px',
            borderRadius: '3px',
            backgroundColor: '#f7f7f7',
        },

        '&::-webkit-scrollbar-thumb': {
            width: '3px',
            borderRadius: '5px',
            border: '2px solid transparent',
            height: '20px',
            backgroundColor: '#d8d8d8',
        },
        backgroundColor: '#ffffff',
        '& .MuiListItem-root': {
            cursor: 'pointer',
            width: '100%',
            wordBreak: 'break-word',
            paddingTop: '10px',
            fontSize: '14px',
            color: '#767676',
            '&.Mui-selected': {
                color: '#000000',
                backgroundColor: 'transparent',
            },
        },
    },
})

const tooltipStyles = {
    top: '15px',
    right: '70px',
}

const DifferentiationValueSelector = ({
    loading,
    data,
    value: selectedValueId,
    onChange,
    getOptionLabel,
    checkSelectedOption,
    readOnlyOptions,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()

    return (
        <List classes={classes}>
            {loading ? (
                <>
                    <Box margin="4px 8px">
                        <Skeleton animation="wave" variant="rect" height={20} />
                    </Box>
                    <Box margin="4px 8px">
                        <Skeleton animation="wave" variant="rect" height={20} />
                    </Box>
                </>
            ) : data.length === 0 &&
              (!readOnlyOptions || readOnlyOptions.length < 1) ? (
                <ListItem>{t('Common_NoDataFound')}</ListItem>
            ) : (
                <>
                    {readOnlyOptions &&
                        readOnlyOptions.map((o, index) => (
                            <ListItem key={`${o.id}${index}`}>
                                <Icon
                                    name={'Checked'}
                                    style={{ marginRight: '9px' }}
                                />
                                {getOptionLabel
                                    ? getOptionLabel(o)
                                    : o.friendlyName}
                            </ListItem>
                        ))}

                    {data.map((i, index) => (
                        <Tooltip
                            tooltipStyles={tooltipStyles}
                            placement="top"
                            arrow={true}
                            title={i.fullPath || ''}
                        >
                            <ListItem
                                selected={
                                    checkSelectedOption
                                        ? checkSelectedOption(
                                              i,
                                              selectedValueId,
                                          ) === selectedValueId
                                        : i.id === selectedValueId
                                }
                                key={`${i.id}${index}`}
                                onClick={() => onChange(i)}
                            >
                                <StyledRadioIcon
                                    icon={<Icon name="Radio" />}
                                    checkedIcon={
                                        <Icon
                                            name="RadioFilled"
                                            color={theme.palette.primary.main}
                                        />
                                    }
                                    checked={
                                        checkSelectedOption
                                            ? checkSelectedOption(
                                                  i,
                                                  selectedValueId,
                                              )
                                            : i.id === selectedValueId
                                    }
                                />
                                {getOptionLabel
                                    ? getOptionLabel(i)
                                    : i.friendlyName}
                            </ListItem>
                        </Tooltip>
                    ))}
                </>
            )}
        </List>
    )
}

export default DifferentiationValueSelector
