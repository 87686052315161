import { useState, Fragment } from 'react'
import { usePerson } from 'hooks/personHooks'
import { PersonDetails } from 'components/ApplicationBanner/PersonDetails'

const Person = ({ data, attribute }) => {
    const item = data?.resource ?? data
    const [enabled, setEnabled] = useState(false)
    const personIdProp =
        attribute.component?.config?.personIdProp || attribute.name
    const personId = item[personIdProp]
    const { data: person, isLoading } = usePerson(personId, enabled)

    if (attribute.component?.config?.showOnlyIfIsPerson) {
        if (!item[attribute.component?.config?.isPersonFlagProp]) {
            return item[attribute.component?.config?.friendlyNameProp] || '-'
        }
    }
    if (item) {
        return (
            <div
                onMouseEnter={() => {
                    if (personId) {
                        setEnabled(true)
                    }
                }}
            >
                <PersonDetails
                    size="medium"
                    enabled={enabled}
                    loading={isLoading}
                    person={{
                        imageThumbUrl:
                            item[attribute.component?.config?.imgUrlProp],
                        friendlyName:
                            item[attribute.component?.config?.friendlyNameProp],
                        email: person && person.email,
                        telephone: person && person.telephone,
                    }}
                />
            </div>
        )
    }

    return (
        <Fragment>
            {item[attribute.component.config.friendlyNameProp]
                ? item[attribute.component.config.friendlyNameProp]
                : '-'}
        </Fragment>
    )
}
export default Person
