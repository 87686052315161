import * as React from 'react'
import { Box, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'

import moment from 'moment'
import {
    NativeDatePicker,
    NativeTimePicker,
} from 'packages/eid-ui/DateTimePickers/'

export interface StartDateControlProps {
    selectedStartDate: any
    setSelectedStartDate: any
}

export const StartDateControl: React.FC<StartDateControlProps> = ({
    selectedStartDate,
    setSelectedStartDate,
}) => {
    const { t } = useTranslation()

    return (
        <Box display="flex">
            <NativeDatePicker
                minDate={moment(new Date()).format('YYYY-MM-DD')}
                value={selectedStartDate.clone().local().format('YYYY-MM-DD')}
                handleChange={(e: any) => {
                    let date = moment()
                    if (!isNilOrEmpty(e)) {
                        date = moment(e)
                    }
                    const dateToSet = date.clone()
                    const isFuture = date.diff(moment()) > 0
                    if (isFuture) {
                        dateToSet.startOf('day')
                    } else {
                        const now = moment()
                        dateToSet.set({
                            hour: now.hour(),
                            minute: now.minute(),
                            second: 0,
                        })
                    }

                    dateToSet.utc()
                    setSelectedStartDate(dateToSet)
                }}
                pickerStyles={{ top: '22px' }}
            >
                <TextField
                    type="text"
                    value={selectedStartDate.local().format('L')}
                    label={t('Common_StartDate')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </NativeDatePicker>
            <NativeTimePicker
                value={selectedStartDate.clone().local().format('HH:mm')}
                handleChange={(e: any) => {
                    const time = moment(e, 'HH:mm')
                    const dateToSet = selectedStartDate.clone().local()

                    dateToSet.set({
                        hour: time.hour(),
                        minute: time.minute(),
                        second: 0,
                    })

                    dateToSet.utc()
                    setSelectedStartDate(dateToSet)
                }}
                disabled={false}
                pickerStyles={{ top: '22px' }}
            >
                <TextField
                    label={t('Common_StartTime')}
                    type="text"
                    value={selectedStartDate.local().format('LT')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </NativeTimePicker>
        </Box>
    )
}
