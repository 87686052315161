import React from 'react'
import moment from 'moment'

const DateTimeGenericComponent = ({ data, attribute }) => {
    const date =
        data.resource && data.resource[attribute.name]
            ? data.resource[attribute.name]
            : null
    return <>{!date ? '-' : moment.utc(date).clone().local().format(attribute?.component?.format || 'L LT')}</>
}
export default DateTimeGenericComponent
