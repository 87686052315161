import { withRouter } from 'react-router'
import { EidLogoIcon, Icon } from 'packages/eid-icons'
import { Header, Tooltip } from 'packages/eid-ui'
import { Box, Link, styled, Typography } from '@material-ui/core'
import { useEnvironment } from 'hooks'
import LanguageMenu from './LanguageMenu'
import HelpMenu from './HelpMenu'
import AccountDropdown from './AccountDropdown'
import appWindowConfig from 'config'
import ShoppingCartMenuIcon from './ShoppingCartMenuIcon'
import { useAppState } from 'appContext'
import useSubcomponents from 'useSubcomponents'
import { headerHeight } from 'utils'

const StyledChip = styled('div')({
    position: 'absolute',
    top: '0px',
    left: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: '#ffffff',
    height: '13px',
    maxWidth: '45px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
    '@media (max-width:374px)': {
        display: 'none',
    },
})

const itemStyles = {
    minWidth: '30px',
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const ITShopMobileHeader = () => {
    const { data: environmentName } = useEnvironment()
    const [{ appConfig: config }] = useAppState()
    const { hasAccessToHelpMenu } = useSubcomponents()
    const headerStyleConfig = config.style.header

    return (
        <>
            <Header.Mobile
                bgColor={headerStyleConfig.bgColor}
                height={headerHeight}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <Link
                        href={`${appWindowConfig.APP_SUBPATH}/`}
                        underline="none"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Header.Item style={{ ...itemStyles, padding: 0 }}>
                            {headerStyleConfig.appLogo.type === 'base64' ? (
                                <img
                                    style={headerStyleConfig.appLogo.style}
                                    src={headerStyleConfig.appLogo.value}
                                    alt={headerStyleConfig.appLogo.name}
                                />
                            ) : headerStyleConfig.showEidLogo ? (
                                <EidLogoIcon />
                            ) : (
                                <Icon
                                    style={headerStyleConfig.appLogo.style}
                                    name={headerStyleConfig.appLogo.value}
                                />
                            )}
                        </Header.Item>
                    </Link>
                    {environmentName && (
                        <Box position="relative" width="32px">
                            <Tooltip title={environmentName}>
                                <StyledChip>
                                    <Typography
                                        noWrap
                                        style={{
                                            fontSize: '8px',
                                            padding: '0px 1.5px',
                                        }}
                                    >
                                        {environmentName}
                                    </Typography>
                                </StyledChip>
                            </Tooltip>
                        </Box>
                    )}

                    <Header.RightSection>
                        <Header.Item style={itemStyles}>
                            <LanguageMenu
                                showCone={false}
                                anchorPosition={{
                                    right: '-32px',
                                    top: '54px',
                                }}
                            />
                        </Header.Item>

                        {hasAccessToHelpMenu && <Box
                            data-protectedsubcomponent={hasAccessToHelpMenu} 
                            style={{
                                padding: '0.375rem 0rem',
                            }}
                        >    
                            <Header.Item style={itemStyles}>
                                <HelpMenu showCone={false} />
                            </Header.Item>
                        </Box>}        

                        <Header.Item style={itemStyles}>
                            <AccountDropdown />
                        </Header.Item>

                        <ShoppingCartMenuIcon />
                    </Header.RightSection>
                </div>
            </Header.Mobile>
        </>
    )
}
export default withRouter(ITShopMobileHeader)
