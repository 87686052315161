import { usePaginatedQuery, useQuery } from 'react-query'
import {
    useApiGetWithInfiniteScroll,
    useAxios,
    useQuery as useBrowserQuery,
} from 'packages/core'
import { useTargetPerson } from 'hooks'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import appConfig from 'config'

export const APPLICATIONS_KEY = 'APPLICATIONS'

export const useApplication = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/ProtectedAppResources/${id}/`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplications = (enabled: boolean) => {
    const [targetPerson] = useTargetPerson()
    let queryParams = []

    const [state]: any = useResourceTypeContext()

    if (targetPerson) {
        queryParams.push(`targetPersonId=${targetPerson.id}`)
    }
    if (state.searchTerm) {
        queryParams.push(`searchTerm=${encodeURIComponent(state.searchTerm)}`)
    }

    let queryString = queryParams.join('&')
    if (state.sorting.sortOrder) {
        queryString = `${queryString}&sortBy=${state.sorting.sortBy}&desc=${state.sorting.sortOrder === 'desc'}`
    }

    return useApiGetWithInfiniteScroll(
        '/api/ProtectedAppResources',
        queryString,
        enabled,
    )
}

export const useResourceTypes = (applicationId: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'RESOURCE_TYPES', applicationId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/ProtectedAppResources/resourceTypes/${applicationId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(applicationId),
        },
    )
}

export const useHandleSelectApplication = () => {
    const query = useBrowserQuery()
    const history = useHistory()

    const [selectedApplication, setSelectedApplication] = useState<any>(null)

    const selectApplication = (application: any) => {
        setSelectedApplication(application)
    }

    useEffect(() => {
        if (selectedApplication) {
            let route
            query.set('selected', selectedApplication.id)
            route = '/applications'
            history.push(`${appConfig.APP_SUBPATH}${route}?${query.toString()}`)
            setSelectedApplication(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedApplication])

    return {
        selectApplication,
    }
}

export const useApplicationOwners = (applicationId: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'OWNERS', applicationId],
        () =>
            callApi({
                method: 'GET',
                url: `api/people/resourceOwners?resourceId=${applicationId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(applicationId),
        },
    )
}

export const useAzureApplicationOwnersAndDeputies = (id: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'OWNERS_AND_DEPUTIES', id],
        () =>
            callApi({
                method: 'GET',
                url: `api/ProtectedAppResources/ownersDeputies/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCheckApplicationsAccess = (
    id: string | null,
) => {
    const callApi = useAxios()
    const [targetPerson] = useTargetPerson()

    const data = {
        targetPersonId: targetPerson.id,
        id: id,
        allowAppManagementRoles: true,
        allowAppRoles : true,
        allowRoleDefinitions: true

    }
    return useQuery(
        [`${APPLICATIONS_KEY}_ACCESS`, id, targetPerson.id],
        () =>
            callApi({
                method: 'POST',
                url: `/api/ProtectedAppResources/GetSingleRequestDetails`,
                data
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationAppRights = (
    applicationId: string | null,
    targetPersonId: string,
    skip: number,
    take: number = 3,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${APPLICATIONS_KEY}_APP_RIGHTS_LIST`,
            applicationId,
            targetPersonId,
            skip,
            take,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                `/api/LocalRights/application/assigned?targetPersonId=${targetPersonId}&skip=${skip}&take=${take}` + (applicationId ? `&applicationId=${applicationId}` : ""),
            }),
        {
            enabled: Boolean(targetPersonId),
        },
    )
}

export const useApplicationRoleDefinitions = (
    applicationId: string | null,
    targetPersonId: string,
    skip: number,
    take: number = 3,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${APPLICATIONS_KEY}_ROLE_DEFINITIONS_LIST`,
            applicationId,
            targetPersonId,
            skip,
            take,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                `/api/azureroles/application/assigned?targetPersonId=${targetPersonId}&skip=${skip}&take=${take}` + (applicationId ? `&applicationId=${applicationId}` : ""),
            }),
        {
            enabled: Boolean(targetPersonId),
        },
    )
}

export const useApplicationManagementRoleAssignments = (
    applicationId: string | null,
    targetPersonId: string,
    skip: number,
    take: number = 3,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${APPLICATIONS_KEY}_MANAGEMENT_ROLE_ASSIGNMENTS_LIST`,
            applicationId,
            targetPersonId,
            skip,
            take,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/ManagementRoles/application/assigned?targetPersonId=${targetPersonId}&skip=${skip}&take=${take}` + (applicationId ? `&applicationId=${applicationId}` : ""),
            }),
        {
            enabled: Boolean(targetPersonId),
        },
    )
}
