import { useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { HorizontalTabs } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { DropdownMenu } from 'components/DropdownMenu'
import { useHistory } from 'react-router'
import appConfig from 'config'
import { SubPathLevel } from 'appConfiguration'
import ShoppingCartMenuIcon from 'components/Layout/ITShopHeader/ShoppingCartMenuIcon'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useAppState } from 'appContext'
import EidButton from 'packages/eid-ui/EidButton'
import useSubcomponents from 'useSubcomponents'
import { useIsSmallScreen } from 'packages/core'

const SubHeaderTabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
        height: '4px',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
    },
}))

const useStyles = makeStyles(
    createStyles({
        cartMenu: {
            display: 'flex',
            '& >div:first-child': {
                minWidth: '20px !important',
                left: '-4px',
                '& hr': {
                    height: '30px !important',
                },
            },
        },
        mobileCartMenu: {
            display: 'flex',
            position: 'absolute',
            top: '25px',
            right: '8px',
            '& >div:first-child': {
                minWidth: '20px !important',
                left: '-4px',
                '& hr': {
                    height: '30px !important',
                },
            },
        },
    }),
)

const SubHeader = (props) => {
    const {
        showNavTabs = true,
        viewSwitcher,
        totalCount,
        resourceTypes,
        currentResourceType,
        viewPendingAccess,
        showWorkFlows = false,
        showBackButton = false,
    } = props

    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const classes = useStyles()

    const { hasAccessToTabs } = useSubcomponents()

    const {
        hasAccessToRequestAccess,
        hasAccessToManageAccess,
        hasAccessToWorkFlow,
    } = hasAccessToTabs(currentResourceType.name)

    const [{ appConfig: appSettings }] = useAppState()
    const history = useHistory()
    const {
        location: { pathname },
    } = history

    let subPaths = pathname.split('/')
    const areaName =
        subPaths.length >= 2 + SubPathLevel ? subPaths[2 + SubPathLevel] : ''

    const [activeTab, setActiveTab] = useState(
        areaName === 'manageAccess'
            ? 'manageAccess'
            : areaName === 'workflows'
            ? 'workFlows'
            : 'requestAccess',
    )

    return (
        <Box
            borderBottom="1px solid rgba(0, 0, 0, 0.1)"
            height={isSmallScreen ? '128px' : '60px'}
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignItems={isSmallScreen ? 'start' : 'center'}
            justifyContent="space-between"
            width="100%"
        >
            <Box
                display="flex"
                paddingLeft={isSmallScreen ? '8px' : '0px'}
                justifyContent="space-between"
            >
                <DropdownMenu
                    routeOptions={resourceTypes}
                    currentResourceType={currentResourceType}
                    totalCount={totalCount}
                />
                {isSmallScreen && appSettings.style.header.hide && (
                    <Box className={classes.mobileCartMenu}>
                        <ShoppingCartMenuIcon />
                    </Box>
                )}
            </Box>

            {showNavTabs && (
                <Box
                    style={{
                        width: isSmallScreen ? '100%' : 'auto',
                        backgroundColor: isSmallScreen ? '#fff' : 'transparent',
                    }}
                >
                    <SubHeaderTabs
                        variant="scrollable"
                        value={activeTab}
                        onChange={(_, value) => {
                            let route
                            if (value === 'manageAccess') {
                                route = `${appConfig.APP_SUBPATH}${currentResourceType.route}/manageAccess`
                            } else if (value === 'workFlows') {
                                route = `${appConfig.APP_SUBPATH}${currentResourceType.route}/workflows`
                            } else {
                                route = `${appConfig.APP_SUBPATH}${currentResourceType.route}`
                            }
                            history.push(route)
                            setActiveTab(value)
                        }}
                    >
                        {hasAccessToRequestAccess && (
                            <HorizontalTabs.Tab
                                key={1}
                                value="requestAccess"
                                label={t('Common_RequestAccess')}
                                data-protectedsubcomponent={
                                    hasAccessToRequestAccess
                                }
                            />
                        )}
                        {hasAccessToManageAccess && (
                            <HorizontalTabs.Tab
                                key={2}
                                value="manageAccess"
                                label={t('Common_ManageAccess')}
                                data-protectedsubcomponent={
                                    hasAccessToManageAccess
                                }
                            />
                        )}
                        {showWorkFlows && hasAccessToWorkFlow && (
                            <HorizontalTabs.Tab
                                key={3}
                                value="workFlows"
                                label={t('Workflows')}
                                data-protectedsubcomponent={hasAccessToWorkFlow}
                            />
                        )}
                    </SubHeaderTabs>
                </Box>
            )}

            {!isSmallScreen && (
                <Box
                    display="flex"
                    marginRight={
                        appSettings.style.header.hide ? '-30px' : '10px'
                    }
                    minWidth={appSettings.style.header.hide ? '252px' : '130px'}
                    justifyContent="end"
                >
                    {viewPendingAccess}
                    {showBackButton ? (
                        <EidButton.BackButton label={t('BackToWorkflows')} />
                    ) : (
                        viewSwitcher
                    )}
                    {appSettings.style.header.hide && (
                        <Box className={classes.cartMenu}>
                            <ShoppingCartMenuIcon />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    )
}

export default SubHeader
