import { Box, Typography, styled } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const LinkWrapper = styled(RouterLink)(({ theme, selected }) => ({
    textDecoration: 'none',
    minHeight: '40px',
    padding: '0px 30px',
    backgroundColor: selected && theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'padding 0.5s ease',
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
}))

const Tab = (props) => {
    const { to, icon, label, count, selected } = props
    return (
        <LinkWrapper to={to} selected={selected}>
            <Box display="flex" alignItems="center" minWidth="17px">
                {icon}
            </Box>
            <Box color="#000000" marginLeft="25px">
                <Typography variant="body1">{label}</Typography>
            </Box>

            <Box color="#919193" position="absolute" top="25%" right="30px">
                <Typography variant="body2">{count}</Typography>
            </Box>
        </LinkWrapper>
    )
}

export default Tab
