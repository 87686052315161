import { Icon } from 'packages/eid-icons'
import DropdownFilter from '../DropdownFilter'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import appConfig from 'config'
import useSubcomponents from 'useSubcomponents'

const DropdownMenuItem = ({
    currentResourceType,
    resource,
    areaName,
    setOpen,
}) => {
    const { t } = useTranslation()
    const history = useHistory()

    const { hasAccessToTabs } = useSubcomponents()

    const { hasAccessToAtleastOneTab } = hasAccessToTabs(resource.name)
    return (
        <>
            {hasAccessToAtleastOneTab ? (
                <DropdownFilter.Item
                    key={resource.name}
                    selected={resource.name === currentResourceType.name}
                    onClick={() => {
                        setOpen((prev) => !prev)
                        if (areaName === 'manageAccess') {
                            let URL = `${appConfig.APP_SUBPATH}${resource.route}/manageAccess`
                            history.push(URL)
                        } else {
                            history.push(
                                `${appConfig.APP_SUBPATH}${resource.route}`,
                            )
                        }
                    }}
                >
                    <Icon
                        width="16px"
                        height="16px"
                        name={resource.icon.small}
                        color="#919193"
                    />
                    {t(resource.title)}
                </DropdownFilter.Item>
            ) : (
                <></>
            )}
        </>
    )
}

export default DropdownMenuItem
