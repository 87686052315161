import { ShoppingCartIcon } from 'packages/eid-icons'
import { Header } from 'packages/eid-ui'
import {
    Badge,
    Box,
    Divider,
    makeStyles,
    styled,
    useTheme,
} from '@material-ui/core'
import { useIsSmallScreen, useQuery } from 'packages/core'
import { useAllCarts, useCart } from 'hooks'
import { Cart } from 'components'
import CartDropdown from '../CartDropdown'
import { Skeleton } from '@material-ui/lab'
import { useHistory } from 'react-router'

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: theme.palette.quaternary.main,
        color: '#ffffff',
        height: '16px',
        minWidth: '12px',
        fontSize: '10px',
    },
}))

const useStyles = makeStyles((theme) => ({
    multipleCartIndicator: {
        position: 'absolute',
        top: '-4px',
        right: 0,
        height: '8px',
        width: '8px',
        borderRadius: '50%',
        backgroundColor: theme.palette.quaternary.main,
        zIndex: 3,
    },
}))

const GroupedButtonContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    height: '34px',

    border: '1px solid transparent',
    borderRadius: '5px',

    '& >div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:hover': {
            cursor: 'pointer',
        },
    },

    '& >div:first-child': {
        borderRight: '1px solid  #b4b4b4',
    },
}))

const ShoppingCartMenuIcon = ({ showDivider = true, showDrawer = true }) => {
    const history = useHistory()
    const query = useQuery()
    const cartOpen = query.get('cart') === 'visible'
    const classes = useStyles()
    const isSmallScreen = useIsSmallScreen()
    const theme = useTheme()

    const { data: cart } = useCart()
    const { hasMultipleCarts } = useAllCarts()
    return (
        <>
            {showDivider && (
                <Header.Item
                    style={{ minWidth: isSmallScreen ? '30px' : '40px' }}
                >
                    <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        style={{
                            backgroundColor: '#e8e8e8',
                            height: 26,
                        }}
                    />
                </Header.Item>
            )}

            <Header.Item
                style={{ marginRight: isSmallScreen ? '8px' : '35px' }}
            >
                {!cart ? (
                    <Skeleton width="80px" height="34px" />
                ) : (
                    <GroupedButtonContainer>
                        <Box
                            width="45px"
                            onClick={() => {
                                query.set('cart', 'visible')
                                history.push(
                                    `${
                                        history.location.pathname
                                    }?${query.toString()}`,
                                )
                            }}
                        >
                            <StyledBadge badgeContent={cart.cartItems.length}>
                                <ShoppingCartIcon
                                    color={theme.palette.primary.main}
                                    width="27px"
                                    height="27px"
                                />
                            </StyledBadge>
                        </Box>

                        <CartDropdown />
                        {hasMultipleCarts && (
                            <Box className={classes.multipleCartIndicator} />
                        )}
                    </GroupedButtonContainer>
                )}
            </Header.Item>
            {showDrawer && (
                <Cart
                    open={cartOpen}
                    toggleDrawer={() => {
                        query.delete('cart')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
            )}
        </>
    )
}

export default ShoppingCartMenuIcon
