import { FC } from 'react'
import { Box, FormControlLabel, makeStyles, useTheme } from '@material-ui/core'
import { Checkbox, Divider } from 'packages/eid-ui'
import CartItemViolation from './CartItemViolation'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#efeff1',
        boxShadow: '0 4px 8px 0 rgba(208, 2, 27, 0.15)',
        border: `1px solid ${theme.palette.error.main}`,
        borderRadius: '5px',
    },
    header: {
        backgroundColor: theme.palette.error.main,
        fontSize: '18px',
        lineHeight: 1.33,
        padding: '8px 16px',
        color: '#ffffff',
    },
    content: {
        backgroundColor: '#ffffff',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    violationItem: {
        padding: '8px 16px',
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 32px',
        '& .MuiFormControlLabel-label': {
            color: '#000000',
            fontSize: '14px',
        },
        '&:hover': {
            '& .MuiFormControlLabel-label': {
                color: '#535b62',
            },
        },
    },
}))

const CartViolations: FC = ({ risks, acknowledged, setAcknowledged }: any) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                {t('Common_RiskViolationsDetected')}
            </Box>
            <Box className={classes.content}>
                {risks.map((r: any, index: number) => (
                    <Box
                        key={`${r.id}_${index}`}
                        className={classes.violationItem}
                    >
                        <CartItemViolation risk={r} />
                    </Box>
                ))}
            </Box>
            <Divider color="#d8d8dd" />
            <Box className={classes.footer}>
                <Box position="relative">
                    <FormControlLabel
                        control={
                            <Checkbox
                                edge="start"
                                color="primary"
                                onChange={(e) => {
                                    const checked = e.target.checked
                                    setAcknowledged(checked)
                                }}
                                checked={acknowledged}
                            />
                        }
                        label={t('Common_AcknowledgeViolations')}
                    />

                    <Box
                        position="absolute"
                        top={0}
                        right={0}
                        color={theme.palette.error.main}
                        zIndex={1}
                        fontSize="17px"
                    >
                        *
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export { CartViolations }
