import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'

const renderValue = (value, styles = {}) => {
    return value ? (
        <span style={{ ...styles }}>{value}</span>
    ) : (
        <span style={{ color: 'rgb(180, 180, 180)' }}>-</span>
    )
}

const TextFormatter = ({ value, maxTextWidth, style }) => {
    const tooltipRef = useRef()
    const [disableTooltip, setDisableTooltip] = useState(true)
    useEffect(() => {
        if (tooltipRef.current.scrollWidth > maxTextWidth) {
            setDisableTooltip(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Tooltip
                disableHoverListener={disableTooltip}
                title={value || ''}
                ref={tooltipRef}
            >
                {renderValue(value, {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'block',
                    width: maxTextWidth,
                    ...style,
                })}
            </Tooltip>
        </>
    )
}

TextFormatter.propTypes = {
    value: PropTypes.any,
}

TextFormatter.defaultProps = {
    maxTextWidth: 90,
}

export default TextFormatter
