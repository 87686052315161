import React from 'react'
import { Box, styled } from '@material-ui/core'
import { Spinner, Tooltip } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import {
    useCurrentPerson,
    useTargetPerson,
    useUpdateDefaultFilters,
} from 'hooks'
import useConfiguration from 'useConfiguration'
import { Icon } from 'packages/eid-icons'
import { useAppState } from 'appContext'

const DeleteFiltersCard = styled('Box')(({ showIcon }) => ({
    height: '40px',
    borderRadius: '5px',
    width: '100%',
    display: 'flex',
    padding: '10px 15px',
    justifyContent: !showIcon ? 'center' : 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    fontSize: '12px',
    '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.7)',
    },
}))

const DeleteFilters = ({ resourceType, showIcon }) => {
    const { t } = useTranslation()

    const { data: currentPerson } = useCurrentPerson()
    // eslint-disable-next-line
    const [_, dispatchAppState] = useAppState()
    const [{ savedFilters }] = useAppState()
    const [targetPerson] = useTargetPerson()

    const shoppingForSomeoneElse = targetPerson.id !== currentPerson.id

    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType(resourceType)

    const [saveFilters, { isLoading }] = useUpdateDefaultFilters()

    const deleteAllFilters = () => {
        saveFilters({ resourceType: currentResourceType.name })
        try {
            if (savedFilters) {
                const selectedFilter = {
                    ...savedFilters,
                    [currentResourceType.name]: {
                        ...savedFilters[currentResourceType.name],
                        filters: {}
                    }
                }
                dispatchAppState({
                    type: 'SAVE_FILETRS',
                    payload: selectedFilter,
                })
            }
        } catch (err) {

        }
    }

    const tooltipTitle = shoppingForSomeoneElse
        ? t('Common_DeleteFiltersDisabledForSomeoneElseHelpText')
        : t('Common_DeleteSavedFiltersHelpText')

    return (
        <Tooltip title={tooltipTitle}>
            <DeleteFiltersCard
                iconColor={shoppingForSomeoneElse ? '#b4b4b4' : 'red'}
                onClick={shoppingForSomeoneElse ? undefined : deleteAllFilters}
                style={{
                    color: shoppingForSomeoneElse ? '#b4b4b4' : '#000000',
                    cursor: shoppingForSomeoneElse ? 'default' : 'pointer',
                    opacity: shoppingForSomeoneElse ? 0.7 : 1,
                    minWidth: '110px',
                }}
                renderCloseButton={!isLoading}
                showIcon={showIcon}
            >
                {isLoading ? (
                    <Box display="flex" width="100%" justifyContent="center">
                        <Spinner size={0.8} color="red" />
                    </Box>
                ) : (
                    <>
                        {showIcon && (
                            <Icon color="#3e3e3e" name="Delete"></Icon>
                        )}
                        <div>{t('Common_DeleteFilters')}</div>
                    </>
                )}
            </DeleteFiltersCard>
        </Tooltip>
    )
}

export default DeleteFilters
