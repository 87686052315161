import React, { useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles({
    container: {
        paddingRight: '19px',
        marginBottom: '15px',
        position: 'fixed',
        zIndex: '9999999',
        background: '#00000063',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',

    },
    thirdPartycookies: {
        background: '#f7f8fa',
        maxWidth: '760px',
        width: '100%',
        borderRadius: '5px',
        padding: '40px',
    },
    thirdPartycookiesHeader: {
        paddingBottom: '40px',
        borderBottom: '1px solid #d8d8d8',
        display:'flex',
        '& h2': {
            fontSize: '24px',
            fontWeight: '500',
            margin: 0,
            marginLeft:'15px',
            '& span': {
                fontWeight: '700',
            },
        },
    },
    thirdPartycookieBody: {
        marginTop: '30px',
        '& p': {
            fontSize: '18px',
            margin: '0',
            '& span': {
                fontWeight: '700',
            },
        },
    },
    orderList: {
        marginTop: '25px',
        fontSize: '16px',
        lineHeight: '20px',
        display:'flex',
        '& span': {
            fontWeight: '700',
        },
        '& label': {
            color: '#307fc1',
            marginRight:'4px',
        },
    },
    cookiesbutton: {
        background: '#307fc1',
        border: 'solid 1px #307fc1;',
        borderRadius: '6px',
        padding: '14px 24px',
        marginTop: '40px',
        fontSize: '16px',
        color: '#fff',
        cursor: 'pointer'
    }
})

const CookieEnableModal = ({ bannerData, handleAccpet,  }) => {
    const classes = useStyles()
    useEffect(() => {
        document.body.classList.add('cookiesModal-open');

        return () => document.body.classList.remove('cookiesModal-open');
    }, [])

    return (
        <Box className={classes.container}>
            <Box className={classes.thirdPartycookies}>
                <Box className={classes.thirdPartycookiesHeader}>
                    {bannerData?.iconName && <Icon name={bannerData.iconName}/>}
                    <h2 dangerouslySetInnerHTML={{__html: bannerData?.title}} />
                </Box>
                <Box className={classes.thirdPartycookieBody}>
                    <p className={classes.thirdPartycookiesText} dangerouslySetInnerHTML={{__html: bannerData?.subTitle}} />
                    {bannerData?.items?.length ? bannerData.items.map((item,index)=>(
                        <div className={classes.orderList}><label>{index+1}.{' '}</label><div dangerouslySetInnerHTML={{__html: item}}></div></div>
                    )): null}
                    <button className={classes.cookiesbutton} onClick={() => handleAccpet()}>{bannerData?.btnLabel}</button>
                </Box>
            </Box>

            
        </Box>
    )
}

export default CookieEnableModal
