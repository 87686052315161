import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Divider, Typography } from '@material-ui/core'
import { Header, Dropdown } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useIsSmallScreen } from 'packages/core'
import useConfiguration from 'useConfiguration'
import { useTranslation } from 'react-i18next'
import { useCurrentPerson, useTargetPerson } from 'hooks'
import appConfig from 'config'
import useSubcomponents from 'useSubcomponents'

const useAccess = () => {
    const { resourceTypes } = useConfiguration()
    const {
        canShopForOthers,
        hasAccessToApplicationProcesses,
        hasAccessToBusinessDomains,
        hasAccessToTargetSystemControl,
    } = useSubcomponents()

    const hasAccessToAnyResource = resourceTypes.length > 0

    const hasAccessToGuideShop =
        hasAccessToAnyResource &&
        (canShopForOthers ||
            hasAccessToApplicationProcesses ||
            hasAccessToBusinessDomains ||
            hasAccessToTargetSystemControl)

    return {
        hasAccessToGuideShop,
        canShopForOthers,
        hasAccessToApplicationProcesses,
        hasAccessToBusinessDomains,
        hasAccessToTargetSystemControl,
    }
}

const AccountDropdown = ({ history }) => {
    const isSmallScreen = useIsSmallScreen()
    const {
        hasAccessToGuideShop,
        canShopForOthers,
        hasAccessToApplicationProcesses,
        hasAccessToBusinessDomains,
        hasAccessToTargetSystemControl,
    } = useAccess()

    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    const { data: personData } = useCurrentPerson()
    const [, , { deleteSavedTargetPersonId }] = useTargetPerson()

    const { hasAccessToTabs } = useSubcomponents()

    const { manageAccess, resourceTypes } = useConfiguration(history)

    const renderManageAccessMenuItem = (item) => {
        return (
            <span>
                <Dropdown.Item
                    onClick={(e) => {
                        const manageAccessRoute = `${appConfig.APP_SUBPATH}${resourceTypes[0].route}${manageAccess.route}`
                        history.push(manageAccessRoute)
                        setOpen((prev) => !prev)
                    }}
                >
                    <Icon name={item.icon.small} color="#919193" />
                    {t(item.title)}
                </Dropdown.Item>
                <Divider
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                    variant="middle"
                />
            </span>
        )
    }

    const renderResourceTypeMenuItem = (resourceType) => {
        const { hasAccessToRequestAccess } = hasAccessToTabs(resourceType.name)
        return (
            hasAccessToRequestAccess && (
                <Dropdown.Item
                    key={resourceType.name}
                    onClick={(e) => {
                        history.push(
                            `${appConfig.APP_SUBPATH}${resourceType.route}`,
                        )
                        setOpen((prev) => !prev)
                    }}
                >
                    <Icon
                        name={resourceType.icon.small}
                        id={`${resourceType.icon.small}-header`}
                        color="#919193"
                    />
                    {t(resourceType.title)}
                </Dropdown.Item>
            )
        )
    }

    const content = (
        <span>
            {manageAccess && renderManageAccessMenuItem(manageAccess)}
            <Typography
                style={{
                    color: '#ced1d4',
                    fontSize: '11px',
                    paddingLeft: '16px',
                    textTransform: 'uppercase',
                }}
            >
                {t('Common_RequestAccess')}
            </Typography>
            {resourceTypes.map((item) => renderResourceTypeMenuItem(item))}

            <Divider
                style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    backgroundColor: '#eef0f4',
                }}
                variant="middle"
            />
            {hasAccessToGuideShop && (
                <span
                    data-protectedsubcomponent={`${canShopForOthers} OR ${hasAccessToApplicationProcesses} OR ${hasAccessToBusinessDomains} OR ${hasAccessToTargetSystemControl}`}
                >
                    <Typography
                        style={{
                            color: '#ced1d4',
                            fontSize: '11px',
                            paddingLeft: '16px',
                            textTransform: 'uppercase',
                        }}
                    >
                        {t('OnBoarding_Guide')}
                    </Typography>
                    <Dropdown.Item
                        onClick={(e) => {
                            history.push(`${appConfig.APP_SUBPATH}/guidedShop`)
                            setOpen((prev) => !prev)
                        }}
                    >
                        <Icon name="HelpWheel" color="#919193" />
                        {t('OnBoarding_GuidedShop')}
                    </Dropdown.Item>

                    <Divider
                        style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            backgroundColor: '#eef0f4',
                        }}
                        variant="middle"
                    />
                </span>
            )}

            {/*<Typography
                style={{
                    color: '#ced1d4',
                    fontSize: '11px',
                    paddingLeft: '16px',
                    textTransform: 'uppercase',
                }}
            >
                ASSIGN AS
            </Typography>
            <Dropdown.Item
                onClick={(e) => {
                    history.push('/cloneAccess')
                }}
            >
                <Icon name="CloneAccess" />
                Clone Access
            </Dropdown.Item>

            <Divider
                style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
                variant="middle"
            />
        */}
            <Dropdown.Item
                onClick={() => {
                    deleteSavedTargetPersonId()
                    history.push('/signout')
                    setOpen((prev) => !prev)
                }}
            >
                <Icon name="LogOut" color="#919193" />
                {t('Common_SignOut')}
            </Dropdown.Item>
        </span>
    )

    const imageUrl = `${appConfig.BASE_EID_URL}${personData?.imageThumbUrl}`

    const mobile = (
        <Header.AccountDropdown
            imageUrl={imageUrl}
            anchorPosition={{ right: '-32px' }}
            friendlyName={personData?.friendlyName}
            showCone={false}
            open={open}
            handleOpen={setOpen}
        >
            {content}
        </Header.AccountDropdown>
    )

    const desktop = (
        <Header.AccountDropdown
            imageUrl={imageUrl}
            open={open}
            handleOpen={setOpen}
            friendlyName={personData?.friendlyName}
        >
            {content}
        </Header.AccountDropdown>
    )

    return isSmallScreen ? mobile : desktop
}

export default withRouter(AccountDropdown)
