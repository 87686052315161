import { useQuery } from 'packages/core'

import AppRights from './Applications/AppRights'
import RoleDefinitions from './Applications/RoleDefinitions'
import ManagementRoles from './Applications/ManagementRoles'

const ApplicationDetails = ({ item, toggleDrawer }) => {
    const query = useQuery()
    const selectedTab = query.get('tab').toLowerCase()
    return (
        <>
            {selectedTab === 'apprights' ? (
                <AppRights item={item} toggleDrawer={toggleDrawer} />
            ) : selectedTab === 'roledefinitions' ? (
                <RoleDefinitions item={item} toggleDrawer={toggleDrawer} />
            ) : (
                <ManagementRoles item={item} toggleDrawer={toggleDrawer} />
            )}
        </>
    )
}

export default ApplicationDetails
