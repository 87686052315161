import { FC, Fragment } from 'react'
import { ApplicationAppRights } from './AppRights'
import { ApplicationManagementRolesAssignments } from './ManagementRoleAssignments'
import { ApplicationRoleDefinitions } from './RoleDefinitions'

export type ProtectedApplicationAssignmentsProps = {
    applicationId: string
    applicationsType: any
    application: any
}

export const ProtectedApplicationAssignments: FC<ProtectedApplicationAssignmentsProps> = (
    props: ProtectedApplicationAssignmentsProps,
) => {
    return (
        <Fragment>
            {props.application.allowAppRoleDirectAssignment && (
                <ApplicationAppRights {...props} />
            )}

            {props.application.allowAppManagementRoleDirectAssignment && (
                <ApplicationManagementRolesAssignments {...props} />
            )}

            {props.application.allowRoleDefinitionDirectAssignment && (
                <ApplicationRoleDefinitions {...props} />
            )}
        </Fragment>
    )
}
