import { withRouter } from 'react-router'
import { EidLogoIcon, Icon } from 'packages/eid-icons'
import { Header, Tooltip } from 'packages/eid-ui'
import { Box, styled, Typography, useTheme } from '@material-ui/core'
import { useEnvironment } from 'hooks'
import LanguageMenu from './LanguageMenu'
import HelpMenu from './HelpMenu'
import AccountDropdown from './AccountDropdown'
import appWindowConfig from 'config'
import ShoppingCartMenuIcon from './ShoppingCartMenuIcon'
import { useAppState } from 'appContext'
import { useQuery } from 'packages/core'
import { headerHeight } from 'utils'
import useSubcomponents from 'useSubcomponents'

const StyledBox = styled(Box)({
    '@media (max-width:1159px)': {
        display: 'none',
    },
})

const StyledChip = styled('div')({
    position: 'absolute',
    top: '11px',
    left: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: '#ffffff',
    height: '13px',
    maxWidth: '48px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
    '@media (max-width:1159px)': {
        left: '2px',
    },
})

const OpenCartmenu = styled(Box)({
    display: 'flex',
    background: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    padding: '15px 30px',
    position: 'fixed',
    top: '0',
    right: '0',
    zIndex: '9999',
    animationDelay: '-0.6s',
    borderRadius: '4px',
    '& hr': {
        display: 'none',
    },
    '& div': {
        minWidth: 'auto !important',
        marginRight: 'auto !important',
    },
})

const ITShopHeader = () => {
    const { data: environmentName } = useEnvironment()
    const [{ appConfig: config, devFeaturesEnabled }] = useAppState()
    const query = useQuery()
    const cartOpen = query.get('cart') === 'visible'
    const headerStyleConfig = config.style.header
    const theme = useTheme()
    const { hasAccessToHelpMenu } = useSubcomponents()

    return (
        <>
            <Header
                bgColor={headerStyleConfig.bgColor}
                height={headerHeight}
                borderBottom={
                    config?.style?.header?.bottomColor
                        ? {
                              borderBottom: `2px solid ${config.style.header.bottomColor}`,
                          }
                        : {}
                }
            >
                <Header.IconLink
                    logo
                    href={
                        headerStyleConfig.showEidLogo
                            ? `${appWindowConfig.APP_SUBPATH}/`
                            : undefined
                    }
                    rightDivider={headerStyleConfig.showEidLogo}
                >
                    {headerStyleConfig.showEidLogo && <EidLogoIcon />}
                </Header.IconLink>
                <Header.IconLink logo href={`${appWindowConfig.APP_SUBPATH}/`}>
                    {headerStyleConfig?.appLogo?.type === 'icon' ? (
                        <Icon
                            style={headerStyleConfig?.appLogo?.style}
                            name={headerStyleConfig?.appLogo?.value}
                            color={theme.palette.primary.main}
                        />
                    ) : (
                        <img
                            style={headerStyleConfig?.appLogo?.style}
                            src={headerStyleConfig?.appLogo?.value}
                            alt={headerStyleConfig?.appLogo?.name}
                        />
                    )}
                </Header.IconLink>
                <StyledBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    {headerStyleConfig?.appTitle?.type === 'text' ? (
                        <Header.Title
                            style={headerStyleConfig?.appTitle?.style}
                        >
                            {headerStyleConfig?.appTitle?.value}
                        </Header.Title>
                    ) : (
                        <Header.IconLink
                            logo
                            href={`${appWindowConfig.APP_SUBPATH}/`}
                        >
                            <img
                                style={headerStyleConfig?.appTitle?.style}
                                src={headerStyleConfig?.appTitle?.value}
                                alt={headerStyleConfig?.appTitle?.name}
                            />
                        </Header.IconLink>
                    )}
                </StyledBox>
                {headerStyleConfig.showEnvironment && environmentName && (
                    <Box position="relative" width="32px">
                        <Tooltip title={environmentName}>
                            <StyledChip>
                                <Typography
                                    noWrap
                                    style={{
                                        fontSize: '8px',
                                        padding: '0px 1.5px',
                                    }}
                                >
                                    {environmentName}
                                </Typography>
                            </StyledChip>
                        </Tooltip>
                    </Box>
                )}
                {devFeaturesEnabled && (
                    <Box position="relative">
                        <StyledChip>
                            <Typography
                                noWrap
                                style={{
                                    fontSize: '8px',
                                    padding: '0px 1.5px',
                                }}
                            >
                                - WIP
                            </Typography>
                        </StyledChip>
                    </Box>
                )}

                <Header.RightSection>
                    <Header.Item
                        style={{ minWidth: '32px', padding: '0px 8px' }}
                    >
                        <LanguageMenu
                            anchorPosition={{
                                right: '-32px',
                                top: '54px',
                            }}
                        />
                    </Header.Item>

                    {hasAccessToHelpMenu && (
                        <Box
                            data-protectedsubcomponent={hasAccessToHelpMenu}
                            style={{
                                padding: '1.5rem 0rem',
                            }}
                        >
                            <Header.Item
                                style={{ minWidth: '32px', padding: '0px 8px' }}
                            >
                                <HelpMenu />
                            </Header.Item>
                        </Box>
                    )}

                    <Header.Item
                        style={{ minWidth: '32px', padding: '0px 8px' }}
                    >
                        <AccountDropdown />
                    </Header.Item>
                    <ShoppingCartMenuIcon />
                </Header.RightSection>
            </Header>
            {cartOpen && (
                <OpenCartmenu>
                    <ShoppingCartMenuIcon showDrawer={false} />
                </OpenCartmenu>
            )}
        </>
    )
}
export default withRouter(ITShopHeader)
