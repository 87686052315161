export const getResourceItemFromList = (id, list, currentResourceTypeName) => {
    let item = null

    switch (currentResourceTypeName) {
        case 'BusinessRoles':
        case 'Mailboxes':
        case 'AzureRoles':
        case 'SharedFolders':
            item = list?.find(
                (i) =>
                    i.assignment.resourceAssignment.resourceAssignmentId === id,
            )
            break
        case 'Computers':
            item = list.find((i) => i.resource?.id.toString() === id)
            if (!item) {
                item = list?.find(
                    (i) =>
                        i.assignment?.resourceAssignment
                            ?.resourceAssignmentId === id,
                )
            }
            break
        default:
            item = list.find((i) => i.resource.id.toString() === id)
            break
    }

    return item
}

export const getUniqueId = (item) => {
    if (
        !item?.resource?.id &&
        !item?.assignment?.resourceAssignment?.resourceAssignmentId
    ) {
        return item.id
    }

    switch (item.resource.resourceTypeName) {
        case 'BusinessRole':
        case 'MailBox':
        case 'AzureRole':
        case 'SharedFolder':
            return item.assignment.resourceAssignment.resourceAssignmentId
        case 'Computer':
            let resourceAssignment = item.assignment?.resourceAssignment
            return resourceAssignment
                ? resourceAssignment.resourceAssignmentId
                : item.resource.id
        default:
            return item.resource.id
    }
}
