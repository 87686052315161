import { useQuery } from 'packages/core'
import ManageCredentialDetails from './ManageCredentialDetails'
import CredentialHistoryDetails from './CredentialHistoryDetails'

const CredentialDetails = ({ item, toggleDrawer }) => {
    const query = useQuery()
    const selectedTab = query.get('tab')
    return (
        <>
            {selectedTab === 'credentials' ? (
                <ManageCredentialDetails
                    item={item}
                    toggleDrawer={toggleDrawer}
                />
            ) : (
                <CredentialHistoryDetails
                    item={item}
                    toggleDrawer={toggleDrawer}
                />
            )}
        </>
    )
}

export default CredentialDetails
