import React from 'react'
import PropTypes from 'prop-types'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import Masonry from 'react-masonry-component'
import classNames from 'classnames'
import { Loader } from '../../eid-ui'

const useStyles = makeStyles(() =>
    createStyles({
        computerCardView: {
            '& > div': {},
            '& > div > div': {
                width: '33% !important',
            },
        },
        root: {
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            paddingBottom: '40px',
        },
    }),
)

const CardList = ({
    loadingIndicator,
    noItemMessage,
    renderItem,
    infiniteScrollObervableDiv,
    fetcher,
    cardType = '',
}) => {
    const { list, loading, loadingMore } = fetcher()
    const classes = useStyles()
    return (
        <>
            {!loading && list && list.length === 0 && (
                <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    padding="20px"
                    marginTop="96px"
                >
                    {noItemMessage}
                </Box>
            )}
            <Box
                className={classNames(classes.root, {
                    [classes.computerCardView]: cardType === 'Computers',
                })}
            >
                <Masonry
                    options={{ transitionDuration: 0, horizontalOrder: true }}
                    enableResizableChildren
                >
                    {loading && loadingIndicator}
                    {!loading && list.map(renderItem)}
                </Masonry>
                {infiniteScrollObervableDiv}
                {loadingMore && <Loader height="1.5in" width="100%" />}
            </Box>
        </>
    )
}

CardList.propTypes = {
    /**
     * If the url is provided, loading, loadingMore and data props will be ignored.
     * In this case, the component will handle API call(s) itself.
     */
    url: PropTypes.string,

    /**
     * If the data fetching is being handled outside the component, this provides the initial loading state.
     */
    loading: PropTypes.bool,

    /**
     * Loading indicator. It should be a skeleton based loading indicator. If none is provided, a spinner will be used as a fallback.
     */
    loadingIndicator: PropTypes.any,

    /**
     * If the data fetching is being handled outside the component, this provides the loading more state for pagination.
     */
    loadingMore: PropTypes.bool,

    /**
     * This should be used when url prop is not present, otherwise it will be ignored. Should be an array of items.
     */
    list: PropTypes.array,

    /**
     * Fallback message to render if no data is available. Default value is "No Items available."
     */
    noItemMessage: PropTypes.object,

    /**
     * Observable div component for infinite scroll. Intersection observer API waits
     * for this div to be in viewport to notify parent of any action.
     */
    infiniteScrollObervableDiv: PropTypes.object,
}

CardList.defaultProps = {
    loadingIndicator: <Loader width="100%" />,
    noItemMessage: 'No Items available.',
}

export default CardList
