import { useState } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    expandIcon: {
        width: '16px',
        height: '16px',
        marginLeft: '8px',
        borderRadius: '50%',
        border: '2px solid #307fc1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        color: '#307fc1',
        cursor: 'pointer',
    },

    expandButton: {
        textTransform: 'uppercase',
        alignSelf: 'flex-start',
        paddingTop: '10px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
})

const styles = {
    friendlyName: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#1b1f1f',
    },
    titleMessage: {
        fontSize: '16px',
        color: '#1b1f1f',
    },
}

const ItemMessageDetailContainer = ({
    friendlyName,
    titleMessage,
    icon,
    detailsMessage,
}) => {
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false)

    const MAX_CHARACTERS_WITHOUT_EXPAND = 110
    return (
        <>
            <Box display="flex" width="100%" marginTop="16px">
                <Box display="flex">{icon}</Box>
                <Box display="flex" marginX="8px">
                    <Typography style={styles.friendlyName}>
                        {friendlyName}
                    </Typography>
                </Box>

                <Typography>{titleMessage}</Typography>
            </Box>

            {detailsMessage && (
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    marginX="32px"
                >
                    <Box paddingTop="10px" minWidth="750px" maxWidth="750px">
                        {detailsMessage.length < MAX_CHARACTERS_WITHOUT_EXPAND
                            ? detailsMessage
                            : expanded
                            ? detailsMessage
                            : detailsMessage.substring(
                                  0,
                                  MAX_CHARACTERS_WITHOUT_EXPAND,
                              ) + '...'}
                    </Box>

                    {detailsMessage.length >= MAX_CHARACTERS_WITHOUT_EXPAND && (
                        <Box
                            className={classes.expandButton}
                            onClick={() => setExpanded((prev) => !prev)}
                        >
                            <Box
                                classes={{
                                    root: classes.expandIcon,
                                }}
                            >
                                {expanded ? '-' : '+'}
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </>
    )
}

export default ItemMessageDetailContainer
