import { styled, Tab as MuiTab } from '@material-ui/core'

const Tab = styled(MuiTab)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 'fit-content',
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: 8,
    marginRight: 8,
    '&.Mui-selected': {
        fontWeight: 'bold',
    },
}))

export default Tab
