import {
    Box,
    makeStyles,
    Button,
    Dialog,
    Typography,
    IconButton,
} from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import moment from 'moment'
import { Avatar } from 'packages/eid-ui'
import { getFullImageUrl } from 'utils'
import RecordingsTab from 'containers/ManageAccess/Details/Computers/RecordingTab'
import {
    useAccessRequestPolicy,
    useCurrentPerson,
    useHasAccessToViewPSMRecordings,
} from 'hooks'
import useSubcomponents from 'useSubcomponents'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'
import RemainingTime from 'components/RemainingTime'
const mobileScreenWidth = '767px'

const useDialogStyles = makeStyles(() => ({
    paper: {
        maxWidth: '1000px',
        width: '840px',
        [`@media (max-width:${mobileScreenWidth})`]: {
            margin: '64px 16px',
            width: 'calc(100% - 32px) !important',
            maxWidth: 'calc(100% - 32px) !important ',
        },
    },
}))

const useStyles = makeStyles({
    body: () => ({
        backgroundColor: '#fbfbfd',
        padding: '24px 40px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',

        '&:focus': {
            outline: 'none',
        },
    }),
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '80px',
        alignItems: 'center',
        padding: '16px 12px 16px 32px',
        backgroundColor: '#ffffff',
    },
    closeIcon: {
        display: 'flex',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#3b454d',
    },
    sectionHeader: {
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        color: '#000000 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '14px',
        padding: '15px 31px',
        borderTop: '1px solid rgba(0, 0, 0, .05)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        fontWeight: 'bold',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
    bodyinner: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: '16px',
        [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
            display: 'block',
        },
    },
    bodyleftinner: {
        maxWidth: '232px',
        width: '100%',
    },
    bodyleftinnertitle: {
        fontSize: '12px',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: '#9b9b9b',
        margin: 0,
    },
    bodyrightinnerresult: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#000',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
    },
    footer: {
        '& button': {
            background: '#307fc1',
            '&:hover': {
                background: '#307fc1',
            },
        },
    },
    avatarname: {
        marginLeft: '5px',
    },
})

export interface ComputerSessionRequestDetailsProps {
    item: any
    open: boolean
    onClose: () => void
    title: string
    handleTerminate: () => void
}

export const ComputerSessionRequestDetails: FC<ComputerSessionRequestDetailsProps> = (
    props,
) => {
    const { open, onClose, title, item, handleTerminate } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const dialogClasses = useDialogStyles()

    const ComputersConnectedWhenAndDuration = (name: string, data: any) => {
        const { t } = useTranslation()
        const whenData = data[name]
        let connectedWhen = `0 ${t('min')}`
        if (whenData?.days > 0) {
            connectedWhen = `${whenData.days} ${
                whenData.days > 1 ? t('days') : t('day')
            }`
        }
        if (whenData?.hours > 0) {
            connectedWhen =
                connectedWhen === `0 ${t('min')}`
                    ? `${whenData.hours} ${
                          whenData.hours > 1 ? t('hours') : t('hour')
                      }`
                    : connectedWhen +
                      ` ${whenData.hours} ${
                          whenData.hours > 1 ? t('hours') : t('hour')
                      }`
        }
        if (whenData?.minutes > 0) {
            connectedWhen =
                connectedWhen === `0 ${t('min')}`
                    ? `${whenData.minutes} ${
                          whenData.minutes > 1 ? t('mins') : t('min')
                      }`
                    : connectedWhen +
                      ` ${whenData.minutes} ${
                          whenData.minutes > 1 ? t('mins') : t('min')
                      }`
        }
        return <>{connectedWhen}</>
    }

    const accessRequestPolicy = useAccessRequestPolicy(item.requestPolicyId)
    const { data: currentPerson } = useCurrentPerson()
    const isSelf = currentPerson.id === item.checkedOutByPersonId

    const { canSeeComputersPSMSessionsRecordings } = useSubcomponents()

    const canSeeRecordedSession =
        canSeeComputersPSMSessionsRecordings &&
        !item.canTerminateSession &&
        item.recordingUploaded

    const canSeeLiveSession =
        canSeeComputersPSMSessionsRecordings &&
        item.canTerminateSession &&
        accessRequestPolicy?.allowLiveSessionSnooping

    const canSeeRecordings = canSeeRecordedSession || canSeeLiveSession

    const [showRecordings, setShowRecordings] = useState(
        isSelf && canSeeRecordings,
    )

    const {
        data: accessToViewSomeoneElseRecordings,
        isLoading: isLoadingRecordingsAccess,
    } = useHasAccessToViewPSMRecordings(
        item.checkedOutByPersonId,
        !isSelf && canSeeRecordings,
    )

    useEffect(() => {
        if (accessToViewSomeoneElseRecordings?.hasAccess && canSeeRecordings) {
            setShowRecordings(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToViewSomeoneElseRecordings, isLoadingRecordingsAccess])

    return (
        <Dialog
            scroll={'body'}
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={title}
            classes={dialogClasses}
        >
            <Box className={classes.modalHeader}>
                <Typography className={classes.title}>
                    {title ? title : '-'}
                </Typography>

                <Box className={classes.closeIcon}>
                    <IconButton onClick={onClose}>
                        <Icon name="Close" color="#959598" />
                    </IconButton>
                </Box>
            </Box>
            <Box className={classes.sectionHeader}>
                {t('Common_SessionHistoryDetails')}
            </Box>
            <Box className={classes.body}>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_ComputerId')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.computerId ? item.computerId : '-'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_Credentials')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.externalCredentialFriendlyName
                                ? item.externalCredentialFriendlyName
                                : '-'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_ConnectedPersonName')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            <Avatar
                                size="small"
                                src={getFullImageUrl(
                                    item.checkedOutByPersonThumbUrl,
                                )}
                            />
                            <span className={classes.avatarname}>
                                {item.checkedOutByPersonName
                                    ? item.checkedOutByPersonName
                                    : '-'}
                            </span>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_ConnectedPersonLogin')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.checkedOutByPersonName
                                ? item.checkedOutByPersonName
                                : '-'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_ConnectedPersonId')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.checkedOutByPersonId
                                ? item.checkedOutByPersonId
                                : '-'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_StartTime')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.startTimeUtc
                                ? moment
                                      .utc(item.startTimeUtc)
                                      .local()
                                      .format('L LT')
                                : '-'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_EndTime')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.endTimeUtc
                                ? moment
                                      .utc(item.endTimeUtc)
                                      .local()
                                      .format('L LT')
                                : '-'}
                        </Box>
                    </Box>
                </Box>
                {item.canTerminateSession ? (
                    <Box className={classes.bodyinner}>
                        <Box className={classes.bodyleftinner}>
                            <Box
                                component="h2"
                                className={classes.bodyleftinnertitle}
                            >
                                {t('RemainingTime')}
                            </Box>
                        </Box>
                        <Box>
                            <Box
                                component="h2"
                                className={classes.bodyrightinnerresult}
                            >
                                <RemainingTime
                                    endTime={item.credCheckedOutEndTimeUtc}
                                />
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box className={classes.bodyinner}>
                        <Box className={classes.bodyleftinner}>
                            <Box
                                component="h2"
                                className={classes.bodyleftinnertitle}
                            >
                                {t('Common_ConnectedWhen')}
                            </Box>
                        </Box>
                        <Box>
                            <Box
                                component="h2"
                                className={classes.bodyrightinnerresult}
                            >
                                {ComputersConnectedWhenAndDuration(
                                    'when',
                                    item,
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}

                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_SharedCredentialsId')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.externalCredentialId
                                ? item.externalCredentialId
                                : '-'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_RDPSession')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.isRdp ? t('Common_Yes') : t('Common_No')}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_TerminateSession')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.canTerminateSession
                                ? t('Common_Yes')
                                : t('Common_No')}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_LOA')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.assuranceLevel ? item.assuranceLevel : '-'}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {showRecordings && (
                <>
                    <Box
                        data-protectedsubcomponent={
                            canSeeComputersPSMSessionsRecordings
                        }
                        className={classes.sectionHeader}
                    >
                        {t('Common_Recording')}
                    </Box>
                    <Box
                        data-protectedsubcomponent={
                            canSeeComputersPSMSessionsRecordings
                        }
                        className={classes.body}
                    >
                        <RecordingsTab item={item} />
                    </Box>
                </>
            )}
            {item.canTerminateSession ? (
                <Box className={classes.footer}>
                    <Box display="flex" width="100%" padding="24px 32px">
                        <Box minWidth="167px">
                            <Button
                                onClick={handleTerminate}
                                style={{ padding: '14px 24px' }}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginLeft="10px"
                                >
                                    <Typography
                                        style={{
                                            color: '#ffffff',
                                            fontSize: '16px',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {t('Common_Disconnect')}
                                    </Typography>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <></>
            )}
        </Dialog>
    )
}

export default ComputerSessionRequestDetails
