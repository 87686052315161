import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { EidWorkflow } from 'packages/eid-ui'
import config from 'config'
import classNames from 'classnames'
import appConfig from 'config'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            minHeight: '40px',
            borderRadius: '4px',
            border: `solid 1px ${theme.palette.primary.main}`,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            fontSize: '16px',
            padding: theme.spacing(1),
            cursor: 'pointer',
            '&:hover': {
                boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
            },
        },
        symbol: {
            minWidth: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        text: {
            width: '90%',
            overflowWrap: 'break-word',
        },
        disabled: {
            backgroundColor: '#ebebed',
            cursor: 'no-drop',
            '&:hover': {
                boxShadow: 'none',
            },
        },
    }),
)

export type EidWorkflowButtonProps = {
    name: string
    title: string
    path: string
    mobile?: boolean
    disabled?: any
}

export const EidWorkflowButton: FC<EidWorkflowButtonProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const query = useQuery()
    const history = useHistory()

    const onClick = () => {
        query.set('workflowName', props.name)
        query.set('returnUrl', getFullPathFromUrl())
        history.push(`${appConfig.APP_SUBPATH}${props.path}/workflows?${query.toString()}`)
    }

    if (props.mobile) {
        return (
            <EidWorkflow
                title={t(props.title)}
                baseEidUrl={config.BASE_EID_URL}
                workflowName={props.name}
            >
                <Box className={classes.root}>
                    <Box className={classes.symbol}>+</Box>
                    <Box className={classes.text}>{t(props.title)}</Box>
                </Box>
            </EidWorkflow>
        )
    }
    return (
        <Fragment>
            <Box
                className={classNames(classes.root, {
                    [classes.disabled]: props.disabled,
                })}
                onClick={props.disabled ? () => { } : onClick}
            >
                <Box className={classes.symbol}>+</Box>
                <Box className={classes.text}>{t(props.title)}</Box>
            </Box>
        </Fragment>
    )
}
