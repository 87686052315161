import {
    createMuiTheme,
    CssBaseline,
    MuiThemeProvider,
} from '@material-ui/core'
import { useAppState } from 'appContext'
import { defaultThemeObject } from 'defaultTheme'
import { deepMergeObjects } from 'packages/core'
import { useMemo } from 'react'

const withCustomTheme = (ChildComponent) => (props) => {
    const [{ appConfig }] = useAppState()

    const customTheme = deepMergeObjects(
        defaultThemeObject,
        appConfig?.style?.theme,
        false,
    )

    const theme = useMemo(() => createMuiTheme(customTheme), [customTheme])

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <ChildComponent {...props} />
            </MuiThemeProvider>
        </>
    )
}

export default withCustomTheme
