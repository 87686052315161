const filters = [
    {
        forRequestAccess: true,
        forManageAccess: true,
        credentials: true,
        history: true,
        name: 'ShoppingFor',
        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        title: {
            requestAccessTitle: 'Common_TargetPerson',
            manageAccessTitle: 'Common_ManageFor',
        },
        order: 1,
    },
    {
        forRequestAccess: false,
        forManageAccess: true,
        history: true,
        credentials: false,
        dynamic: true,
        name: 'HistoryFor',
        title: 'Common_ShowHistoryFor',
        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        order: 3,
        colorCode: {
            primary: 'rgb(65 154 183)',
            secondary: 'rgba(65 154 183, 0.09)',
        },
        type: 'autocomplete',
        placeholder: 'Common_SelectPerson',
        url: '/api/people/search',
        queryParams: {
            take: 10,
        },
        label: 'Common_ShowHistoryFor',
        optionConfig: {
            type: 'person',
            labelProp: 'friendlyName',
            imageUrlProp: 'imageUrl',
        },
        excludeOptionsConfig: {
            contextType: 'local',
            contextProp: 'targetPerson',
            valueProp: 'id',
        },
        debounceInterval: 500,
        dedupingInterval: 60000,
        contextProp: 'requestedByPerson',
        queryParam: {
            name: 'RequestedByPersonId',
            valueProp: 'id',
        },
    },
    {
        forManageAccess: true,
        credentials: true,
        history: true,
        dynamic: true,
        name: 'AccessType',
        title: 'AccessType',
        order: 2,
        colorCode: {
            primary: 'rgba(107,0,123,1)',
            secondary: 'rgba(107,0,123,0.1)',
        },
        type: 'nullableBoolean',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
            trueLabel: 'PreApproved',
            falseLabel: 'NotPreApproved',
            nullLabel: 'Common_All',
        },
        contextProp: 'accessType',
        queryParam: {
            name: 'ShowPreApproved',
        },
    },
    {
        forManageAccess: false,
        dynamic: true,
        name: 'Applications',
        title: 'Common_Applications',
        order: 4,
        colorCode: {
            primary: '#ff5e65',
            secondary: '#fee8f2',
        },
        type: 'autocomplete',
        label: 'Common_Applications',
        placeholder: 'Common_SelectApplication',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/ProtectedAppResources',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'application',
        queryParam: {
            name: 'applicationId',
            valueProp: 'id',
        },
        basedOn: {
            type: 'required',
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
            queryParamName: 'targetPersonId',
        },
    },
    {
        forManageAccess: true,
        credentials: true,
        history: true,
        dynamic: true,
        name: 'Status',
        title: 'ShowByStatus',

        order: 3,
        colorCode: {
            primary: 'rgba(65,66,183,1)',
            secondary: 'rgba(65,66,183,0.1)',
        },
        type: 'nullableBoolean',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
            trueLabel: 'ActiveCheckedOut',
            falseLabel: 'AvailableForCheckOut',
            nullLabel: 'Common_All',
        },
        contextProp: 'status',
        queryParam: {
            name: 'HasActiveCheckout',
        },
    },

    {
        forManageAccess: true,
        credentials: true,
        history: true,
        dynamic: true,
        name: 'Type',
        title: 'CredentialsType',
        order: 3,
        colorCode: {
            primary: 'rgba(10,172,0,1)',
            secondary: 'rgba(10,172,0,0.1)',
        },
        type: 'select',
        placeholder: 'Common_All',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
        },
        url: '/api/Credentials/types',
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'type',
        queryParam: {
            name: 'typeId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        credentials: true,
        history: true,
        dynamic: true,
        name: 'Category',
        title: 'CredentialsCategory',
        order: 3,
        colorCode: {
            primary: 'rgba(35,73,144,1)',
            secondary: 'rgba(35,73,144,0.1)',
        },
        type: 'select',
        placeholder: 'Common_All',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
        },
        url: '/api/Credentials/categories',
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'category',
        queryParam: {
            name: 'categoryId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        credentials: true,
        history: true,
        name: 'TargetSystem',
        title: 'Common_TargetSystem',

        order: 7,
        colorCode: {
            primary: '#307fc1',
            secondary: 'rgba(48, 127, 193, 0.09)',
        },
        contextProp: 'accountStore',
        type: 'autocomplete',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        queryParam: {
            name: 'AccountStoreGUID',
            type: 'guid',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        credentials: true,
        history: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-CredentialsAdvancedSearch-Control',
        },
        order: 8,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'FriendlyName',
                    type: 'text',
                    placeholder: 'Common_FriendlyName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                    queryParam: {
                        name: 'EID_FriendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'LogonName',
                    type: 'text',
                    placeholder: 'Credentials_AccountLogonName',
                    contextProp: 'advancedSearch.forms.logonName',
                    queryParam: {
                        name: 'AccountLogonName',
                        type: 'string',
                    },
                },
                {
                    name: 'description',
                    type: 'text',
                    placeholder: 'Credentials_Description',
                    contextProp: 'advancedSearch.forms.description',
                    queryParam: {
                        name: 'Description',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    //Add columnName property as sorting property for Request Access and manageAccessColumnName property for Manage Access if sorting can't be done on name property
    {
        name: 'friendlyName',
        columnName: 'friendlyName',
        label: 'Common_DisplayName',
        isHeader: true,
        sortable: true,
        forHistory: true,
        showMasterLock: false,
        credentials: true,
        history: false,
        headerCellStyles: {
            width: '250px',
            minWidth: '250px',
            maxWidth: '250px',
        },
        styles: {
            width: '250px',
            maxWidth: '250px',
            minWidth: '250px',
        },
    },

    {
        name: 'userName',
        columnName: 'userName',
        type: 'credentialLockedAttribute',
        label: 'Credentials_Username',
        manageAccessOnlyAttribute: true,
        showMasterLock: true,
        credentials: true,
        history: false,
        component: {
            name: 'CredentialsLockedAttribute',
            length: 10,
            locked: true,
            targetUrl: '/api/credentials/hiddenProperties',
            targetProperty: 'userName',
            validationMessage: 'CredentialCheckoutValidationMessage',
        },
        headerCellStyles: {
            width: '120px',
            minWidth: '120px',
            maxWidth: '120px',
        },
        styles: {
            width: '120px',
            maxWidth: '120px',
            minWidth: '120px',
        },
    },
    {
        name: 'password',
        columnName: 'password',
        label: 'Password',
        type: 'credentialLockedAttribute',
        manageAccessOnlyAttribute: true,
        showMasterLock: true,
        credentials: true,
        history: false,
        component: {
            name: 'CredentialsLockedAttribute',
            length: 10,
            locked: true,
            targetUrl: '/api/credentials/hiddenProperties',
            targetProperty: 'password',
            validationMessage: 'CredentialCheckoutValidationMessage',
        },
        headerCellStyles: {
            width: '110px',
            minWidth: '110px',
            maxWidth: '110px',
        },
        styles: {
            width: '110px',
            maxWidth: '110px',
            minWidth: '110px',
        },
    },
    {
        name: 'notes',
        columnName: 'notes',
        label: 'Notes',
        type: 'credentialLockedAttribute',
        manageAccessOnlyAttribute: true,
        showMasterLock: true,
        credentials: true,
        history: false,
        component: {
            name: 'CredentialsLockedAttribute',
            length: 10,
            locked: true,
            targetUrl: '/api/credentials/hiddenProperties',
            targetProperty: 'notes',
            validationMessage: 'CredentialCheckoutValidationMessage',
        },
        headerCellStyles: {
            width: '70px',
            minWidth: '70px',
            maxWidth: '70px',
        },
        styles: {
            width: '70px',
            maxWidth: '70px',
            minWidth: '70px',
        },
    },

    {
        name: 'checkedOutByLogin',
        columnName: 'checkedOutByLogin',
        label: 'Common_ACCOUNTLOGONNAME',
        sortable: true,
        forManageAccess: false,
        showMasterLock: false,
    },
    {
        name: 'description',
        columnName: 'description',
        label: 'Common_Description',
        isHeader: false,
        sortable: true,
        forManageAccess: false,
        showMasterLock: false,
        credentials: false,
        history: false,
    },
    {
        name: 'resourceSystemFriendlyName',
        columnName: 'resourceSystemFriendlyName',
        label: 'Domain or Directory',
        sortable: true,
        forManageAccess: false,
        showMasterLock: false,
        credentials: false,
        history: false,
        styles: {
            cursor: 'pointer',
            width: '60px',
            minWidth: '60px',
            maxWidth: '80px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'statusButton',
        label: 'Status',
        hideValue: true,
        type: 'credentialStatusBtn',
        manageAccessOnlyAttribute: true,
        showMasterLock: true,
        credentials: true,
        history: false,
        component: {
            name: 'CredentialStatusButton',
        },
        headerCellStyles: {
            fontSize: '13px',
        },
    },
    {
        name: 'checkOutEndTimeUtc',
        columnName: 'checkOutEndTimeUtc',
        sortable: false,
        label: 'RemainingTime',
        type: 'CredentialRemainingTime',
        manageAccessOnlyAttribute: true,
        showMasterLock: false,
        credentials: true,
        history: false,
        component: {
            name: 'CredentialRemainingTime',
        },
    },
    {
        name: 'externalCredentialName',
        columnName: 'friendlyName',
        label: 'Common_FriendlyName',
        isHeader: true,
        sortable: true,
        manageAccessOnlyAttribute: true,
        credentials: false,
        history: true,
    },
    {
        name: 'externalCredentialTypeName',
        columnName: 'externalCredentialTypeName',
        label: 'Common_Type',
        sortable: true,
        manageAccessOnlyAttribute: true,
        credentials: false,
        history: true,
    },

    {
        name: 'computerFriendlyName',
        columnName: 'computerFriendlyName',
        label: 'Common_Computer',
        sortable: true,
        manageAccessOnlyAttribute: true,
        credentials: false,
        history: true,
    },
    {
        name: 'personFriendlyName',
        columnName: 'personFriendlyName',
        label: 'Common_Approver',
        sortable: true,
        manageAccessOnlyAttribute: true,
        credentials: false,
        history: true,
    },
    {
        name: 'startTimeUtc',
        columnName: 'startTimeUtc',
        label: 'Common_StartTime',
        sortable: true,
        component: {
            name: 'GenericDateTimeComponent',
        },
        manageAccessOnlyAttribute: true,
        credentials: false,
        history: true,
    },
    {
        name: 'endTimeUtc',
        columnName: 'endTimeUtc',
        label: 'Common_EndTime',
        sortable: true,
        component: {
            name: 'GenericDateTimeComponent',
        },
        manageAccessOnlyAttribute: true,
        credentials: false,
        history: true,
    },
    {
        name: 'userCheckedOutTimeUtc',
        columnName: 'userCheckedOutTimeUtc',
        label: 'Common_UserCheckedOutTime',
        isHeader: false,
        sortable: true,
        component: {
            name: 'GenericDateTimeComponent',
        },
        manageAccessOnlyAttribute: true,
        credentials: false,
        history: true,
    },
]

const checkoutHistoryCardAttributes = [
    {
        name: 'businessRequestName',
        label: 'Common_BusinessRequest',
        redirectToOtherMS: {
            microservice: 'myTasks',
            path: '/myRequests/businessRequests',
            queryParams: [{ key: 'id', value: 'businessRequestId' }],
        },
    },
    {
        name: 'businessRequestItemName',
        label: 'Common_BusinessRequestItem',
        redirectToOtherMS: {
            microservice: 'myTasks',
            path: '/myRequests/businessRequestItems',
            queryParams: [{ key: 'id', value: 'businessRequestItemId' }],
        },
    },
    {
        name: 'startTimeUtc',
        label: 'Common_StartTime',
        component: {
            componentName: 'DateTimeGenericComponent',
        },
    },
    {
        name: 'endTimeUtc',
        label: 'Common_EndTime',
        component: {
            componentName: 'DateTimeGenericComponent',
        },
    },
    {
        name: 'actualCheckedOutTimeUtc',
        label: 'Common_UserCheckedOutTime',
    },
]

const manageAccessTabs = [
    {
        label: 'ManageCredentials',
        path: 'credentials',
        icon: 'ApplicationsSmall',
        display: true,
        default: true,
        controller: 'allAssignedResources',
        showMasterLock: true,
        contextProp: 'membershipCount', // need to check what this is
        showCount: true,
        requireAccess: {
            control: 'Credentials-ManageCredentials',
        },
    },
    {
        label: 'History',
        path: 'history',
        icon: 'BusinessRolesSmall',
        display: true,
        controller: 'history',
        showMasterLock: false,
        contextProp: 'historyCount',
        showCount: true,
        requireAccess: {
            control: 'Credentials-History',
        },
    },
]

const credentialsConfig = {
    name: 'Credentials',
    resourceTypeName: 'ExternalCredential',
    title: 'Common_Credentials',
    route: '/Credentials',
    requireAccess: {
        page: 'ITShop-Credentials-Page',
    },
    order: 11,
    icon: {
        small: 'CredentialsSmall',
        large: 'CredentialsLarge',
    },
    dataSource: {
        type: 'MS_DS',
        url: '/api/credentials',
    },
    workflows: [],
    filters: filters,
    attributes: attributes,
    checkoutHistoryCardAttributes,
    manageAccessTabs,
}

export default credentialsConfig
