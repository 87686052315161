import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        quaternary: Palette['primary']
        tertiary: Palette['primary']
        disabled: Palette['primary']
    }
    interface PaletteOptions {
        quaternary?: Palette['primary']
        tertiary?: PaletteOptions['primary']
        disabled?: PaletteOptions['primary']
    }

    interface SimplePaletteColorOptions {
        gradient?: string
    }

    interface TypeText {
        highlightSearchTerm?: string
    }
}

export const defaultThemeObject: ThemeOptions = {
    palette: {
        common: {
            black: '#000',
            white: '#fff',
        },
        type: 'light',
        primary: {
            main: '#307fc1',
            light: 'rgba(48, 127, 193, 0.07)',
            dark: '#1f629a',
            gradient: 'linear-gradient(107deg,#682a94,#05afe0)',
        },
        secondary: {
            light: 'rgba(1, 174, 143, 0.07)',
            main: '#01ae8f',
            dark: '#118e77',
            gradient: 'linear-gradient(107deg, #019cae, #307fc1)',
            contrastText: '#fff',
        },
        tertiary: {
            light: 'rgba(69, 63, 187, 0.07)',
            main: '#453fbb',
            dark: '#1e1989',
            gradient: 'linear-gradient(107deg, #01ae8f, #307fc1)',
            contrastText: '#fff',
        },
        quaternary: {
            light: 'rgba(255, 60, 0, 0.07)',
            main: '#ff3c00',
            dark: '#bf2f03',
            contrastText: '#fff',
        },
        error: {
            light: 'rgba(255, 77, 95, 0.07)',
            main: '#ff4d5f',
            dark: '#ba002c',
            contrastText: '#fff',
        },
        warning: {
            light: 'rgba(255, 126, 0, 0.07)',
            main: '#ff7e00',
            dark: '#b86d24',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        info: {
            light: 'rgba(38, 95, 180, 0.07)',
            main: '#265fb4',
            dark: '#0f3d81',
            contrastText: '#fff',
        },
        success: {
            light: 'rgba(62, 170, 5, 0.07)',
            main: '#3eaa05',
            dark: '#3c8416',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        disabled: {
            light: 'rgba(208, 217, 226, 0.25)',
            main: '#d0d9e2',
            dark: '#aeb8c3',
        },
        grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#8b909a',
            A100: '#d5d5d5',
            A200: '#aaaaaa',
            A400: '#303030',
            A700: '#616161',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            highlightSearchTerm: '#d36dbf',
        },
        divider: 'rgba(0, 0, 0, 0.12)',
        background: {
            paper: '#ffffff',
            default: '#eef0f4',
        },
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(0, 0, 0, 0.04)',
            hoverOpacity: 0.04,
            selected: 'rgba(0, 0, 0, 0.08)',
            selectedOpacity: 0.08,
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            disabledOpacity: 0.38,
            focus: 'rgba(0, 0, 0, 0.12)',
            focusOpacity: 0.12,
            activatedOpacity: 0.12,
        },
    },
    typography: {
        fontFamily: [
            'Rubik',
            'sans-serif',
            'Source Sans Pro',
            'Muli',
            'Roboto',
            'Helvetica',
            'Arial',
        ].join(','),
        h2: {
            fontSize: '18px',
        },
        h5: {
            fontSize: '24px',
        },
        h6: {
            fontSize: '18px',
        },
        subtitle1: {
            fontSize: '10px',
            color: '#b4b4b4',
            fontWeight: 500,
        },
        body1: {
            fontSize: '16px',
            fontWeight: 500,
        },
        subtitle2: {
            fontSize: '10px',
            color: '#b4b4b4',
            fontWeight: 500,
        },
        body2: { fontSize: '14px' },
    },
    shape: {
        borderRadius: 4,
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '13px',
                color: '#ffffff',
                backgroundColor: '#307fc1',
            },
        },
    },
    props: {
        MuiTable: {
            style: {
                boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.05)',

                border: 'solid 1px #efeff1',
            },
        },
        MuiPaper: {
            style: {
                boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.05)',
                // border: 'solid 1px #efeff1', // be specific to wherever you want to apply this
            },
        },
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiAppBar: {
            style: {
                boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
            },
        },
        MuiCard: {
            style: {
                // boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.05)',
                boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
                borderRadius: '5px',
                // border: 'solid 1px #ebebed',
                border: 'none',
            },
        },
        MuiInputBase: {
            style: {
                fontSize: '12px',
            },
        },
        MuiFormControl: {
            style: {
                marginTop: '8px',
            },
        },
        MuiInputLabel: {
            style: {
                fontSize: '12px',
            },
        },
        MuiRadio: {
            color: 'primary',
        },
        MuiCheckbox: {
            color: 'primary',
        },
    },
    mixins: {
        toolbar: {
            minHeight: 111,
            '@media (min-width:0px) and (orientation: landscape)': {
                minHeight: 111,
            },
            '@media (min-width:960px)': {
                minHeight: 70,
            },
        },
        // @ts-ignore
        navBar: {
            // Custom mixin
            minHeight: 65,
            '@media (min-width:0px) and (orientation: landscape)': {
                minHeight: 65,
            },
            'media (min-width:600px)': {
                minHeight: 65,
            },
        },
        header: {
            // Custom mixin
            minHeight: 111,
            '@media (min-width:0px) and (orientation: landscape)': {
                minHeight: 111,
            },
            '@media (min-width:960px)': {
                minHeight: 70,
            },
        },
        headerTop: {
            // Custom mixin
            top: 111,
            '@media (min-width:0px) and (orientation: landscape)': {
                top: 111,
            },
            '@media (min-width:960px)': {
                top: 70,
            },
        },
    },
}

export default createMuiTheme(defaultThemeObject)
