import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MobileButtons from 'components/MobileButtons'
import { Dropdown } from 'packages/eid-ui'
import MobileSortingItem from './MobileSortingItem'
import { Backdrop, styled } from '@material-ui/core'
import { useResourceTypeContext } from 'resourceTypeContext'

const StyledBackdrop = styled(Backdrop)(() => ({
    zIndex: '0',
}))

const SortingList = ({ attributes, setOpen }) => {
    const sortableAttributes = attributes.filter((x) => x.sortable)
    const { t } = useTranslation()

    const [{ sorting }, dispatch] = useResourceTypeContext()
    const { sortBy, sortOrder } = sorting
    const handleSort = (heading) => {
        const label = heading.label
        const sortByName = heading.sortBy ? heading.sortBy : heading.name
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'sorting',
                value: {
                    sortBy: sortByName,
                    sortLabel: label,
                    sortOrder:
                        sortBy !== sortByName
                            ? 'desc'
                            : !sortOrder ||
                              sortOrder === '' ||
                              sortOrder === 'asc'
                            ? 'desc'
                            : 'asc',
                },
            },
        })
        setOpen(false)
    }

    return (
        <>
            {sortableAttributes.map((attribute, index) => (
                <MobileSortingItem
                    key={`${attribute.name}_${index}`}
                    displayName={t(attribute.label)}
                    onClick={() => handleSort(attribute)}
                    selected={
                        sortBy === attribute.sortBy || attribute.friendlyName
                    }
                    order={sortOrder}
                ></MobileSortingItem>
            ))}
        </>
    )
}

const SortingDropdown = React.forwardRef(
    ({ open, setOpen, attributes }, ref) => {
        const handleClose = (event) => {
            if (ref.current && ref.current.contains(event.target)) {
                return
            }

            setOpen(false)
        }
        return (
            <Dropdown
                open={open}
                rootStyles={{
                    paddingRight: '0px',
                }}
                width="320px"
                listStyles={{
                    paddingBottom: '0px',
                    '& > ul': {
                        paddingBottom: '7px',
                        paddingTop: '0px',
                    },
                }}
                anchorEl={ref.current}
                anchorOriginPosition={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                anchorTransformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                handleClose={handleClose}
                showCone={false}
                menuHeight="45vh"
            >
                <SortingList
                    setOpen={setOpen}
                    ref={ref}
                    attributes={attributes}
                />
            </Dropdown>
        )
    },
)

const MobileSortingMenu = ({ attributes }) => {
    const [{ sorting }] = useResourceTypeContext()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)
    const displayButton = (
        <>
            <MobileButtons.Sort
                label={t('Common_OrderBy')}
                subLabel={t(
                    sorting.sortLabel
                        ? sorting.sortLabel
                        : 'Common_SortListing',
                )}
                order={sorting.sortOrder}
                onClick={() => {
                    setOpen((prev) => !prev)
                }}
            />
            <span
                ref={anchorRef}
                style={{ marginLeft: '-15px', height: '50px' }}
            />
        </>
    )

    return (
        <>
            <StyledBackdrop
                sx={{ color: '#fff' }}
                open={open}
                onClick={setOpen}
            />
            {displayButton}
            {open && (
                <>
                    <SortingDropdown
                        open={open}
                        setOpen={setOpen}
                        ref={anchorRef}
                        attributes={attributes}
                    />
                </>
            )}
        </>
    )
}

export default MobileSortingMenu
