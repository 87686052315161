import config from 'config'

export const inIframe = () => {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
}

export const isItemAlreadyInCart = (cart, resourceId, locationId) => {
    if (cart && cart.length > 0) {
        const item = cart[0].cartItems.filter(
            (el) =>
                el.requestableResourceId === resourceId &&
                el.locationId === locationId,
        )

        if (item && item.length > 0) {
            return true
        }
    }
    return false
}

export const paginate = (items, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize
    const end = startIndex + pageSize
    items = items.slice(startIndex, end)
    return items
}

export const sanitizeSearchString = (searchString) => {
    return searchString && typeof searchString === 'string'
        ? searchString.trim()
        : ''
}

export const isValidSearchString = (searchString) => {
    return searchString && searchString.length > 0
}

export const getFullImageUrl = (imagePath) =>
    `${config.BASE_EID_URL}${imagePath}`

export { default as genericHashLink } from './genericHashLink'

export const subHeaderHeight = 70
export const headerHeight = 70

export const bindResourceTypeNounAndVerbQueryParams = (resourceType) => {
    switch (resourceType) {
        case 'applications':
            return 'noun=ITShopMS&verb=Application'
        case 'businessRoles':
            return 'noun=ITShopMS&verb=BusinessRole'
        case 'applicationRoles':
            return 'noun=ITShopMS&verb=ApplicationRole'
        case 'azureLicenses':
            return 'noun=ITShopMS&verb=AzureLicense'
        case 'azureRoles':
            return 'noun=ITShopMS&verb=AzureRole'
        case 'managementRoles':
            return 'noun=ITShopMS&verb=ManagementRole'
        case 'mailboxes':
            return 'noun=ITShopMS&verb=Mailbox'
        case 'sharedFolders':
            return 'noun=ITShopMS&verb=SharedFolder'
        case 'computers':
            return 'noun=ITShopMS&verb=Computer'
        case 'Credentials':
        case 'credentials':
            return 'noun=ITShopMS&verb=SharedCredential'

        default:
            return 'noun=ITShopMS&verb=Application'
    }
}

export const getMasterLockFieldsWidth = (headings, tableHeadingRefs) => {
    let allRefWidth = 0
    const masterLockFields = headings.filter((x) => x.showMasterLock)
    if (masterLockFields?.length > 0) {
        masterLockFields.forEach((item) => {
            const itemIndex = headings.findIndex((x) => x.name === item.name)
            if (itemIndex >= 0) {
                allRefWidth = tableHeadingRefs.current[itemIndex]?.current
                    ?.offsetWidth
                    ? allRefWidth +
                      tableHeadingRefs.current[itemIndex].current.offsetWidth
                    : allRefWidth
            }
        })
    }
    return allRefWidth
}

export const getMasterLockLeftSpace = (headings, tableHeadingRefs) => {
    let allRefOnLeftWidth = 0
    let keysBeforeMasterPass = []
    let isMasterLockFound = false
    headings.forEach((element) => {
        if (!element.showMasterLock && !isMasterLockFound) {
            keysBeforeMasterPass.push(element.name)
        } else {
            isMasterLockFound = true
        }
    })
    keysBeforeMasterPass.forEach((item) => {
        const itemIndex = headings.findIndex((x) => x.name === item)
        if (itemIndex >= 0) {
            allRefOnLeftWidth = tableHeadingRefs.current[itemIndex]?.current
                ?.offsetWidth
                ? allRefOnLeftWidth +
                  tableHeadingRefs.current[itemIndex].current.offsetWidth
                : allRefOnLeftWidth
        }
    })
    return allRefOnLeftWidth
}

export const defaultDataProtectedSubcomponent = 'NotProtected'
