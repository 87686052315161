import { FC, Fragment, useEffect, useState } from 'react'
import {
    Box,
    createStyles,
    styled,
    Typography,
    useTheme,
} from '@material-ui/core'
import { Loader, Tooltip } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import {
    useAccessRequestPolicy,
    useAddionalProperties,
    useAddItemToCart,
    useCart,
} from 'hooks'
import { Icon } from 'packages/eid-icons'
import { ItemDetails } from 'components'
import { useTimeConstrainedControl } from 'components/TimeConstrainedControl'
import { IResource, IResourceType } from 'core'
import { makeStyles } from '@material-ui/core/styles'
import DynamicFormDrawerComp from './DynamicForm'

export type AddToCartProps = {
    type?: 'Request' | 'Renew'
    resource: IResource
    resourceType: IResourceType
    preValidate?: any
    preAdd: any
    postAdd: any
    secondary?: any
    isSmallScreen?: boolean
    locationId?: string
    useAccessLevelPolicy?: boolean
    showTimeConstraint?: boolean
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '16px',
            marginTop: '40px',
            [theme.breakpoints.up('sm')]: {
                height: '80px',
                alignItems: 'center',
                position: 'relative',
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
                paddingTop: '16px !important',
                paddingBottom: '16px !important',
            },
        },

        timeControlsContainer: {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                marginLeft: '40px',
                alignItems: 'center',
            },
            [theme.breakpoints.down('sm')]: {
                paddingBottom: '16px',
            },
        },
    }),
)

export const AddToCart: FC<AddToCartProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const { data: cart } = useCart()
    const theme = useTheme()

    const [dynamicFormData, setDynamicFormData] = useState([])
    const [isDynamicFieldFilled, setIsDynamicFieldFilled] = useState(true)

    const isBusinessRole = props.resourceType.name === 'BusinessRoles'

    const [isAdditionalFields, setIsAdditionalFormFields] = useState(false)

    const accessRequestPolicy = useAccessRequestPolicy(
        // NOTE: This is show default time from access level policy rather tha resource
        props.useAccessLevelPolicy &&
            props?.secondary?.resourceAssignment?.requestPolicyId
            ? props?.secondary?.resourceAssignment?.requestPolicyId
            : props.resource.requestPolicyId,
        isBusinessRole ? props.resource.id : '',
        isBusinessRole && props.secondary ? props.secondary.id : '',
    )
    const selectedResource = props.resource
    const isSecondary = props.secondary === null ? false : true
    const { data: additionalFormFields } = useAddionalProperties(
        selectedResource?.id,
        selectedResource?.resourceTypeId,
        props?.secondary?.id,
        isSecondary,
    )
    const getTooltipTitle = () => {
        let message = ''
        if (props.preValidate) {
            message = props.preValidate()
        }
        if (message === '' && itemAlreadyInCart) {
            message = t('Common_ItemAlreadyInCart')
        }
        if (message === '' && hasInvalidDates) {
            message = t('Common_InvalidDates')
        }
        if (!isDynamicFieldFilled && isAdditionalFields) {
            message = t('Common_DynamicFormFieldRequired')
        }
        return message
    }
    const hasTooltipValue = getTooltipTitle() !== ''
    const {
        timeConstrained,
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        violatesPolicy,
        timeConstrainedControl,
    } = useTimeConstrainedControl(
        accessRequestPolicy,
        accessRequestPolicy?.isTimeConstrained ?? false,
        hasTooltipValue,
    )

    useEffect(() => {
        if (additionalFormFields) {
            if (
                Object.keys(additionalFormFields).length > 0 &&
                additionalFormFields.mappingFields &&
                additionalFormFields.mappingFields.length > 0
            ) {
                checkForRequiredFields()
                setIsAdditionalFormFields(true)
            } else {
                setIsAdditionalFormFields(false)
            }
        } else {
            setIsAdditionalFormFields(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalFormFields])

    const checkForRequiredFields = () => {
        try {
            let allFieldFilled = true
            additionalFormFields.mappingFields.map((item: any) => {
                if (item.required) {
                    allFieldFilled = false
                }
                return false
            })
            setIsDynamicFieldFilled(allFieldFilled)
        } catch (err: any) {}
    }

    const checkIfAllRequiredSatiesfied = (data: any) => {
        let allFieldFilled = true
        additionalFormFields.mappingFields.map((item: any) => {
            if (item.required) {
                const dataMaping = data.find(
                    (x: any) => x.name === item.fieldName,
                )
                if (!dataMaping || dataMaping.value === '') {
                    allFieldFilled = false
                }
            }
            return false
        })
        setIsDynamicFieldFilled(allFieldFilled)
    }

    const handleDataChange = (data: any) => {
        checkIfAllRequiredSatiesfied(data)
        setDynamicFormData(data)
    }

    const [addItemToCart, { isLoading }] = useAddItemToCart()

    if (!accessRequestPolicy) return <Fragment />

    const handleAddItemToCart = () => {
        const timeConstraintDetails = {
            timeConstrained,
            startDateUtc: selectedStartDate,
            endDateUtc: selectedEndDate,
        }
        let itemToAdd = props.preAdd(timeConstraintDetails)
        if (isAdditionalFields && dynamicFormData) {
            itemToAdd = {
                ...itemToAdd,
                additionalFields: bindDynamicFieldsData(),
            }
        }
        if (itemToAdd === undefined) return

        addItemToCart(itemToAdd).then(() => props.postAdd())
    }

    const bindDynamicFieldsData = () => {
        try {
            const sortedDataList = dynamicFormData.map((item: any) => {
                if (item.value) {
                    return {
                        additionalField_Name: item.name,
                        additionalField_LocaleKey: item.localeKey,
                        additionalField_Value: item.value,
                    }
                } else {
                    return null
                }
            })

            return sortedDataList.filter((x: any) => x !== null)
        } catch (err) {
            return []
        }
    }

    if (!cart) return <Loader />

    const itemAlreadyInCart = props.resourceType.alreadyInCart(
        cart.cartItems,
        props.resource,
        props.secondary,
    )

    const renderAddToCartButton = () => {
        return (
            <Tooltip title={getTooltipTitle()}>
                <Box minWidth="167px">
                    <ItemDetails.ActionButton
                        fontColor="#ffffff"
                        bgColor={theme.palette.primary.main}
                        width="100%"
                        loading={isLoading}
                        disabled={hasTooltipValue || violatesPolicy}
                        onClick={handleAddItemToCart}
                    >
                        <Box display="flex" alignItems="center">
                            <Icon name="AddToCart" color="#ffffff" />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography style={{ color: '#ffffff' }}>
                                {t(
                                    props.type === 'Request'
                                        ? 'Common_AddToCart'
                                        : 'Common_Renew',
                                )}
                            </Typography>
                        </Box>
                    </ItemDetails.ActionButton>
                </Box>
            </Tooltip>
        )
    }

    if (!accessRequestPolicy.isRequestAllowed) {
        return <Fragment />
    }

    return (
        <>
            {additionalFormFields && (
                <DynamicFormDrawerComp
                    additionalFormFields={additionalFormFields}
                    handleDataChange={(data: any) => handleDataChange(data)}
                />
            )}
            <PaddedDiv className={classes.root}>
                {renderAddToCartButton()}
                <Box className={classes.timeControlsContainer}>
                    {props.showTimeConstraint && timeConstrainedControl}
                </Box>
            </PaddedDiv>
        </>
    )
}

AddToCart.defaultProps = {
    type: 'Request',
    showTimeConstraint: true,
}
