import { makeStyles, Tabs as MuiTabs } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import Tab from './Tab'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles({
    scrollableDiv: {
        overflow: 'scroll !important',
        '&::-webkit-scrollbar': {
            display: 'none !important',
            width: '0px',
            height: '0px',
        },
    },
})

const HorizontalTabs = (props) => {
    const OFFSET = props.offset ? props.offset : 50
    const classes = useStyles()
    const ref = useRef(null)
    const [scrollableDiv, setScrollableDiv] = useState(null)
    const [scrollLeft, setScrollLeft] = useState(0)
    useEffect(() => {
        if (ref?.current) {
            ref.current.firstChild.classList.add(classes.scrollableDiv)
            setScrollableDiv(ref.current.firstChild)
            setScrollLeft(ref.current.firstChild.scrollLeft)
        }
    })
    useEffect(() => {
        if (scrollableDiv) {
            const handleScroll = () => {
                setScrollLeft(scrollableDiv.scrollLeft)
            }
            scrollableDiv.addEventListener('scroll', handleScroll)
            return () => {
                scrollableDiv.removeEventListener('scroll', handleScroll)
            }
        }
    })
    return (
        <>
            {props?.customScroll ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {scrollLeft > 0 && (
                        <Icon
                            direction="left"
                            style={{
                                width: '30px',
                                height: '30px',
                                cursor: 'pointer',
                            }}
                            name="Arrow"
                            onClick={() => {
                                if (scrollLeft <= 0) {
                                    return
                                }
                                const { y } =
                                    scrollableDiv?.getBoundingClientRect()
                                scrollableDiv?.scrollTo(
                                    scrollableDiv.scrollLeft - OFFSET,
                                    y,
                                )
                            }}
                        />
                    )}
                    <MuiTabs
                        style={{
                            scrollBehavior: 'smooth',
                        }}
                        ref={ref}
                        {...props}
                        variant="standard"
                        scrollButtons={false}
                    />

                    {scrollLeft <
                        scrollableDiv?.scrollWidth -
                            scrollableDiv?.offsetWidth && (
                        <Icon
                            direction="right"
                            style={{
                                width: '30px',
                                height: '30px',
                                cursor: 'pointer',
                            }}
                            name="Arrow"
                            onClick={() => {
                                const { y } =
                                    scrollableDiv?.getBoundingClientRect()
                                scrollableDiv?.scrollTo(
                                    scrollableDiv.scrollLeft + OFFSET,
                                    y,
                                )
                            }}
                        />
                    )}
                </div>
            ) : (
                <>
                    <MuiTabs {...props} />
                </>
            )}
        </>
    )
}

HorizontalTabs.Tab = Tab

export default HorizontalTabs
