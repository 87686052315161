import React, { useEffect, useState } from 'react'
import { EidTree } from 'packages/eid-controls'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useGetControlsAccess } from 'packages/core'
import { useTargetPerson } from 'hooks'
import config from 'config'

const TreeFilter = ({
    url,
    initialValue,
    value,
    onChange,
    selectedValueIndicatorProp,
    searchPlaceholder,
    ...rest
}) => {
    const controls = useGetControlsAccess()

    const [targetPerson] = useTargetPerson()
    const [{ showSuggested }] = useResourceTypeContext()
    const [selectedItem, setSelectedItem] = useState(value)

    useEffect(() => {
        if (value !== selectedItem) {
            setSelectedItem(value)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        onChange(selectedItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])

    let basicUrl = `${url}?targetPersonId=${targetPerson.id}`

    if (rest.name === 'ApplicationProcesses') {
        let showSuggestedToPass = Boolean(showSuggested)

        const hasAccessToSuggestedApplicationRolesControl =
            controls.findIndex(
                (c) => c.name === 'ITShop-SuggestedApplicationRoles-Control',
            ) >= 0

        if (
            !hasAccessToSuggestedApplicationRolesControl &&
            config &&
            config.FETCH_SUGGESTED_APPLICATION_ROLES_ALWAYS?.toLowerCase() === 'true'
        ) {
            showSuggestedToPass = true
        }

        basicUrl = basicUrl + `&showSuggested=${showSuggestedToPass}`
        if (rest.rootParentIdentifier) {
            basicUrl = basicUrl + `&parentId=${rest.rootParentIdentifier}`
        }
    }

    return (
        <EidTree
            url={basicUrl}
            initialValue={initialValue}
            onChange={(item) => setSelectedItem(item)}
            value={value}
            searchPlaceholder={searchPlaceholder}
            {...rest}
        />
    )
}

export default TreeFilter
