import React, { useEffect, useState } from 'react'
import { Chip, makeStyles } from '@material-ui/core'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'
import DefaultPaper from './Paper'
import DefaultListbox from './Listbox'
import DefaultLabel from './Label'
import DefaultOption from './Option'
import DefaultTag from './Tag'
import DefaultInputWrapper from './InputWrapper'
import DefaultEndAdornmentContainer from './EndAdornmentContainer'
import Spinner from '../Spinner'
import Checkbox from '../Checkbox'
import { isNilOrEmpty } from 'packages/core'

const useStyles = makeStyles(() => ({
    root: ({ rootStyleProps }) => ({
        width: '100%',
        ...rootStyleProps,
    }),
    focused: {
        '& > div': {
            borderColor: '#453fbb',
        },
    },
    option: {
        width: 'max-content',
        minWidth: '100%',
    },
    popper: ({ popperStyleProps }) => ({
        ...popperStyleProps,
    }),
}))

const fullWidthStyles = { width: '100%' }

function Autocomplete(props) {
    const {
        label,
        loading,
        options,
        getOptionLabel,
        disabled,
        placeholder,
        labelComponent: Label,
        optionComponent: Option,
        tagComponent: Tag,
        inputWrapperComponent: InputWrapper,
        endAdornmentContainerComponent: EndAdornmentContainer,
        endAdornmentComponent: EndAdornment,
        clearIcon,
        value,
        rootStyleProps,
        multiselect,
        chipsConfig,
        inputWrapperStyleProps,
        popperStyleProps,
        ...rest
    } = props
    const classes = useStyles({ rootStyleProps, popperStyleProps })
    const [localValue, setLocalValue] = useState([])
    useEffect(() => {
        if (multiselect && isNilOrEmpty(value)) {
            setLocalValue([])
        } else {
            setLocalValue(value)
        }
    }, [value])
    return (
        <MuiAutocomplete
            classes={classes}
            multiple={multiselect}
            loadingText=""
            noOptionsText=""
            disablePortal={false}
            loading={loading}
            options={options}
            getOptionLabel={getOptionLabel}
            disabled={disabled}
            disableCloseOnSelect={multiselect}
            filterOptions={(options) => options}
            value={localValue}
            renderOption={(option, optionState) => {
                return multiselect ? (
                    <li>
                        <Checkbox checked={optionState.selected} />
                        <div style={{ marginTop: 5 }}>
                            {option.friendlyName}
                        </div>
                    </li>
                ) : (
                    <Option
                        option={option}
                        optionState={optionState}
                        getOptionLabel={getOptionLabel}
                    />
                )
            }}
            getOptionSelected={(option, value) =>
                getOptionLabel(option) === getOptionLabel(value)
            }
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return chipsConfig && chipsConfig.type === 'round' ? (
                        <Chip
                            label={getOptionLabel(option)}
                            size={chipsConfig.size || 'small'}
                            style={{ fontSize: '12px' }}
                            {...getTagProps({ index })}
                        />
                    ) : (
                        <Tag
                            label={getOptionLabel(option)}
                            {...getTagProps({ index })}
                        />
                    )
                })
            }
            renderInput={(params) => {
                return (
                    <>
                        {label && (
                            <Label {...params.InputLabelProps}>{label}</Label>
                        )}
                        <InputWrapper
                            ref={params.InputProps.ref}
                            style={{
                                paddingRight:
                                    (EndAdornment || clearIcon) && '55px',
                            }}
                        >
                            {params.InputProps.startAdornment}
                            <input
                                {...params.inputProps}
                                placeholder={placeholder}
                                style={{
                                    ...fullWidthStyles,
                                    ...inputWrapperStyleProps,
                                }}
                            />
                            {loading && (
                                <EndAdornmentContainer>
                                    <Spinner size={0.7} color="#453fbb" />
                                </EndAdornmentContainer>
                            )}
                            {!loading && EndAdornment && (
                                <EndAdornmentContainer>
                                    <EndAdornment />
                                </EndAdornmentContainer>
                            )}
                            {!loading && clearIcon && (
                                <EndAdornmentContainer>
                                    {clearIcon}
                                </EndAdornmentContainer>
                            )}
                        </InputWrapper>
                    </>
                )
            }}
            {...rest}
        />
    )
}

Autocomplete.defaultProps = {
    PaperComponent: DefaultPaper,
    ListboxComponent: DefaultListbox,
    labelComponent: DefaultLabel,
    optionComponent: DefaultOption,
    tagComponent: DefaultTag,
    inputWrapperComponent: DefaultInputWrapper,
    endAdornmentContainerComponent: DefaultEndAdornmentContainer,
}

Autocomplete.PaperComponent = DefaultPaper
Autocomplete.ListboxComponent = DefaultListbox
Autocomplete.labelComponent = DefaultLabel
Autocomplete.optionComponent = DefaultOption
Autocomplete.tagComponent = DefaultTag
Autocomplete.inputWrapperComponent = DefaultInputWrapper
Autocomplete.endAdornmentContainerComponent = DefaultEndAdornmentContainer

export default Autocomplete
