import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { TreeView, Skeleton } from '@material-ui/lab'
import { ReactComponent as PlusSquareIconSvg } from './Resources/collapse.svg'
import { ReactComponent as MinusquareIconSvg } from './Resources/constrain.svg'
import MainTreeItem from './MainTreeItem'
import SubTreeItem from './SubTreeItem'
import { isNilOrEmpty } from '../../core'
import config from 'config'
import Scrollbars from "react-custom-scrollbars";

const getFullImageUrl = (imagePath) => `${config.BASE_EID_URL}${imagePath}`

const useStyles = makeStyles({
    root: {
        height: '100%',
        flexGrow: 1,
    },
})

const extractAllNodeIdsFromTree = (tree) => {
    let ids = []

    const traverse = (node) => {
        ids.push(node.id)
        if (node.children && node.children.length > 0) {
            node.children.forEach((child) => traverse(child))
        }
    }

    tree.forEach((rootNode) => traverse(rootNode))

    return ids
}

export default function Tree({
    data,
    handleSelect,
    loading,
    selectedItem,
    searchTerm,
    idProp = 'id',
    labelProp = 'friendlyName',
    ...rest
}) {
    const [expanded, setExpanded] = useState([])
    const [treeItems, setTreeItems] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    useEffect(() => {
        if (!isNilOrEmpty(searchTerm)) {
            setExpanded(extractAllNodeIdsFromTree(data))
        }
    }, [data])

    const onNodeToggle = (event, nodeIds) => {
        setExpanded(nodeIds)
    }

    useEffect(() => {
        if (data?.length) {
            let tempList = [...data]
            // NOTE: First chunk to load first 50 records
            if (tempList.length > 50) {
                tempList.length = 50
                setHasMore(true)
            }
            setTreeItems(tempList)
            setPageNo(1)
        }

    }, [data])

    const classes = useStyles()

    const renderRootNodes = (node) => {
        const isParentSelected =
            selectedItem && selectedItem[idProp] === node[idProp]
        return (
            <MainTreeItem
                key={node[idProp]}
                nodeId={node[idProp]}
                label={node[labelProp]}
                count={
                    node.children.length > 0 ? node.children.length : undefined
                }
                highlightText={searchTerm}
                selected={selectedItem && selectedItem[idProp] === node[idProp]}
                onClick={(event) => {
                    event.preventDefault()
                    handleSelect(node)
                }}
                iconUrl={
                    node.iconUrl && node.iconUrl.length > 0
                        ? getFullImageUrl(node.iconUrl)
                        : null
                }
                {...rest}
            >
                {Array.isArray(node.children) &&
                    node.children.map((currentNode) =>
                        renderSubNodes(currentNode, isParentSelected),
                    )}
            </MainTreeItem>
        )
    }

    const renderSubNodes = (node, isParentSelected) => {
        const _isParentSelected = isParentSelected
            ? isParentSelected
            : selectedItem && selectedItem[idProp] === node[idProp]
        return (
            <SubTreeItem
                key={node[idProp]}
                nodeId={node[idProp]}
                label={node[labelProp]}
                count={
                    node.children.length > 0 ? node.children.length : undefined
                }
                highlightText={searchTerm}
                selected={selectedItem && selectedItem[idProp] === node[idProp]}
                onClick={(event) => {
                    event && event.preventDefault()
                    handleSelect(node)
                }}
                iconUrl={
                    node.iconUrl && node.iconUrl.length > 0
                        ? getFullImageUrl(node.iconUrl)
                        : null
                }
                isParentSelected={isParentSelected}
                {...rest}
            >
                {Array.isArray(node.children) &&
                    node.children.map((currentNode) =>
                        renderSubNodes(currentNode, _isParentSelected),
                    )}
            </SubTreeItem>
        )
    }

    const bindMoreData = (response) => {
        if (response.top >= 0.99 && hasMore) {
            let tempList = [...data]
            const startIndex = pageNo * 50
            const endIndex = (pageNo + 1) * 50
            const refinedList = tempList.slice(startIndex, endIndex)
            const combinedList = [...treeItems, ...refinedList]
            setTreeItems(combinedList)
            setPageNo(pageNo + 1)
            if (endIndex >= data.length) {
                setHasMore(false)
            }
        }
    }

    return loading ? (
        <>
            <Box margin="4px 8px">
                <Skeleton animation="wave" variant="rect" height={20} />
            </Box>
            <Box margin="4px 8px">
                <Skeleton animation="wave" variant="rect" height={20} />
            </Box>
        </>
    ) : (
        data && (
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<MinusquareIconSvg />}
                defaultExpandIcon={<PlusSquareIconSvg />}
                expanded={expanded}
                onNodeToggle={onNodeToggle}
                disableSelection
            >
                <div
                    style={{
                        maxHeight: 300,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}
                >
                    <Scrollbars
                        className="scrollPriceChange"
                        onScrollFrame={bindMoreData}
                        autoHide
                        style={{ height: "80vh" }}
                    >

                        {treeItems.map((item) => (
                            <React.Fragment key={item[idProp]}>
                                {renderRootNodes(item)}
                            </React.Fragment>
                        ))}

                    </Scrollbars>
                </div>
            </TreeView>
        )
    )
}

Tree.propTypes = {
    data: PropTypes.array,
    handleSelect: PropTypes.func.isRequired,
}

Tree.defaultProps = {}
