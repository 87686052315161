import { Box, makeStyles, createStyles } from '@material-ui/core'
import { ReactComponent as ArrowIcon } from './arrow.svg'
import { ReactComponent as VerticalArrowIcon } from './vertical-arrow.svg'
import { useTranslation } from 'react-i18next'
import { ICartItemRisk } from '../types'

const smallScreenWidth = 959

const useStyles = makeStyles(
    createStyles({
        root: {
            position: 'relative',
            paddingTop: '4px',
            display: 'flex',
            alignItems: 'flex-start',

            [`@media (max-width:${smallScreenWidth}px)`]: {
                paddingLeft: '16px',
                flexDirection: 'column',
                marginTop: '8px',
            },
        },

        reasonArrowVertical: {
            position: 'absolute',
            left: 0,
            top: 15,

            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
        },

        reasonArrow: {
            padding: '0px 8px',
            [`@media (max-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
        },

        reasonLabel: {
            minWidth: '90px',
            color: '#5d6870',
            fontSize: '12px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            textAlign: 'right',
            paddingTop: '4px',

            [`@media (max-width:${smallScreenWidth}px)`]: {
                textAlign: 'left',
                minWidth: '50px',
            },
        },
        reasonValueContainer: {
            display: 'flex',
        },
        reasonTagContainer: {
            minWidth: '140px',
            paddingLeft: '16px',
        },
        reasonTag: {
            backgroundColor: '#ffffff',
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '2px 8px',
            textTransform: 'uppercase',
            color: '#3b454d',
            border: '1px solid #aab0b4',
            borderRadius: '5px',
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
        },

        reasonValue: {
            display: 'flex',
        },
        reasonLabelAndTagBox: {
            display: 'flex',
            [`@media (max-width:${smallScreenWidth}px)`]: {
                marginBottom: '8px',
            },
        },
        textWrap: {
            wordBreak: 'break-word',
            wordWrap: 'break-word',
            overflow: 'auto',
        },
    }),
)

const RiskReason = ({ risk }: { risk: ICartItemRisk }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <Box className={classes.reasonArrowVertical}>
                <VerticalArrowIcon />
            </Box>

            <Box className={classes.reasonLabelAndTagBox}>
                <Box className={classes.reasonLabel}>{t('Common_Source')}</Box>
                <Box className={classes.reasonTagContainer}>
                    <Box className={classes.reasonTag}>
                        {t(`Common_${risk.riskReason}`)}
                    </Box>
                </Box>
            </Box>

            <Box className={classes.reasonValueContainer}>
                <Box className={classes.reasonArrow}>
                    <ArrowIcon />
                </Box>
                <Box className={classes.reasonValue}>
                    <Box
                        component={'span'}
                        marginLeft={'8px'}
                        fontSize={'14px'}
                        className={classes.textWrap}
                    >
                        {risk.riskReason_ResourceFriendlyName}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default RiskReason
