import React from 'react'
import { styled } from '@material-ui/core'
import SomeoneElseSrc from './someone-else.png'
import PeopleSearch from 'components/PeopleSearch'
import { useTranslation } from 'react-i18next'
import config from 'config'

const ShoppingForSomeoneElseDiv = styled('div')({
    position: 'relative',
    width: '260px',
    '& > img': {
        width: '146px',
        marginLeft: '93px',
    },
    '& > div': {
        position: 'absolute',
        top: '155px',
        width: '100%',
    },
})

const ShoppingForSomeoneElse = ({ onSelect }) => {
    const { t } = useTranslation()
    return (
        <ShoppingForSomeoneElseDiv>
            <img
                src={`${config.APP_SUBPATH}${SomeoneElseSrc}`}
                alt={t('OnBoarding_ShopForSomeoneElse')}
            />
            <div>
                <PeopleSearch
                    onChange={(_, value) => {
                        if (value) {
                            onSelect({
                                id: value.id,
                                friendlyName: value.friendlyName,
                            })
                        }
                    }}
                />
            </div>
        </ShoppingForSomeoneElseDiv>
    )
}

export default ShoppingForSomeoneElse
