import React, { Fragment, useEffect, useState } from 'react'
import { Box, Radio, Typography } from '@material-ui/core'
import { styled, useTheme } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { Tooltip } from 'packages/eid-ui'
import { isNilOrEmpty } from 'packages/core'
import TreeV3ChildNode from './TreeV3ChildNode'
import config from 'config'

const getFullImageUrl = (imagePath) =>
    !isNilOrEmpty(imagePath) ? `${config.BASE_EID_URL}${imagePath}` : undefined

const TreeItem = styled(Box)(({ treeItemStyles }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    marginTop: '0.6rem',
    marginBottom: '0.6rem',
    padding: '0.5rem 0.9rem 0.5rem 0.8rem',
    '&:hover': {
        backgroundColor: '#f1f1f4 !important',
    },
    ...treeItemStyles,
}))

const StyledImage = styled('img')(({ imageStyles }) => ({
    width: '1.5rem',
    marginLeft: '0.5rem',
    ...imageStyles,
}))

const StyledRadio = styled(Radio)(({ radioButtonStyles }) => ({
    padding: '0',
    marginLeft: '0.8rem',
    maxHeight: '2rem',
    '&:hover': {
        backgroundColor: '#d2d2d9',
    },
    ...radioButtonStyles,
}))

const StyledTypography = styled(Typography)(
    ({ theme, isSelected, isDisabled, fontStyles }) => ({
        fontSize: '1.4rem',
        marginLeft: '0.8rem',
        color: '#767676',
        wordBreak: 'break-all',
        cursor: 'pointer',
        '& .search-highlight': {
            color: theme?.palette?.text?.highlightSearchTerm,
            fontWeight: 600,
            textDecoration: 'underline',
            fontSize: '1.4rem',
        },
        ...(isSelected && {
            background: '#3d3d44',
            padding: '0 0.3rem',
            borderRadius: '0.4rem',
            color: '#fff !important',
        }),
        ...(isDisabled && {
            color: '#a3a3aa',
            cursor: 'default',
        }),
        ...fontStyles,
    }),
)

const highlight = (text, searchKey) => {
    searchKey = new RegExp('(' + searchKey + ')', 'gi')
    return text.split(searchKey).map((o, i) =>
        i % 2 === 1 ? (
            <span className="search-highlight" key={i}>
                {o}
            </span>
        ) : (
            o
        ),
    )
}

const DefaultExpandIcon = (
    <Icon
        name="ArrowSmall"
        color="#60708f"
        fill="#60708f"
        direction={'right'}
    />
)

const DefaultCollapseIcon = (
    <Icon name="ArrowSmall" color="#60708f" fill="#60708f" direction={'down'} />
)

const TreeV3 = React.memo(
    ({
        data,
        label = 'title',
        handleSelect,
        selectedItemId,
        isExpanded = false,
        highlightText = '',
        disableAssignableCheck = false,
        expandIcon = DefaultExpandIcon,
        collapseIcon = DefaultCollapseIcon,
        radioSelection = false,
        loading,
        fontStyles = {},
        treeItemStyles = {},
        imageStyles = {},
        radioButtonStyles = {},
    }) => {
        const { t } = useTranslation()
        const [isOpen, setIsOpen] = useState(isExpanded)
        const theme = useTheme()

        useEffect(() => {
            if (isExpanded) {
                setIsOpen(true)
            }
        }, [isExpanded])

        if (loading) {
            return (
                <>
                    <Box margin="0.4rem 0.8rem">
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={20}
                        />
                    </Box>
                    <Box margin="0.4rem 0.8rem">
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={20}
                        />
                    </Box>
                </>
            )
        }

        return (
            <Fragment>
                {data?.children?.length ? (
                    <Box marginTop={'1rem'}>
                        <TreeItem treeItemStyles={treeItemStyles}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <Box
                                    onClick={() => setIsOpen(!isOpen)}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        width: '1.5rem',
                                    }}
                                >
                                    {isOpen ? collapseIcon : expandIcon}
                                </Box>
                                <Box
                                    onClick={() => {
                                        if (
                                            data?.isAssignable !== false ||
                                            disableAssignableCheck
                                        ) {
                                            handleSelect(data)
                                        }
                                    }}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {radioSelection && (
                                        <StyledRadio
                                            checkedIcon={
                                                <Icon
                                                    name="RadioFilled"
                                                    color={
                                                        theme?.palette?.primary
                                                            ?.main
                                                    }
                                                />
                                            }
                                            icon={<Icon name="Radio" />}
                                            checked={
                                                selectedItemId === data.id ??
                                                false
                                            }
                                            onClick={() => {
                                                if (
                                                    data?.isAssignable !==
                                                        false ||
                                                    disableAssignableCheck
                                                ) {
                                                    handleSelect(data)
                                                }
                                            }}
                                            radioButtonStyles={
                                                radioButtonStyles
                                            }
                                        />
                                    )}

                                    {data?.imageUrl && (
                                        <StyledImage
                                            alt="folder-icons"
                                            src={getFullImageUrl(
                                                data?.imageUrl,
                                            )}
                                            imageStyles={imageStyles}
                                        />
                                    )}

                                    {data.icon && (
                                        <Icon
                                            name={data.icon}
                                            width="1.6rem"
                                            height="1.6rem"
                                            color={
                                                theme?.palette?.primary?.main
                                            }
                                        />
                                    )}

                                    <Tooltip
                                        title={
                                            data?.alreadyAssigned
                                                ? t('AlreadyAssigned')
                                                : data?.isAssignable === false
                                                ? t('NotAvailableForAssignment')
                                                : ''
                                        }
                                    >
                                        <StyledTypography
                                            isSelected={
                                                data?.id &&
                                                selectedItemId === data?.id
                                            }
                                            isDisabled={
                                                data?.isAssignable === false &&
                                                !disableAssignableCheck
                                            }
                                            fontStyles={fontStyles}
                                            onClick={() => {
                                                if (
                                                    data?.isAssignable !==
                                                        false ||
                                                    disableAssignableCheck
                                                ) {
                                                    handleSelect(data)
                                                }
                                            }}
                                        >
                                            {highlightText &&
                                            highlightText.length
                                                ? highlight(
                                                      data[label],
                                                      highlightText,
                                                  )
                                                : data[label]}
                                            {
                                                <span>{` (${data?.children?.length})`}</span>
                                            }
                                        </StyledTypography>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </TreeItem>
                        {isOpen && (
                            <Fragment>
                                {data?.children?.map((item, index) => {
                                    return (
                                        <div
                                            style={{
                                                paddingLeft: 15,
                                            }}
                                            key={`${item?.id}-${index}`}
                                        >
                                            <TreeV3ChildNode
                                                item={item}
                                                handleSelect={handleSelect}
                                                selectedItemId={selectedItemId}
                                                disableAssignableCheck={
                                                    disableAssignableCheck
                                                }
                                                isExpanded={isExpanded}
                                                highlightText={highlightText}
                                                label={label}
                                                expandIcon={expandIcon}
                                                collapseIcon={collapseIcon}
                                                radioSelection={radioSelection}
                                                loading={loading}
                                                fontStyles={fontStyles}
                                                treeItemStyles={treeItemStyles}
                                                imageStyles={imageStyles}
                                                radioButtonStyles={
                                                    radioButtonStyles
                                                }
                                            />
                                        </div>
                                    )
                                })}
                            </Fragment>
                        )}
                    </Box>
                ) : data?.id ? (
                    <TreeItem
                        style={{ marginLeft: '1.5rem' }}
                        treeItemStyles={treeItemStyles}
                    >
                        {radioSelection && (
                            <StyledRadio
                                checkedIcon={
                                    <Icon
                                        name="RadioFilled"
                                        color={theme?.palette?.primary?.main}
                                    />
                                }
                                icon={<Icon name="Radio" />}
                                checked={selectedItemId === data.id ?? false}
                                onClick={() => {
                                    if (
                                        data?.isAssignable !== false ||
                                        disableAssignableCheck
                                    ) {
                                        handleSelect(data)
                                    }
                                }}
                                radioButtonStyles={radioButtonStyles}
                            />
                        )}

                        {data?.imageUrl && (
                            <StyledImage
                                alt="folder-icons"
                                src={getFullImageUrl(data?.imageUrl)}
                                imageStyles={imageStyles}
                            />
                        )}
                        <Tooltip
                            title={
                                data?.alreadyAssigned
                                    ? t('AlreadyAssigned')
                                    : data?.isAssignable === false
                                    ? t('NotAvailableForAssignment')
                                    : ''
                            }
                        >
                            <StyledTypography
                                isSelected={
                                    data?.id && selectedItemId === data?.id
                                }
                                isDisabled={
                                    data?.isAssignable === false &&
                                    !disableAssignableCheck
                                }
                                fontStyles={fontStyles}
                                onClick={() => {
                                    if (
                                        data?.isAssignable !== false ||
                                        disableAssignableCheck
                                    ) {
                                        handleSelect(data)
                                    }
                                }}
                            >
                                {highlightText && highlightText.length
                                    ? highlight(data[label], highlightText)
                                    : data[label]}
                            </StyledTypography>
                        </Tooltip>
                    </TreeItem>
                ) : (
                    <Typography>{t('NoDataFound')}</Typography>
                )}
            </Fragment>
        )
    },
)

export default TreeV3
