import React, { useState, useEffect } from 'react'
import { ToggleSwitch, Avatar, WithCloseButton } from 'packages/eid-ui'
import { Box, Collapse, styled, Typography } from '@material-ui/core'
import PeopleSearch from 'components/PeopleSearch'
import { Card, ExpansionPanel, Tooltip } from 'packages/eid-ui'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useTargetPerson, useCurrentPerson } from 'hooks'
import { defaultDataProtectedSubcomponent, getFullImageUrl } from 'utils'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useQuery } from 'packages/core'
import { useAppState } from 'appContext'

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '800px', // some arbitrary width
}

const ShoppingForSomeoneElseIndicator = styled(WithCloseButton)({
    borderRadius: '5px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
})

const ShoppingFor = (props) => {
    const {
        title,
        resourceType,
        screen,
        tab,
        protectedsubcomponent = defaultDataProtectedSubcomponent,
    } = props
    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()
    const [targetPerson, setTargetPerson] = useTargetPerson()
    const [{ referencePerson }, dispatch] = useResourceTypeContext()
    // eslint-disable-next-line
    const [_, dispatchAppState] = useAppState()

    const disabled =
        (resourceType === 'Credentials' && screen === 'ManageAccess') ||
        (resourceType === 'Computers' &&
            screen === 'ManageAccess' &&
            (tab?.path === 'loginsession' || tab?.path === 'history'))

    const isForMe = currentPerson.id === targetPerson.id
    const [switchOn, setSwitchOn] = useState(isForMe)

    const changeTargetPerson = (payload) => {
        setTargetPerson(payload)
        setSwitchOn(true)
    }

    useEffect(() => {
        if (disabled && targetPerson.id !== currentPerson.id) {
            changeTargetPerson(currentPerson)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetPerson.id, disabled])

    useEffect(() => {
        if (currentPerson.id === targetPerson.id) {
            setSwitchOn(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetPerson.id])

    useEffect(() => {
        dispatchAppState({
            type: 'SET_DISABLE_SWITCH_TARGET_PERSON',
            payload: disabled ? true : false,
        })
        return () => {
            dispatchAppState({
                type: 'SET_DISABLE_SWITCH_TARGET_PERSON',
                payload: false,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled])

    const query = useQuery()
    const applicationId = query.get('applicationId')

    const content = () => {
        if (isForMe) {
            return (
                <>
                    <Box
                        data-protectedsubcomponent={protectedsubcomponent}
                        padding="0px 20px 0px 20px"
                    >
                        {!disabled ? (
                            <ToggleSwitch
                                onChange={() => {
                                    setSwitchOn((prev) => !prev)
                                }}
                                value={switchOn}
                                label={t('Common_Myself')}
                            />
                        ) : (
                            <Box>{t('Common_Myself')}</Box>
                        )}
                    </Box>
                    <Collapse in={!switchOn}>
                        <Box padding="20px">
                            <PeopleSearch
                                label={t('Common_SomeoneElse')}
                                onChange={(_, value) => {
                                    if (value) {
                                        changeTargetPerson(value)
                                    }
                                }}
                                excludeOptions={
                                    referencePerson ? [referencePerson.id] : []
                                }
                                url="/api/People/shoppingFor"
                            />
                        </Box>
                    </Collapse>
                </>
            )
        } else {
            return (
                <Box
                    data-protectedsubcomponent={protectedsubcomponent}
                    padding="0px 20px 0px 20px"
                >
                    <ShoppingForSomeoneElseIndicator
                        onClose={() => {
                            changeTargetPerson(currentPerson)
                        }}
                    >
                        <Avatar
                            src={getFullImageUrl(targetPerson.imageThumbUrl)}
                        />
                        <Tooltip
                            color="#5D6870"
                            fontColor="#ffffff"
                            enterDelay={500}
                            enterNextDelay={500}
                            tooltipStyles={tooltipStyles}
                            title={targetPerson.friendlyName}
                        >
                            <Typography
                                style={{
                                    marginLeft: '21px',
                                    wordBreak: 'break-all',
                                }}
                                display="block"
                                variant="body1"
                                noWrap={true}
                            >
                                {targetPerson.friendlyName}
                            </Typography>
                        </Tooltip>
                    </ShoppingForSomeoneElseIndicator>
                </Box>
            )
        }
    }

    const isSmallScreen = useIsSmallScreen()

    return isSmallScreen ? (
        <ExpansionPanel
            expanded={true}
            title={!title ? t('Common_TargetPerson') : t(title)}
            disabled={applicationId}
        >
            <Box padding="20px 0px">{content()}</Box>
        </ExpansionPanel>
    ) : (
        <Card
            cardTitle={!title ? t('Common_TargetPerson') : t(title)}
            color="#fff"
            collapsible
            expanded={true}
            disabled={applicationId}
        >
            {content()}
        </Card>
    )
}

export default ShoppingFor
