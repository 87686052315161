import React, { useState } from 'react'
import {
    ApplicationsList,
    FunctionsList,
    ItemDetails,
    PeopleList,
    StyledSearchInput,
    SuggestedRoles,
    TableGrid,
    TCodesList,
} from 'components'
import { isNilOrEmpty, useDebounce, useIsSmallScreen } from 'packages/core'
import { Box, styled } from '@material-ui/core'
import { CheckedIcon } from 'packages/eid-icons'
import {
    useManagementRoleApplicationRolesGranted,
    useManagementRoleManagementRolesGranted,
    useManagementRoleApplications,
    useManagementRoleBr,
    useManagementRoleLocalFunctions,
    useManagementRoleOwners,
    useManagementRoleSuggestedApplicationRoles,
    useTargetPerson,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'packages/eid-ui'
import useSubcomponents from 'useSubcomponents'
import cartHelpers from 'containers/Cart/cartHelpers'
import { CommonAttributes } from '../CommonAttributes'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { RenewRevoke } from 'components/RenewRevoke'
import { Skeleton } from '@material-ui/lab'
import ActivateNowButton from '../../ListingPage/ActivateNowButton'
import useConfiguration from 'useConfiguration'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const OverviewSection = ({ item }) => {
    const managementRole = item.resource
    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()

    const { data: businessRequestItem, isLoading } = useManagementRoleBr(
        targetPerson.id,
        item.assignment.resourceAssignment.resourceId,
        item.assignment.resourceAssignment.resourceTypeId,
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={managementRole.name}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('Common_Type')}
                    value={managementRole.typeFriendlyName}
                    orientation={attributeOrientation}
                />
                {managementRole.owners && (
                    <ItemDetails.Attribute
                        label={t('ManagementRoles_Owners')}
                        value={<PeopleList list={managementRole.owners} />}
                        orientation={attributeOrientation}
                    />
                )}
                <ItemDetails.Attribute
                    label={t('Common_IsHighSecurity')}
                    value={
                        isNilOrEmpty(managementRole.isHighSecurity)
                            ? '-'
                            : managementRole.isHighSecurity
                            ? t('Common_Yes')
                            : t('Common_No')
                    }
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={managementRole.localizedDescription}
                    orientation={attributeOrientation}
                />
                {isLoading && <Skeleton height={32} />}
                <CommonAttributes
                    item={item}
                    businessRequestItem={businessRequestItem}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const { t } = useTranslation()

    const managementRole = item.resource

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useManagementRoleOwners(
        managementRole.id,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                owners && (
                    <ItemDetails.AttributesContainer bottomPadding="0">
                        <PaddedDiv>
                            <ItemDetails.Attribute
                                label={t('ManagementRoles_Owners')}
                                value={<PeopleList list={owners} />}
                                orientation={attributeOrientation}
                            />
                        </PaddedDiv>
                    </ItemDetails.AttributesContainer>
                )
            )}
        </>
    )
}

const ApplicationRolesGranted = ({ item }) => {
    const take = 10
    const isSmallScreen = useIsSmallScreen()

    const managementRole = item.resource

    const { t } = useTranslation()
    const [page, setPage] = React.useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const handlePageSelection = (value) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value) => {
        setPage(1)
        setPerPageItems(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useManagementRoleApplicationRolesGranted(
        managementRole.id,
        (page - 1) * perPageItems,
        perPageItems,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )
    const data = latestData ? latestData.data : undefined

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    const attributes = [
        {
            label: '',
            resolve: () => <CheckedIcon />,
        },
        {
            label: t('Common_FriendlyName'),
            resolve: (item) => <> {item.friendlyName}</>,
        },
        {
            name: 'resourceSystemFriendlyName',
            label: t('Common_ResourceSystem'),
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <StyledSearchInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>
            <TableGrid
                loading={isLoading}
                data={data}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
            />

            {!isLoading && numberOfPages > 1 && (
                <Box
                    padding={!isSmallScreen ? '20px 32px' : '16px'}
                    display="flex"
                    alignItems="center"
                    justifyContent={!isSmallScreen ? 'flex-start' : 'center'}
                >
                    <Pagination
                        count={numberOfPages}
                        size={!isSmallScreen ? 'large' : 'small'}
                        page={page}
                        onChange={handlePageChange}
                        totalCount={latestData?.totalCount}
                        onPageSelection={handlePageSelection}
                        itemsPerPage={perPageItems}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        showPageSelection={true}
                        showItemsPerPageSelection
                        showCountStat
                    />
                </Box>
            )}
        </>
    )
}

const ManagementRolesGranted = ({ item }) => {
    const take = 10
    const isSmallScreen = useIsSmallScreen()

    const managementRole = item.resource

    const { t } = useTranslation()
    const [page, setPage] = React.useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const handlePageSelection = (value) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value) => {
        setPage(1)
        setPerPageItems(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useManagementRoleManagementRolesGranted(
        managementRole.id,
        (page - 1) * perPageItems,
        perPageItems,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )
    const data = latestData ? latestData.data : undefined

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    const attributes = [
        {
            label: '',
            resolve: () => <CheckedIcon />,
        },
        {
            label: t('Common_FriendlyName'),
            resolve: (item) => <> {item.friendlyName}</>,
        },
        {
            name: 'resourceSystemFriendlyName',
            label: t('Common_ResourceSystem'),
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <StyledSearchInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>
            <TableGrid
                loading={isLoading}
                data={data}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
            />

            {!isLoading && numberOfPages > 1 && (
                <Box
                    padding={!isSmallScreen ? '20px 32px' : '16px'}
                    display="flex"
                    alignItems="center"
                    justifyContent={!isSmallScreen ? 'flex-start' : 'center'}
                >
                    <Pagination
                        count={numberOfPages}
                        size={!isSmallScreen ? 'large' : 'small'}
                        page={page}
                        onChange={handlePageChange}
                        totalCount={latestData?.totalCount}
                        onPageSelection={handlePageSelection}
                        itemsPerPage={perPageItems}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        showPageSelection={true}
                        showItemsPerPageSelection
                        showCountStat
                    />
                </Box>
            )}
        </>
    )
}

const LocalSensitiveFunctions = ({ item }) => {
    const take = 10
    const { t } = useTranslation()

    const managementRole = item.resource

    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = React.useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const handlePageSelection = (value) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value) => {
        setPage(1)
        setPerPageItems(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useManagementRoleLocalFunctions(
        managementRole.id,
        (page - 1) * perPageItems,
        perPageItems,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const localFunctions = latestData ? latestData.data : undefined

    const globalFunctionsLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    return (
        <>
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <StyledSearchInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>
            <Box overflow="auto">
                <FunctionsList
                    data={localFunctions}
                    loading={globalFunctionsLoading}
                />
                {!globalFunctionsLoading && numberOfPages > 1 && (
                    <Box
                        padding={!isSmallScreen ? '20px 32px' : '16px'}
                        display="flex"
                        alignItems="center"
                        justifyContent={
                            !isSmallScreen ? 'flex-start' : 'center'
                        }
                    >
                        <Pagination
                            count={numberOfPages}
                            size={!isSmallScreen ? 'large' : 'small'}
                            page={page}
                            onChange={handlePageChange}
                            totalCount={latestData?.totalCount}
                            onPageSelection={handlePageSelection}
                            itemsPerPage={perPageItems}
                            onItemsPerPageChange={handleItemsPerPageChange}
                            showPageSelection={true}
                            showItemsPerPageSelection
                            showCountStat
                        />
                    </Box>
                )}
            </Box>
        </>
    )
}

const ManagementRoleDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()
    const registry = useRegistry()

    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType('ManagementRoles')

    const managementRoleType = registry.get(
        ResourceTypesNamespace,
        'ManagementRoles',
    )
    const [activeTab, setActiveTab] = useState('overview')
    const [subActiveTab, setSubActiveTab] = useState('applicationRolesGranted')

    const {
        hasAccessToTCodesGrid,
        hasAccessToLocalSensitiveFunctionsGrid,
        canSeeApplications,
        canSeeApplicationRolesGranted,
        canSeeManagementRolesGranted,
    } = useSubcomponents()

    const [targetPerson] = useTargetPerson()

    const preRevoke = () => {
        const itemToRevoke = cartHelpers.managementRoleToCartItem({
            targetPerson,
            assignmentType: 'Remove',
            managementRole: item.resource,
            assignmentStatus: item.assignment,
        })
        if (!itemToRevoke) return undefined

        return itemToRevoke
    }

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}
            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}

            <Box>
                <ItemDetails.Tabs
                    variant={'scrollable'}
                    scrollButtons={'on'}
                    value={subActiveTab}
                    onChange={(_, value) => setSubActiveTab(value)}
                >
                    {canSeeApplicationRolesGranted && (
                        <ItemDetails.Tabs.Tab
                            value="applicationRolesGranted"
                            label={t('Common_ApplicationRolesGranted')}
                            data-protectedsubcomponent={
                                canSeeApplicationRolesGranted
                            }
                        />
                    )}

                    {canSeeManagementRolesGranted && (
                        <ItemDetails.Tabs.Tab
                            value="managementRolesGranted"
                            label={t('Common_ManagementRolesGranted')}
                            data-protectedsubcomponent={
                                canSeeManagementRolesGranted
                            }
                        />
                    )}
                    {hasAccessToLocalSensitiveFunctionsGrid && (
                        <ItemDetails.Tabs.Tab
                            value="localFunctions"
                            label={t('Common_LocalSensitiveFunctions')}
                            data-protectedsubcomponent={
                                hasAccessToLocalSensitiveFunctionsGrid
                            }
                        />
                    )}
                    {hasAccessToTCodesGrid && (
                        <ItemDetails.Tabs.Tab
                            value="tCodes"
                            label={t('Common_TCodes')}
                            data-protectedsubcomponent={hasAccessToTCodesGrid}
                        />
                    )}
                    {canSeeApplications && (
                        <ItemDetails.Tabs.Tab
                            value="applications"
                            label={t('Common_Applications')}
                            data-protectedsubcomponent={canSeeApplications}
                        />
                    )}
                </ItemDetails.Tabs>
            </Box>
            <Box overflow="auto">
                {subActiveTab === 'applicationRolesGranted' && (
                    <ApplicationRolesGranted item={item} />
                )}
            </Box>

            <Box overflow="auto">
                {subActiveTab === 'managementRolesGranted' && (
                    <ManagementRolesGranted item={item} />
                )}
            </Box>

            {subActiveTab === 'localFunctions' && (
                <LocalSensitiveFunctions item={item} />
            )}

            {subActiveTab === 'tCodes' && (
                <TCodesList resourceId={item.resource.id} />
            )}

            {subActiveTab === 'applications' && (
                <ApplicationsList
                    resource={item.resource}
                    useDataHook={useManagementRoleApplications}
                />
            )}

            {item?.assignment.canActivateNow ? (
                <ActivateNowButton
                    resource={item.resource}
                    resourceType={currentResourceType}
                    forDetailsDrawer={true}
                    width="300px"
                    assignment={item?.assignment}
                />
            ) : (
                <RenewRevoke
                    resourceType={managementRoleType}
                    resource={item.resource}
                    assignment={item.assignment}
                    postRenew={toggleDrawer}
                    preRevoke={preRevoke}
                    postRevoke={toggleDrawer}
                    item={item}
                />
            )}

            <Box overflow="auto" width="100%" id="SuggestedApplicationRoles">
                <SuggestedRoles
                    title={t('Common_SuggestedApplicationRoles')}
                    useDataHook={useManagementRoleSuggestedApplicationRoles}
                    toggleDrawer={toggleDrawer}
                    resource={item.resource}
                    targetPerson={targetPerson}
                />
            </Box>
        </>
    )
}

export default ManagementRoleDetails
