import { useEffect, useState } from 'react'
import {
    Box,
    Button as MuiButton,
    makeStyles,
    Link,
    Typography,
    useTheme,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useActivateNow } from '../ListingPage/useRequestAccessResources'
import { useTargetPerson } from 'hooks'
import { Spinner } from 'packages/eid-ui'
import { Modal } from 'components/Modal'
import { Icon } from 'packages/eid-icons'
import { ItemDetails } from 'components'
import { useNotification } from 'packages/eid-ui'
import { useHistory } from 'react-router'
import config from 'config'
import GenericButton from 'components/Button/GenericButton'
import { getURLTarget } from 'packages/core'

const drawerButtonStyles = makeStyles((theme) => ({
    root: {
        minHeight: '31px',
        border: `solid 1px ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: '0 0 0 0',
        color: '#fff',
        textTransform: 'capitalize !important',
        borderRadius: '16px',
        lineHeight: '1.3',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
}))

const ActivateNowButton = ({
    resourceType,
    resource,
    forDetailsDrawer = false,
    forCardView = false,
    assignment = null,
    ...rest
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const history = useHistory()
    const { showWarningMessage } = useNotification()
    const [targetPerson] = useTargetPerson()

    const [showModal, setShowModal] = useState(false)

    const [activateResource, { data, isError, isLoading }] = useActivateNow()

    useEffect(() => {
        if (isError) {
            showWarningMessage(t('FailedToActivateNow'))
        } else if (data) {
            setShowModal(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError, isLoading])

    const classes = drawerButtonStyles()

    const handleActivateNow = () => {
        const dataObject = {
            resourceType: resourceType,
            resourceId: resource.id,
            targetPersonPersonId: targetPerson.id,
            resourceAssignmentId: assignment?.resourceAssignment
                ?.resourceAssignmentId
                ? assignment.resourceAssignment.resourceAssignmentId
                : resource?.resourceAssignmentId,
        }
        activateResource(dataObject)
    }

    const getManageAccessUrl = () => {
        return `${config.APP_SUBPATH}${resourceType?.route}/manageAccess?selected=${resource.id}`
    }
    return (
        <>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                title={
                    <>
                        <p>
                            <Icon
                                name="CheckedRound"
                                style={{ marginRight: '10px' }}
                            />
                            {resource.friendlyName} {t('ActivatedSoonMessage')}.
                        </p>
                        {history.location.pathname.indexOf(
                            `${config.APP_SUBPATH}${resourceType?.route}/manageAccess`,
                        ) < 0 ? (
                            <p>
                                {t('CheckExistingAccess')}{' '}
                                <Link
                                    underline="hover"
                                    target={getURLTarget()}
                                    href={getManageAccessUrl()}
                                >
                                    {t('Here')}
                                </Link>
                                .
                            </p>
                        ) : null}
                    </>
                }
            />
            {forCardView && (
                <GenericButton
                    onClick={handleActivateNow}
                    loading={isLoading}
                    color="#307fc1"
                    rootStylesProp={{
                        borderRadius: '4px',
                        width: '120px',
                        height: '32px',
                        fontSize: '14px',
                        padding: '8px 12px 8px 12px',
                    }}
                >
                    {t('Activate')}
                </GenericButton>
            )}
            {forDetailsDrawer ? (
                <Box
                    display="flex"
                    padding="0 31px"
                    margin="16px 0px"
                    {...rest}
                >
                    <ItemDetails.ActionButton
                        fontColor="#ffffff"
                        bgColor={theme.palette.secondary.main}
                        width="100%"
                        loading={isLoading}
                        onClick={handleActivateNow}
                    >
                        <Box display="flex" alignItems="center">
                            <Icon name="Check" color="#ffffff" />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography style={{ color: '#ffffff' }}>
                                {t('Activate')}
                            </Typography>
                        </Box>
                    </ItemDetails.ActionButton>
                </Box>
            ) : (
                <>
                    {!forCardView && (
                        <MuiButton
                            size="small"
                            classes={classes}
                            variant="outlined"
                            onClick={handleActivateNow}
                            {...rest}
                            endIcon={
                                <Icon
                                    name="ArrowSmall"
                                    fill="white"
                                    color="white"
                                    direction="right"
                                />
                            }
                        >
                            {isLoading ? (
                                <Spinner color="#fff" />
                            ) : (
                                t('Activate')
                            )}
                        </MuiButton>
                    )}
                </>
            )}
        </>
    )
}
export default ActivateNowButton
