import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Card, Typography, Grid, useTheme } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Divider, Spinner, Tooltip } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useGroupByRisks, useRemoveItemFromCart } from 'hooks'
import { Icon } from 'packages/eid-icons'
import { useCartItemStyles, useShowMoreButtonStyles } from './styles'
import CartItemViolation from './CartItemViolation'
import classNames from 'classnames'
import { CartItemComment } from './CartItemComment'
import Button from 'components/Button'
const NS = 'public'
const assignmentTypeLabelKey = {
    Add: 'Common_RequestAccess',
    Remove: 'Common_RevokeAccess',
}

const MyTypography = withStyles({
    root: {},
    h2: {
        color: '#FFFFFF',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '1.2',
    },
    h3: {
        fontSize: '22px',
        color: '#000000',
        textTransform: 'uppercase',
    },
    h4: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#000000',
    },
    caption: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#8a8989',
    },
    subtitle1: {
        position: 'absolute',
        fontSize: '150px',
        right: '17px',
        top: '-100px',
        fontWeight: '300',
        color: '#FFF',
        opacity: '0.1',
    },
})(Typography)

const CartItem = (props) => {
    const theme = useTheme()
    const classes = useCartItemStyles({
        bgColor: theme.palette.primary.main,
        assignmentType: props.item.assignmentType,
    })

    const { t } = useTranslation()
    const { item, index, onCommentChange, risks } = props

    const riskGroupedByLocalRiskId = useGroupByRisks(risks)

    const [
        removeItemFromCart,
        { isLoading: isRemoving },
    ] = useRemoveItemFromCart()

    return (
        <Card className={classes.cartItem} key={index}>
            <Box className={classes.cartItemHeader}>
                <Box className={classes.itemLabel}>
                    <Box className={classes.addOrRemoveLabel}>
                        {t(assignmentTypeLabelKey[props.item.assignmentType])}
                    </Box>
                    <Box className={classes.resourceTypeLabel}>
                        <Icon name={item.resourceType_Name} />
                        <Box component="span" marginLeft="8px">
                            {t(`Common_${item.resourceType_Name}`)}
                        </Box>
                    </Box>
                </Box>
                <Box flex="0.9">
                    <MyTypography
                        variant="h2"
                        style={{ wordBreak: 'break-word' }}
                    >
                        {item.resource_FriendlyName}
                    </MyTypography>
                </Box>
                <Tooltip title={t('Common_RemoveFromCart')}>
                    <Box
                        className={classes.deleteIconContainer}
                        onClick={() => {
                            if (isRemoving) return
                            removeItemFromCart(item.id)
                        }}
                    >
                        {isRemoving ? (
                            <Spinner
                                color={theme.palette.error.main}
                                size={0.6}
                            />
                        ) : (
                            <Icon className="delete" name="Delete" />
                        )}
                    </Box>
                </Tooltip>
            </Box>
            <Box className={classes.cartItemContent}>
                <Box className={classes.cartCommentContainer}>
                    <Box className={classes.cartComment}>
                        <CartItemComment
                            item={item}
                            containsRisks={
                                riskGroupedByLocalRiskId &&
                                riskGroupedByLocalRiskId.length > 0
                            }
                            onCommentChange={onCommentChange}
                        />
                    </Box>
                    <Box className={classes.timeConstraints}>
                        {item.timeConstraintActive ? (
                            <>
                                {item.startDateUtc && (
                                    <Attribute
                                        label={t('Common_StartDateTime')}
                                        value={moment
                                            .utc(item.startDateUtc)
                                            .local()
                                            .format('L LT')}
                                    />
                                )}
                                {item.endDateUtc && (
                                    <Attribute
                                        marginTop="8px"
                                        label={t('Common_EndDateTime')}
                                        value={moment
                                            .utc(item.endDateUtc)
                                            .local()
                                            .format('L LT')}
                                    />
                                )}
                            </>
                        ) : (
                            <Attribute
                                label={t('Common_ExpiresOn')}
                                value="-"
                            />
                        )}
                    </Box>
                </Box>

                <Box className={classes.moreAttributes}>
                    {item.resourceType_Name === 'BusinessRole' && (
                        <Attribute
                            className={classes.horizontalOrVertical}
                            label={t('BusinessRoles_DifferentiationValue')}
                            value={item.location_FriendlyName}
                        />
                    )}

                    {item.resourceType_Name === 'SharedFolder' && (
                        <Attribute
                            className={classes.horizontalOrVertical}
                            label={t('Common_AccessLevel')}
                            value={item.resourceTypeRole_FriendlyName}
                        />
                    )}
                    {item.resourceType_Name === 'AzureRole' &&
                        item.location_FriendlyName && (
                            <Attribute
                                className={classes.horizontalOrVertical}
                                label={t('AzureRbacRoles_Scope')}
                                value={item.location_FriendlyName}
                            />
                        )}

                    {item.resourceType_Name === 'MailBox' && (
                        <Attribute
                            className={classes.horizontalOrVertical}
                            label={t('Common_AccessLevel')}
                            value={item.resourceTypeRole_FriendlyName}
                        />
                    )}
                    {item.resourceType_Name ===
                        'ProtectedApplicationResource' && (
                        <Attribute
                            className={classes.horizontalOrVertical}
                            label={t('Common_AccessLevel')}
                            value={item.resourceTypeRole_FriendlyName}
                        />
                    )}
                    {item?.computerAdditionalProperties && (
                        <>
                            {item?.computerAdditionalProperties
                                ?.requestType && (
                                <Fragment>
                                    <Attribute
                                        padding={'0px 0px 16px 0px'}
                                        className={classes.horizontalOrVertical}
                                        label={t('Common_RequestType')}
                                        value={
                                            item?.computerAdditionalProperties
                                                ?.requestType
                                        }
                                    />
                                </Fragment>
                            )}
                            <Divider />
                            <Attribute
                                padding={'16px 0px'}
                                className={classes.horizontalOrVertical}
                                label={t('Common_TypeOfAccess')}
                                value={
                                    item?.computerAdditionalProperties
                                        ?.typeOfAccess
                                        ? item?.computerAdditionalProperties
                                              ?.typeOfAccess
                                        : '-'
                                }
                            />
                            {item?.computerAdditionalProperties
                                ?.personalCredentialId && (
                                <>
                                    <Divider />
                                    <Box display="flex">
                                        <Attribute
                                            padding={'16px 0px'}
                                            className={
                                                classes.horizontalOrVertical
                                            }
                                            label={t('CredentialType')}
                                            value={
                                                item
                                                    ?.computerAdditionalProperties
                                                    ?.credentialType
                                                    ? item
                                                          ?.computerAdditionalProperties
                                                          ?.credentialType
                                                    : '-'
                                            }
                                        />
                                        <Attribute
                                            padding={'16px 34px'}
                                            className={
                                                classes.horizontalOrVertical
                                            }
                                            label={t('Credential')}
                                            value={
                                                item
                                                    ?.computerAdditionalProperties
                                                    ?.credentialFriendlyName
                                                    ? item
                                                          ?.computerAdditionalProperties
                                                          ?.credentialFriendlyName
                                                    : '-'
                                            }
                                        />
                                    </Box>
                                </>
                            )}
                            {item.resourceTypeRole_FriendlyName && (
                                <>
                                    <Divider />
                                    <Attribute
                                        padding={'16px 0px'}
                                        className={classes.horizontalOrVertical}
                                        label={t('Common_AccessLevel')}
                                        value={
                                            item.resourceTypeRole_FriendlyName
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}
                    {item.additionalFields.length > 0 && (
                        <AdditionalFieldsSection
                            item={item}
                            classes={classes}
                        />
                    )}
                </Box>
            </Box>
            {riskGroupedByLocalRiskId && riskGroupedByLocalRiskId.length > 0 && (
                <>
                    <Divider />
                    <Box className={classes.cartItemContent}>
                        {riskGroupedByLocalRiskId.map((r, index) => (
                            <Box key={`${r.id}_${index}`} py={'4px'}>
                                <CartItemViolation risk={r} showReason={true} />
                            </Box>
                        ))}
                    </Box>
                </>
            )}

            <Box
                className={classNames(classes.borderBox, {
                    [classes.riskBorderBox]:
                        riskGroupedByLocalRiskId &&
                        riskGroupedByLocalRiskId.length > 0,
                })}
            />
        </Card>
    )
}

const Attribute = ({ label, value, ...rest }) => {
    return (
        <Box {...rest}>
            <Box
                color="#8b909a"
                fontSize="12px"
                style={{ textTransform: 'uppercase' }}
            >
                {label}
            </Box>
            <Box fontSize="14px">{value}</Box>
        </Box>
    )
}

const AdditionalFieldsSection = ({ item, classes }) => {
    const { t } = useTranslation()
    const buttonClasses = useShowMoreButtonStyles()
    const [showMore, setShowMore] = useState(false)
    return (
        <>
            <Divider />
            <Box style={{ position: 'relative' }}>
                <Button
                    onClick={() => setShowMore(!showMore)}
                    classes={buttonClasses}
                    endIcon={
                        <Icon
                            name="Arrow"
                            direction={showMore ? 'up' : 'down'}
                            style={{ width: '16px', height: '16px' }}
                            color="#2471ab"
                        />
                    }
                >
                    {showMore ? t('Common_ShowLess') : t('Common_ShowMore')}
                </Button>
                <Attribute
                    padding={'23px 0px 8px 0px'}
                    className={classes.horizontalOrVertical}
                    label={t('Common_DynamicRequestAttributes')}
                />
                {showMore && (
                    <Box display="flex">
                        <Box>
                            <Icon
                                name="dynamicForm"
                                height="45px"
                                width="45px"
                            />
                        </Box>
                        <Box style={{ marginLeft: '34px' }}>
                            <Grid container spacing={1}>
                                {item.additionalFields.map((field, index) => (
                                    <Grid item xs={6}>
                                        <AdditionalField
                                            key={index}
                                            className={classes.additionalField}
                                            label={
                                                field.additionalField_LocaleKey
                                                    ? t(
                                                          `${NS}:MsCommonAnonymous_${field.additionalField_LocaleKey}`,
                                                      )
                                                    : field.additionalField_Name
                                            }
                                            value={field.additionalField_Value}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    )
}

const AdditionalField = ({ label, value, ...rest }) => {
    return (
        <Box {...rest}>
            <Box fontWeight="bold">{label}</Box>
            <Box color="#5d6870" fontSize="12px">
                {value}
            </Box>
        </Box>
    )
}

CartItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number,
    handleDelteItem: PropTypes.func,
}

export default CartItem
