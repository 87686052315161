import { useState, useEffect } from 'react'
import {
    ApplicationsList,
    ItemDetails,
    SuggestedRoles,
    TCodesList,
} from 'components'
import { Box, Link } from '@material-ui/core'
import useSubcomponents from 'useSubcomponents'
import {
    useCheckManagementRoleAccess,
    useManagementRoleApplications,
    useManagementRoleSuggestedApplicationRoles,
    useTargetPerson,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import cartHelpers from 'containers/Cart/cartHelpers'
import { genericHashLink } from 'utils'
import { MoreInformationSection } from './MoreInformationSection'
import { OverviewSection } from './OverviewSection'
import { LocalSensitiveFunctions } from './LocalSensitiveFunctions'
import { ApplicationRolesGranted } from './ApplicationRolesGranted'
import { ManagementRolesGranted } from './ManagementRolesGranted'
import { AddToCart } from 'components/AddToCart'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { Skeleton } from '@material-ui/lab'
import ActivateNowButton from '../ActivateNowButton'
import useConfiguration from 'useConfiguration'
import appConfig from 'config'

const HashLink = genericHashLink(Link)

const ManagementRoleDetails = ({ managementRole, toggleDrawer }) => {
    const { t } = useTranslation()
    const registry = useRegistry()
    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType('ManagementRoles')

    const managementRoleType = registry.get(
        ResourceTypesNamespace,
        'ManagementRoles',
    )

    const {
        hasAccessToTCodesGrid,
        hasAccessToLocalSensitiveFunctionsGrid,
        canSeeApplicationRolesGranted,
        canSeeManagementRolesGranted,
        canSeeApplications,
    } = useSubcomponents()

    const [activeTab, setActiveTab] = useState('overview')
    const [subActiveTab, setSubActiveTab] = useState('applicationRolesGranted')

    const [targetPerson] = useTargetPerson()

    const {
        data: assignmentStatuses,
        isLoading: isCheckingAccess,
    } = useCheckManagementRoleAccess(managementRole.id, targetPerson.id)

    const [assignmentStatus, setAssignmentStatus] = useState(null)

    useEffect(() => {
        if (assignmentStatuses?.length >= 1) {
            setAssignmentStatus(assignmentStatuses[0])
        }
    }, [assignmentStatuses, setAssignmentStatus])

    const [suggestedRolesCount, setSuggestedRolesTotalCount] = useState('')

    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.managementRoleToCartItem({
            targetPerson,
            assignmentType: 'Add',
            managementRole,
            assignmentStatus,
            ...baseObj,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const preValidate = () => {
        if (assignmentStatus && assignmentStatus.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }
        return ''
    }

    const scrollToSuggestedApplicationRolesLink = (
        <Box paddingX="31px" paddingY="16px" textAlign="right">
            <HashLink
                smooth
                component="button"
                underline="always"
                to={`${appConfig.APP_SUBPATH}/ManagementRoles?selected=${managementRole.id}#SuggestedApplicationRoles`}
                scroll={(el) => el.scrollIntoView()}
            >
                {t('Common_SeeSuggestedApplicationRoles', {
                    count: suggestedRolesCount,
                })}
            </HashLink>
        </Box>
    )
    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && (
                <OverviewSection managementRole={managementRole} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection managementRole={managementRole} />
            )}

            {isCheckingAccess ? (
                <Box padding={'24px'}>
                    <Skeleton height={40} />
                </Box>
            ) : managementRole.canActivateNow ? (
                <ActivateNowButton
                    resource={managementRole}
                    resourceType={currentResourceType}
                    forDetailsDrawer={true}
                    width="300px"
                />
            ) : (
                <AddToCart
                    resourceType={managementRoleType}
                    resource={managementRole}
                    preAdd={preAdd}
                    postAdd={toggleDrawer}
                    preValidate={preValidate}
                />
            )}

            {suggestedRolesCount > 0 && scrollToSuggestedApplicationRolesLink}

            <Box>
                <ItemDetails.Tabs
                    variant={'scrollable'}
                    scrollButtons={'on'}
                    value={subActiveTab}
                    onChange={(_, value) => setSubActiveTab(value)}
                >
                    {canSeeApplicationRolesGranted && (
                        <ItemDetails.Tabs.Tab
                            value="applicationRolesGranted"
                            label={t('Common_ApplicationRolesGranted')}
                            data-protectedsubcomponent={canSeeApplicationRolesGranted}
                        />
                    )}

                    {canSeeManagementRolesGranted && (
                        <ItemDetails.Tabs.Tab
                            value="managementRolesGranted"
                            label={t('Common_ManagementRolesGranted')}
                            data-protectedsubcomponent={canSeeManagementRolesGranted}
                        />
                    )}

                    {hasAccessToLocalSensitiveFunctionsGrid && (
                        <ItemDetails.Tabs.Tab
                            value="localFunctions"
                            label={t('Common_LocalSensitiveFunctions')}
                            data-protectedsubcomponent={hasAccessToLocalSensitiveFunctionsGrid}
                        />
                    )}
                    {hasAccessToTCodesGrid && (
                        <ItemDetails.Tabs.Tab
                            value="tCodes"
                            label={t('Common_TCodes')}
                            data-protectedsubcomponent={hasAccessToTCodesGrid}
                        />
                    )}
                    {canSeeApplications && (
                        <ItemDetails.Tabs.Tab
                            value="applications"
                            label={t('Common_Applications')}
                            data-protectedsubcomponent={canSeeApplications}
                        />
                    )}
                </ItemDetails.Tabs>
            </Box>
            <Box overflow="auto">
                {subActiveTab === 'applicationRolesGranted' && (
                    <ApplicationRolesGranted managementRole={managementRole} />
                )}
            </Box>

            <Box overflow="auto">
                {subActiveTab === 'managementRolesGranted' && (
                    <ManagementRolesGranted managementRole={managementRole} />
                )}
            </Box>

            {subActiveTab === 'localFunctions' && (
                <LocalSensitiveFunctions managementRole={managementRole} />
            )}

            {subActiveTab === 'tCodes' && (
                <TCodesList resourceId={managementRole.id} />
            )}

            {subActiveTab === 'applications' && (
                <ApplicationsList
                    resource={managementRole}
                    useDataHook={useManagementRoleApplications}
                />
            )}

            <Box
                overflow="auto"
                width="100%"
                id="ManagementRolesSuggestedApplicationRoles"
            >
                <SuggestedRoles
                    title={t('Common_SuggestedApplicationRoles')}
                    useDataHook={useManagementRoleSuggestedApplicationRoles}
                    toggleDrawer={toggleDrawer}
                    resource={managementRole}
                    targetPerson={targetPerson}
                    onDataLoaded={(data) =>
                        setSuggestedRolesTotalCount(data.totalCount)
                    }
                />
            </Box>
        </>
    )
}

export default ManagementRoleDetails
