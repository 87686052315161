import { Fragment } from 'react'
import ApplicationRoleDrawer from './Details/ApplicationRoleDetails'
import BusinessRoleDrawer from './Details/BusinessRoleDetails'
import AzureLicenseDrawer from './Details/AzureLicenseDetails'
import ManagementRoleDrawer from './Details/ManagementRoleDetails'
import SharedFolderDrawer from './Details/SharedFolderDetails'
import MailBoxesDrawer from './Details/MailboxDetails'
import ComputerDrawer from './Details/ComputerDetails'
import CredentialDrawer from './Details/CredentialDetails'
import ApplicationDrawer from './Details/ApplicationDetails'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import ItemHeader from './ItemHeader'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import { Drawer, Loader } from 'packages/eid-ui'
import { AzureRoles } from './Details/AzureRoles'

const DetailsDrawer = ({ type, item, toggleDrawer, attributes, ...rest }) => {
    const isSmallScreen = useIsSmallScreen()
    const headerAttribute = attributes.find((x) => x.isHeader)

    const getDetailedDrawer = () => {
        switch (type) {
            case 'ApplicationRoles':
                return ApplicationRoleDrawer
            case 'BusinessRoles':
                return BusinessRoleDrawer
            case 'AzureLicenses':
                return AzureLicenseDrawer
            case 'AzureRoles':
                return AzureRoles
            case 'ManagementRoles':
                return ManagementRoleDrawer
            case 'SharedFolders':
                return SharedFolderDrawer
            case 'Mailboxes':
                return MailBoxesDrawer
            case 'Computers':
                return ComputerDrawer
            case 'Applications':
                return ApplicationDrawer
            case 'Credentials':
                return CredentialDrawer
            default:
                return <Fragment />
        }
    }

    let DetailComponent = getDetailedDrawer()
    const itemDetails = (
        <DetailComponent
            item={item}
            toggleDrawer={toggleDrawer}
            attributes={attributes}
            {...rest}
        />
    )

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper
            withCloseIcon={!isSmallScreen}
            toggleDrawer={toggleDrawer}
        >
            <ItemHeader title={item.resource[headerAttribute.name]} />
            {!item && <Loader />}
            {item && itemDetails}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="768px"
            open={!isNilOrEmpty(item)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            closeOnOutsideClick
            header={<ItemHeader title={item.resource[headerAttribute.name]} />}
        >
            {!item && <Loader />}
            {item && itemDetails}
        </Drawer>
    )
}
export default DetailsDrawer
