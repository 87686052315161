import PropTypes from 'prop-types'
import { Card, useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import ItemHeader from 'containers/ManageAccess/ItemHeader'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl } from 'utils'
import { isNilOrEmpty } from 'packages/core'
import GenericButton from 'components/Button/GenericButton'
import RequestableResourceDetails from 'components/RequestableResourceDetails'
import ActivateNowButton from '../ListingPage/ActivateNowButton'

const useCardStyles = makeStyles({
    root: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px !important',
        position: 'relative',
        overflow: 'visible',
        boxShadow: 'none !important',
        border: 'none !important',
        '&:hover': {
            borderBottomRightRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
            overflow: 'visible',
            zIndex: 4,
            '&:not(:hover)': {
                zIndex: 2,
                transition: 'z-index 0.5s ease 0s',
            },
        },
        ...props.cardStyles,
    }),
    lineGlow: {
        width: '100%',
        height: '1px',
        opacity: 0.25,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderImageSlice: 1,
    },
})

const styles = {
    card: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        position: 'relative',
        overflow: 'visible',

        '&:hover': {
            borderBottomRightRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
            overflow: 'visible',

            zIndex: 4,

            '&:not(:hover)': {
                zIndex: 2,
                transition: 'z-index 0.5s ease 0s',
            },
        },
        ...props.cardStyles,
    }),
}

const ItemCard = withStyles(styles)((props) => {
    const {
        item,
        cardStyles,
        attributes,
        color,
        resourceType,
        onItemClick,
    } = props
    const data = item.resource ? item.resource : item

    const canActivateNow =
        resourceType?.name !== 'Computers' && item?.canActivateNow

    const { t } = useTranslation()
    const cardClasses = useCardStyles({ cardStyles })
    const theme = useTheme()
    const headerAttribute = attributes.find((x) => x.isHeader)
    const imageAttribute = attributes.find(
        (i) => i.component?.name === 'ApplicationImage',
    )
    let imgUrl
    if (imageAttribute) {
        imgUrl = !isNilOrEmpty(data[imageAttribute.name])
            ? getFullImageUrl(data[imageAttribute.name])
            : getFullImageUrl('/webcdn/Images/AppLogos/Genric-1.png')
    }

    return (
        <Card classes={cardClasses} role="button">
            <ItemHeader title={data[headerAttribute?.name]} url={imgUrl} />

            <RequestableResourceDetails
                item={data}
                attributes={attributes}
                assignmentDetails={item?.assignment?.assignmentDetails}
                color={color}
                additionalData={item}
                data={item}
                resourceType={resourceType}
            />

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
            >
                <Box
                    style={{
                        gap: '10px',
                        padding: '20px',
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <GenericButton
                        onClick={onItemClick}
                        color={theme.palette.secondary.main}
                        rootStylesProp={{
                            borderRadius: '4px',
                            width: '100%',
                            minHeight: '31px',
                            lineHeight: '1.3',
                        }}
                    >
                        {canActivateNow ? t('Common_Details') : t('Common_RequestAccess')}
                    </GenericButton>

                    {canActivateNow && (
                        <ActivateNowButton
                            resource={data}
                            resourceType={resourceType}
                            forCardView={true}
                            assignment={item?.assignment}
                        />
                    )}
                </Box>
            </Box>
            <div className={cardClasses.lineGlow}></div>
        </Card>
    )
})

ItemCard.propTypes = {
    item: PropTypes.object,
    onItemClick: PropTypes.func,
    className: PropTypes.string,
}

export default ItemCard
