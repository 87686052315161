import React, { useState, useEffect } from 'react'
import { useDebounce } from 'packages/core'
import { TextInput } from 'packages/eid-ui'

const TextualFilter = ({ value, onChange,placeholder, ...rest }) => {
    const [searchKey, setSearchKey] = useState(value)

    useEffect(() => {
        if (value !== searchKey) {
            setSearchKey(value)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const debouncedValue = useDebounce(searchKey)

    useEffect(() => {
        onChange(debouncedValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue])

    return (
        <TextInput
            {...rest}
            value={searchKey}
            handleChange={(e) => setSearchKey(e.target.value)}
            placeholder={placeholder}
        />
    )
}

export default TextualFilter
