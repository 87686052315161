import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, makeStyles } from '@material-ui/core'
import {
    AttentionIcon,
    FilledCheckIcon,
    CloseIcon,
    InfoIcon,
} from '../../eid-icons'

const icons = {
    success: <FilledCheckIcon />,
    warning: <AttentionIcon />,
    information: <InfoIcon />,
}

const colors = {
    success: '#029435',
    warning: '#d0021b',
    information: '#3792cb',
}

const useStyles = makeStyles({
    root: ({ type }) => ({
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        width: 350,
        borderRadius: 5,
        color: '#fff',
        padding: 20,
        borderBottom: `10px solid ${colors[type]}`,
        fontSize: 16,
    }),
})

const NotificationMessage = React.forwardRef(
    ({ type, message, onClose }, ref) => {
        const classes = useStyles({ type })
        return (
            <Box className={classes.root}>
                <Box display="flex" alignItems="center" ref={ref}>
                    <Box flex="0.2" display="flex" alignItems="center">
                        {icons[type]}
                    </Box>
                    <Box flex="0.8">
                        <Typography
                            variant="body2"
                            style={{ fontSize: '16px' }}
                        >
                            {message}
                        </Typography>
                    </Box>
                    <Box flex="0.2" display="flex" justifyContent="flex-end">
                        <span style={{ cursor: 'pointer' }} onClick={onClose}>
                            <CloseIcon />
                        </span>
                    </Box>
                </Box>
            </Box>
        )
    },
)

NotificationMessage.propTypes = {
    type: PropTypes.oneOf(['success', 'warning', 'information']).isRequired,
    message: PropTypes.string.isRequired,
}

export default NotificationMessage
