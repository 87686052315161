import React from 'react'
import {
    FormControlLabel,
    withStyles,
    makeStyles,
    Switch,
    Box,
} from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    label: ({ labelColor, fontSize }) => ({
        maxWidth: '220px',
        fontSize: fontSize,
        color: labelColor,
    }),
})
const StyledSwitch = withStyles((theme) => ({
    root: {
        width: '36px',
        height: '20px',
        padding: 0,
        margin: theme.spacing(1),
        borderRadius: '13px',
        transition: 'all 300ms ease 0s !important',
    },
    switchBase: {
        padding: 1,
        transition: 'all 300ms ease 0s !important',

        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
        },
    },
    thumb: {
        width: 18,
        height: 18,
    },
    track: {
        borderRadius: 26 / 2,
        backgroundColor: '#D7D7DB',
        opacity: 1,
        transition: 'all 300ms ease 0s !important',

        //transition: theme.transitions.create(['background-color', 'border']),
    },
    disabled: {
        transition: 'all 300ms ease 0s !important',
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    )
})

const ToggleSwitch = ({
    onChange,
    value,
    label,
    labelColor = '#000000',
    fontSize = '14px',
    ...rest
}) => {
    const classes = useStyles({ labelColor, fontSize })
    return (
        <Box display="flex">
            <Box display="flex" paddingTop="2px">
                <FormControlLabel
                    control={
                        <StyledSwitch
                            checked={value}
                            onChange={onChange}
                            {...rest}
                        />
                    }
                    label={label}
                    classes={classes}
                />
            </Box>
        </Box>
    )
}

export default ToggleSwitch
