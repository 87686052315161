import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { useQueryApiGet, listToTree, useDebounce } from '../../core'
import { Tree, TextInput } from '../../eid-ui'
import { TreeV2 } from '../../eid-ui'
import TreeV3 from 'packages/eid-ui/Tree/V3'
import { Icon } from 'packages/eid-icons'

const contains = (text, searchTerm) => {
    if (!text) {
        return false
    }
    return text.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
}

const search = (items, searchTerm) => {
    const filteredItems = items.reduce((acc, item) => {
        if (
            contains(item.friendlyName, searchTerm) ||
            contains(item.name, searchTerm)
        ) {
            acc.push(item)
        } else if (item.children && item.children.length > 0) {
            let newItems = search(item.children, searchTerm)
            if (newItems && newItems.length > 0) {
                acc.push({
                    ...item,
                    children: newItems,
                })
            }
        }
        return acc
    }, [])

    return filteredItems
}

const EidTree = ({
    url,
    dedupingInterval = 2000,
    onChange,
    identifier = 'id',
    parentIdentifier = 'parentId',
    rootParentIdentifier = null,
    value = null,
    searchable = false,
    searchPlaceholder,
    initialValue,
    treeContainerStyle = {},
    searchContainerStyle = {},
    version = 'v1',
    queryKey,
    ...rest
}) => {
    const [treeItems, setTreeItems] = useState([])

    const { data } = useQueryApiGet(queryKey, url, { staleTime: Infinity })

    const [searchKey, setSearchKey] = useState('')

    const debouncedSearchValue = useDebounce(searchKey, 500)

    useEffect(() => {
        if (data) {
            let itemsToSet

            if (debouncedSearchValue !== '') {
                itemsToSet = search(
                    listToTree(data, {
                        identifier,
                        parentIdentifier,
                        rootParentIdentifier,
                    }),
                    debouncedSearchValue,
                )
            } else {
                itemsToSet = listToTree(data, {
                    identifier,
                    parentIdentifier,
                    rootParentIdentifier,
                })
            }
            setTreeItems(itemsToSet)
        }
    }, [data, debouncedSearchValue])

    useEffect(() => {
        if (data && !value && initialValue) {
            onChange(data.find((i) => i.id === initialValue))
        }
    }, [initialValue, value, data])

    return (
        <>
            {searchable && (
                <Box {...searchContainerStyle} marginBottom="15px">
                    <TextInput
                        placeholder={searchPlaceholder}
                        value={searchKey}
                        handleChange={(e) => setSearchKey(e.target.value)}
                    />
                </Box>
            )}
            <Box paddingX="10px" {...treeContainerStyle}>
                {version === 'v1' ? (
                    <Tree
                        data={treeItems}
                        searchTerm={debouncedSearchValue}
                        loading={!data}
                        handleSelect={(item) => {
                            onChange(item)
                        }}
                        selectedItem={value}
                        {...rest}
                    ></Tree>
                ) : version === 'v2' ? (
                    <TreeV2
                        data={treeItems}
                        loading={!data}
                        handleSelect={(item) => {
                            onChange(item)
                        }}
                        selectedItem={value}
                        searchTerm={debouncedSearchValue}
                        {...rest}
                    />
                ) : (
                    <TreeV3
                        data={treeItems[0]}
                        loading={!data}
                        label="friendlyName"
                        handleSelect={(item) => {
                            onChange(item)
                        }}
                        selectedItemId={value?.id}
                        isExpanded={debouncedSearchValue?.length >= 3}
                        highlightText={debouncedSearchValue}
                        expandIcon={<Icon name="Expand" />}
                        collapseIcon={<Icon name="Collapse" />}
                        radioSelection={true}
                        {...rest}
                    />
                )}
            </Box>
        </>
    )
}

EidTree.propTypes = {
    /**
     * URL where to fetch the data from.
     */
    url: PropTypes.string.isRequired,

    /**
     * Callback when there is a change in the value.
     */
    onChange: PropTypes.func,

    /**
     * Request deduplication interval (ms) for requests with the same key.
     * Default is set to 2000.
     */
    dedupingInterval: PropTypes.number,

    /**
     * Property name that uniquely identifies an item in the tree.
     */
    identifier: PropTypes.string,

    /**
     * Property name that indicates the parentId of an item.
     */
    parentIdentifier: PropTypes.string,

    /**
     * Value of the {parentIdentifier} property for root items.
     */
    rootParentIdentifier: PropTypes.any,
}

export default EidTree
