import { useQuery } from 'packages/core'
import LoginHistoryAccessDetails from './Computers/LoginHistoryAccessDetails'
import LoginSessionAccessDetails from './Computers/LoginSessionAccessDetails'
import MembershipBasesAccessDetails from './Computers/MembershipBasesAccessDetails'

const ComputerDetails = ({ item, toggleDrawer }) => {
    const query = useQuery()
    const selectedTabForComputers = query.get('tab')
    return (
        <>
            {selectedTabForComputers === 'history' ? (
                <LoginHistoryAccessDetails
                    item={item}
                    toggleDrawer={toggleDrawer}
                />
            ) : selectedTabForComputers === 'loginsession' ? (
                <LoginSessionAccessDetails
                    item={item}
                    toggleDrawer={toggleDrawer}
                />
            ) : (
                <MembershipBasesAccessDetails
                    item={item}
                    toggleDrawer={toggleDrawer}
                />
            )}
        </>
    )
}

export default ComputerDetails
