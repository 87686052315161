import { useCallback } from 'react'
import { useAuthState } from '../auth/context'
import axios from 'axios'
import config from 'config'
import { useHistory } from 'react-router'
import appConfig from 'config'
import useUserManager from '../auth/useUserManager'
import { useQuery } from 'packages/core'

/**
 * Returns a function that can be used to call an API.
 * This function wraps the axios instance.
 */
const useAxios = (baseUrl = config.API_URL) => {
    const history = useHistory()
    const userManager = useUserManager()

    const query = useQuery()
    const returnedFromWorkflow = query.get('returnedFromWorkflow') === 'true'

    const callApi = useCallback(
        async ({ headers, ...rest }) => {
            try {
                var user = await userManager.getUser()

                const { data } = await axios({
                    baseURL: baseUrl,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.access_token}`,
                        ...headers,
                    },
                    ...rest,
                    validateStatus: (status) => status >= 200 && status <= 299,
                })
                return data
            } catch (err) {
                if (err && err.response && err.response.status === 401) {
                    history.push(`${appConfig.APP_SUBPATH}/sessionExpired`)
                } else if (err && err.response && err.response.status === 503) {
                    history.push(`${appConfig.APP_SUBPATH}/underMaintenance`)
                } else if (
                    err &&
                    err.response &&
                    err.response.status === 404 &&
                    returnedFromWorkflow
                ) {
                    query.delete('returnedFromWorkflow')
                    history.push(`${appConfig.APP_SUBPATH}/`)
                }
                throw err
            }
        },
        [userManager],
    )

    return callApi
}
export default useAxios
