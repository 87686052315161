import { Fragment, useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Collapse, Paper } from '@material-ui/core'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { Button } from './Button'
import { Owners } from './Owners'
import { ArrowIcon } from 'packages/eid-icons'
import { useApplication } from 'hooks'
import { useHistory } from 'react-router'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl } from 'utils'
import { Actions } from './Actions'
import { isNilOrEmpty, useQuery } from 'packages/core'
import { Instructions } from './Instructions'
import { Skeleton } from '@material-ui/lab'
import { AzureApplicationOwners } from './AzureApplicationOwners'
import { AzureApplicationDeputies } from './AzureApplicationDeputies'
import appConfig from 'config'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        imgContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            minWidth: '230px',
            maxWidth: '230px',

            '& img': {
                maxHeight: '92px',
                maxWidth: '146px',
            },

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                minWidth: '116px',
                '& img': {
                    maxHeight: '33px',
                    width: '66px',
                },
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'none',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        description: {
            [`@media (min-width:1366px)`]: {
                maxWidth: '300px',
            },
        },
        actionsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            minWidth: '230px',
            maxWidth: '230px',
            paddingRight: theme.spacing(2),

            [`@media (max-width:445px)`]: {
                alignItems: 'flex-start',
            },
        },
        backButton: {
            '& >button': {
                '&:hover': {
                    backgroundColor: '#307fc1',
                    color: 'white',
                    '& span': {
                        color: theme.palette.common.white,
                    },
                },
            },
        },
        moreInfoButton: {
            marginTop: theme.spacing(3),
            '& >button': {
                backgroundColor: theme.palette.grey[900],
                color: theme.palette.common.white,

                border: `solid 1px ${theme.palette.grey[900]}`,

                '&:hover': {
                    backgroundColor: theme.palette.grey[900],

                    '& span': {
                        //  color: theme.palette.common.white,
                    },
                },
            },
        },
        actionDropdown: {
            marginTop: theme.spacing(3),
        },
        showMoreButton: {
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },

            position: 'absolute',
            bottom: '-15px',
            left: 'calc(50% - 83px)',
            zIndex: 2,
            '& > button': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                border: 'none',
                height: '32px',
                minWidth: '115px',
                color: '#307fc1',
                fontSize: '11px',
                textTransform: 'uppercase',
                borderRadius: '15px',
                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                cursor: 'pointer',
                paddingLeft: '12px',
                paddingRight: '6px',
                '& svg': {
                    color: '#307fc1',
                    height: '20px',
                },
            },
        },
        collapsibleContainer: {
            display: 'flex',
            flexDirection: 'column',
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
            '& div': {
                textAlign: 'start',
            },
        },
        actionContainerMobile: {},
        azureApplicationDetailsLabel: {
            color: '#000000',
        },
        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
        commonDeputies: {
            'flex-wrap': 'nowrap',
        },
    }),
)

export const ApplicationBanner = (props: any) => {
    const classes = useStyles()
    const history = useHistory()
    const query = useQuery()

    const { applicationId } = props

    const { data, isLoading } = useApplication(applicationId)

    const [, dispatch]: any = useResourceTypeContext()

    useEffect(() => {
        if (data)
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'application',
                    value: data,
                },
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const { t } = useTranslation()

    const [checked, setChecked] = useState(false)

    const handleChange = () => {
        setChecked((prev) => !prev)
    }

    const showAzureApplicationDetails =
        data &&
        data.isAzureApplication &&
        (data.azureApplicationFriendlyName ||
            data.azureApplicationDescription ||
            data.azureApplicationId ||
            data.servicePrincipleId ||
            data.applicationId)

    const azureApplicationDetails = data && (
        <Box>
            <AttributeLabel className={classes.azureApplicationDetailsLabel}>
                {t('Common_AzureApplicationDetails')}
            </AttributeLabel>
            <Box display="flex" flexWrap="wrap">
                <Box paddingRight="32px">
                    {data.azureApplicationFriendlyName && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Common_FriendlyName')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationFriendlyName}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationDescription && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Common_Description')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationDescription}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureIntegrationType && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Common_Type_Of_Integration')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureIntegrationType}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationLine && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Common_Application_Line')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationLine}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureUpxId && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Common_Upx_ID')}
                            </AttributeLabel>
                            <AttributeValue>{data.azureUpxId}</AttributeValue>
                        </Attribute>
                    )}

                    {data.azureAuthenticationLevel && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Common_Authentication_Level')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureAuthenticationLevel}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.applicationId && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Common_AzureApplicationAppId')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.applicationId}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationId && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Common_AzureApplicationObjectId')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationId}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.servicePrincipleId && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Common_ServicePrincipleId')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.servicePrincipleId}
                            </AttributeValue>
                        </Attribute>
                    )}
                </Box>

                <Box maxWidth="260px">
                    {data && data.azureApplicationId && (
                        <Box>
                            <Attribute>
                                <AttributeLabel>
                                    {t('Common_Owners')}
                                </AttributeLabel>
                                <AzureApplicationOwners data={data} />
                            </Attribute>
                        </Box>
                    )}

                    {data && data.azureApplicationId && (
                        <Box>
                            <Attribute>
                                <AttributeLabel>
                                    {t('Common_Deputies')}
                                </AttributeLabel>
                                <AzureApplicationDeputies data={data} />
                            </Attribute>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )

    const applicationDetails = (
        <Fragment>
            <Box className={classes.basicInfo}>
                <Attribute>
                    <AttributeLabel>{t('Common_FriendlyName')}</AttributeLabel>
                    <AttributeValue>{data && data.friendlyName}</AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>{t('Common_TechnicalName')}</AttributeLabel>
                    <AttributeValue>{data && data.name}</AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>{t('Common_Description')}</AttributeLabel>
                    <AttributeValue>{data && data.description}</AttributeValue>
                </Attribute>
            </Box>
        </Fragment>
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                <Box className={classes.section}>{applicationDetails}</Box>
                {data && !data.isAzureApplication && (
                    <Box className={classes.section}>
                        <Attribute>
                            <AttributeLabel>
                                {' '}
                                {t('Common_Owners')}
                            </AttributeLabel>
                            <Owners applicationId={applicationId} />
                        </Attribute>
                    </Box>
                )}
                {showAzureApplicationDetails && (
                    <Box className={classes.section}>
                        {azureApplicationDetails}
                    </Box>
                )}
            </Box>
        </Fragment>
    )

    const renderActionContent = () => (
        <Fragment>
            <Box className={classes.backButton}>
                <Button
                    onClick={() => {
                        query.delete('applicationId')
                        history.push(
                            `${appConfig.APP_SUBPATH}/applications`,
                            query.toString(),
                        )
                    }}
                >
                    {t('Common_BackTo', { text: t('Common_Applications') })}
                </Button>
            </Box>

            {data.instructions && (
                <Box className={classes.moreInfoButton}>
                    <Instructions
                        title={data.friendlyName}
                        instructions={data.instructions}
                    />
                </Box>
            )}

            <Box className={classes.actionDropdown}>
                <Actions application={data} />
            </Box>
        </Fragment>
    )

    return (
        <Paper className={classes.root}>
            {isLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box className={classes.content}>
                        <Paper className={classes.imgContainer}>
                            <img
                                alt={data?.friendlyName}
                                src={
                                    isNilOrEmpty(data.applicationLogoImageUrl)
                                        ? getFullImageUrl(
                                              '/webcdn/Images/AppLogos/Genric-1.png',
                                          )
                                        : getFullImageUrl(
                                              data?.applicationLogoImageUrl,
                                          )
                                }
                            />
                        </Paper>

                        <Box className={classes.section}>{renderContent()}</Box>

                        <Box className={classes.actionsContainer}>
                            {renderActionContent()}
                        </Box>
                    </Box>
                    <Collapse in={checked} collapsedHeight={0}>
                        <Box className={classes.collapsibleContainer}>
                            {renderContent()}
                        </Box>
                    </Collapse>
                    <Box className={classes.showMoreButton}>
                        <button onClick={handleChange}>
                            {checked ? t('Common_Hide') : t('Common_ShowMore')}
                            <ArrowIcon direction={checked ? 'up' : 'down'} />
                        </button>
                    </Box>
                </Fragment>
            )}
        </Paper>
    )
}
