import React, { Fragment } from 'react'
import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useApplicationRoleOwnersAndApprovers } from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { LocalSensitiveFunctions } from './LocalSensitiveFunctions'
import useSubcomponents from 'useSubcomponents'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const MoreInformationSection = ({ applicationRole }: any) => {
    const { t } = useTranslation()

    const { hasAccessToLocalSensitiveFunctionsGrid } = useSubcomponents()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const {
        data: ownersAndApprovers,
        isLoading: ownersAndApproversLoading,
    } = useApplicationRoleOwnersAndApprovers(applicationRole.id)

    return (
        <Fragment>
            {ownersAndApproversLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('ApplicationRoles_Owners')}
                            value={
                                <PeopleList list={ownersAndApprovers.owners} />
                            }
                            orientation={attributeOrientation}
                        />
                        <ItemDetails.Attribute
                            label={t('Common_Approvers')}
                            value={
                                <PeopleList
                                    list={ownersAndApprovers.approvers}
                                />
                            }
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>

                    {hasAccessToLocalSensitiveFunctionsGrid && (
                        <LocalSensitiveFunctions
                            applicationRole={applicationRole}
                            protectSubcomponent={hasAccessToLocalSensitiveFunctionsGrid}
                        />
                    )}
                </ItemDetails.AttributesContainer>
            )}
        </Fragment>
    )
}
