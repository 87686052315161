import React, { useState } from 'react'
import {
    Box,
    InputBase,
    styled,
    Typography,
    withStyles,
} from '@material-ui/core'
import { Pagination } from 'packages/eid-ui'
import { useApplicationRoleLocalFunctions } from 'hooks'
import { FunctionsList } from 'components'
import { useDebounce, useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { defaultDataProtectedSubcomponent } from 'utils'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#ffffff !important',
        border: 'solid 1px #ebebed',

        fontSize: 16,
        padding: '10px 12px',
        '&:focus': {
            boxShadow: ` 0 2px 4px 0 ${theme.palette.primary.light}`,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
        },
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))(InputBase)

const take = 10

export const LocalSensitiveFunctions = ({
    applicationRole,
    protectSubcomponent = defaultDataProtectedSubcomponent,
}: any) => {
    const { t } = useTranslation()

    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = React.useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }
    const handlePageSelection = (value: any) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value: any) => {
        setPage(1)
        setPerPageItems(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event: any) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useApplicationRoleLocalFunctions(
        applicationRole.id,
        (page - 1) * perPageItems,
        perPageItems,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const localFunctions = latestData ? latestData.data : undefined

    const localFunctionsLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    return (
        <>
            <PaddedDiv
                data-protectedsubcomponent={protectSubcomponent}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px 31px',
                }}
            >
                <Box width="50%">
                    <Typography
                        style={{
                            fontSize: '12px',
                            lineHeight: 1.33,
                            color: '#9b9b9b',
                            wordBreak: 'break-word',
                            textTransform: 'uppercase',
                            minWidth: '80px',
                            fontWeight: 'bold',
                        }}
                    >
                        {t('Common_LocalSensitiveFunctions')}
                    </Typography>
                </Box>

                <Box minWidth="130px">
                    <StyledInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </PaddedDiv>
            <Box overflow="auto">
                <FunctionsList
                    data={localFunctions}
                    loading={localFunctionsLoading}
                />

                {!localFunctionsLoading && numberOfPages > 1 && (
                    <Box
                        padding={!isSmallScreen ? '20px 0px' : '16px'}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Pagination
                            count={numberOfPages}
                            size={!isSmallScreen ? 'large' : 'small'}
                            page={page}
                            onChange={handlePageChange}
                            totalCount={latestData?.totalCount}
                            onPageSelection={handlePageSelection}
                            itemsPerPage={perPageItems}
                            onItemsPerPageChange={handleItemsPerPageChange}
                            showPageSelection={true}
                            showItemsPerPageSelection
                            showCountStat
                        />
                    </Box>
                )}
            </Box>
        </>
    )
}
