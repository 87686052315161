const cartHelpers = {
    computerToCartItem: ({
        targetPerson,
        assignmentType,
        computer,
        assignmentStatus,
        timeConstrained = true,
        startDateUtc = null,
        endDateUtc = null,
        computerAdditionalProperties = null,
        isPreApproved = false,
    }) => {
        const itemToAdd = {
            assignmentType,
            requestableResourceId: computer.id,
            resource_FriendlyName: computer.friendlyName,
            resourceTypeId: computer.resourceTypeId,
            resourceType_Name: computer.resourceTypeName,
            resourceTypeRoleId:
                assignmentStatus?.resourceAssignment.resourceAssignmentId,
            resourceTypeRole_FriendlyName:
                assignmentStatus?.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
            isPreApproved,
            computerAdditionalProperties: computerAdditionalProperties,
        }

        if (timeConstrained) {
            if (!endDateUtc) {
                return null
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },

    sharedCredentialToCartItem: ({
        targetPerson,
        assignmentType,
        credential,
        assignmentStatus,
        isPreApproved,
        timeConstrained = true,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        // if (!assignmentStatus) return null

        const itemToAdd = {
            assignmentType,
            requestableResourceId: credential.id,
            resource_FriendlyName: credential.friendlyName,
            resourceTypeId: credential.resourceTypeId,
            resourceType_Name: credential.resourceTypeName,
            // resourceTypeRoleId:
            //     assignmentStatus?.resourceAssignment.resourceAssignmentId,
            // resourceTypeRole_FriendlyName:
            //     assignmentStatus?.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
            isPreApproved: isPreApproved ? true : false,
        }

        if (timeConstrained) {
            if (!endDateUtc) {
                return null
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },
    sharedFolderToCartItem: ({
        targetPerson,
        assignmentType,
        sharedFolder,
        assignmentStatus,
        timeConstrained = false,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        if (!assignmentStatus) return null

        const itemToAdd = {
            assignmentType,
            requestableResourceId: sharedFolder.id,
            resource_FriendlyName: sharedFolder.friendlyName,
            resourceTypeId: assignmentStatus.resourceAssignment.resourceTypeId,
            resourceType_Name: sharedFolder.resourceTypeName,
            resourceTypeRoleId:
                assignmentStatus.resourceAssignment.resourceAssignmentId,
            resourceTypeRole_FriendlyName:
                assignmentStatus.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
        }

        if (timeConstrained) {
            if (!endDateUtc) {
                return null
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },

    mailboxToCartItem: ({
        targetPerson,
        assignmentType,
        mailbox,
        assignmentStatus,
        timebased = false,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        if (!assignmentStatus) return null

        const itemToAdd = {
            assignmentType,
            requestableResourceId: mailbox.id,
            resource_FriendlyName: mailbox.friendlyName,
            resourceTypeId: assignmentStatus.resourceAssignment.resourceTypeId,
            resourceType_Name: mailbox.resourceTypeName,
            resourceTypeRoleId:
                assignmentStatus.resourceAssignment.resourceAssignmentId,
            resourceTypeRole_FriendlyName:
                assignmentStatus.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
        }

        if (timebased) {
            if (!endDateUtc) {
                return null
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },

    appRoleToCartItem: ({
        targetPerson,
        assignmentType,
        applicationRole,
        assignmentStatus,
        timeConstrained = false,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        if (!assignmentStatus) return undefined

        const itemToAdd = {
            assignmentType,
            requestableResourceId: applicationRole.id,
            resource_FriendlyName: applicationRole.friendlyName,
            resourceTypeId: assignmentStatus.resourceAssignment.resourceTypeId,
            resourceType_Name: applicationRole.resourceTypeName,
            resourceTypeRoleId:
                assignmentStatus.resourceAssignment.resourceAssignmentId,
            resourceTypeRole_FriendlyName:
                assignmentStatus.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
        }

        if (timeConstrained) {
            if (!endDateUtc) {
                return undefined
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },

    azAdminRoleToCartItem: ({
        targetPerson,
        assignmentType,
        azureAdminRole,
        assignmentStatus,
        timeConstrained = false,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        if (!assignmentStatus) return null

        const itemToAdd = {
            assignmentType,
            requestableResourceId: azureAdminRole.id,
            resource_FriendlyName: azureAdminRole.friendlyName,
            resourceTypeId: assignmentStatus.resourceAssignment.resourceTypeId,
            resourceType_Name: azureAdminRole.resourceTypeName,
            resourceTypeRoleId:
                assignmentStatus.resourceAssignment.resourceAssignmentId,
            resourceTypeRole_FriendlyName:
                assignmentStatus.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
        }

        if (timeConstrained) {
            if (!endDateUtc) {
                return null
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },

    azLicenseToCartItem: ({
        targetPerson,
        assignmentType,
        azureLicense,
        assignmentStatus,
        timeConstrained = false,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        if (!assignmentStatus) return null

        const itemToAdd = {
            assignmentType,
            requestableResourceId: azureLicense.id,
            resource_FriendlyName: azureLicense.friendlyName,
            resourceTypeId: assignmentStatus.resourceAssignment.resourceTypeId,
            resourceType_Name: azureLicense.resourceTypeName,
            resourceTypeRoleId:
                assignmentStatus.resourceAssignment.resourceAssignmentId,
            resourceTypeRole_FriendlyName:
                assignmentStatus.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
        }

        if (timeConstrained) {
            if (!endDateUtc) {
                return null
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },

    azRbacRoleToCartItem: ({
        targetPerson,
        assignmentType,
        azureRbacRole,
        assignmentStatus,
        location,
        timeConstrained = false,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        if (!assignmentStatus || !location) return null

        const itemToAdd = {
            assignmentType,
            requestableResourceId: azureRbacRole.id,
            resource_FriendlyName: azureRbacRole.friendlyName,
            resourceTypeId: assignmentStatus.resourceAssignment.resourceTypeId,
            resourceType_Name: azureRbacRole.resourceTypeName,
            resourceTypeRoleId:
                assignmentStatus.resourceAssignment.resourceAssignmentId,
            resourceTypeRole_FriendlyName:
                assignmentStatus.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
            locationId: location.id,
            location_FriendlyName: location.friendlyName,
        }

        if (timeConstrained) {
            if (!endDateUtc) {
                return null
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },

    businessRoleToCartItem: ({
        targetPerson,
        assignmentType,
        businessRole,
        differentiationValue,
        assignmentStatus,
        timeConstrained = false,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        if (!differentiationValue || !assignmentStatus) return null
        const itemToAdd = {
            assignmentType,
            requestableResourceId: businessRole.id,
            resource_FriendlyName: businessRole.friendlyName,
            locationId: differentiationValue.id,
            location_FriendlyName: differentiationValue.friendlyName,
            resourceTypeId: assignmentStatus.resourceAssignment.resourceTypeId,
            resourceType_Name: businessRole.resourceTypeName,
            resourceTypeRoleId:
                assignmentStatus.resourceAssignment.resourceAssignmentId,
            resourceTypeRole_FriendlyName:
                assignmentStatus.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
        }

        if (timeConstrained) {
            if (!endDateUtc) {
                return null
            }

            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },

    managementRoleToCartItem: ({
        targetPerson,
        assignmentType,
        managementRole,
        assignmentStatus,
        timeConstrained = false,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        if (!assignmentStatus) return null

        const itemToAdd = {
            assignmentType,
            requestableResourceId: managementRole.id,
            resource_FriendlyName: managementRole.friendlyName,
            resourceTypeId: assignmentStatus.resourceAssignment.resourceTypeId,
            resourceType_Name: managementRole.resourceTypeName,
            resourceTypeRoleId:
                assignmentStatus.resourceAssignment.resourceAssignmentId,
            resourceTypeRole_FriendlyName:
                assignmentStatus.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
        }

        if (timeConstrained) {
            if (!endDateUtc) {
                return null
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },

    accessItemToCartItem: (
        resourceType,
        targetPerson,
        item,
        assignmentType,
        timeConstraintDetails = null,
    ) => {
        if (resourceType === 'ApplicationRoles') {
            return cartHelpers.appRoleToCartItem({
                targetPerson,
                assignmentType,
                applicationRole: item.resource,
                assignmentStatus: item.assignment,
                ...timeConstraintDetails,
            })
        } else if (resourceType === 'AzureAdminRoles') {
            return cartHelpers.azAdminRoleToCartItem({
                targetPerson,
                assignmentType,
                azureAdminRole: item.resource,
                assignmentStatus: item.assignment,
                ...timeConstraintDetails,
            })
        } else if (resourceType === 'AzureLicenses') {
            return cartHelpers.azLicenseToCartItem({
                targetPerson,
                assignmentType,
                azureLicense: item.resource,
                assignmentStatus: item.assignment,
                ...timeConstraintDetails,
            })
        } else if (resourceType === 'AzureRbacRoles') {
            const location = {
                id: item.assignment.resourceAssignment.locationId,
                friendlyName:
                    item.assignment.resourceAssignment.locationFriendlyName,
            }

            return cartHelpers.azRbacRoleToCartItem({
                targetPerson,
                assignmentType,
                azureRbacRole: item.resource,
                location,
                assignmentStatus: item.assignment,
                ...timeConstraintDetails,
            })
        } else if (resourceType === 'BusinessRoles') {
            const differentiationValue = {
                id: item.assignment.resourceAssignment.locationId,
                friendlyName:
                    item.assignment.resourceAssignment.locationFriendlyName,
            }
            return cartHelpers.businessRoleToCartItem({
                targetPerson,
                assignmentType,
                businessRole: item.resource,
                differentiationValue,
                assignmentStatus: item.assignment,
                ...timeConstraintDetails,
            })
        } else if (resourceType === 'ManagementRoles') {
            return cartHelpers.managementRoleToCartItem({
                targetPerson,
                assignmentType,
                managementRole: item.resource,
                assignmentStatus: item.assignment,
                ...timeConstraintDetails,
            })
        } else if (resourceType === 'SharedFolders') {
            return cartHelpers.sharedFolderToCartItem({
                targetPerson,
                assignmentType,
                sharedFolder: item.resource,
                assignmentStatus: item.assignment,
                ...timeConstraintDetails,
            })
        } else if (resourceType === 'Applications') {
            if (item?.resource.resourceTypeName === 'AzureRole') {
                item.resource = {
                    ...item.resource,
                    resourceTypeName: 'RoleDefinition',
                }
            }
            return cartHelpers.applicationsAccessToCartItem({
                targetPerson,
                assignmentType,
                application: item?.resource,
                assignmentStatus: item?.assignment,
                ...timeConstraintDetails,
            })
        } else if (resourceType === 'Mailboxes') {
            return cartHelpers.mailboxToCartItem({
                targetPerson,
                assignmentType,
                mailbox: item.resource,
                assignmentStatus: item.assignment,
                ...timeConstraintDetails,
            })
        } else if (resourceType === 'Computers') {
            return cartHelpers.computerToCartItem({
                targetPerson,
                assignmentType,
                computer: item.resource,
                assignmentStatus: item.assignment,
                ...timeConstraintDetails,
            })
        }
    },

    applicationsAccessToCartItem: ({
        targetPerson,
        assignmentType,
        application,
        assignmentStatus,
        timebased = false,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        if (!assignmentStatus) return null

        const itemToAdd = {
            assignmentType,
            requestableResourceId: application.id,
            resource_FriendlyName: application.friendlyName,
            resourceTypeId: application.resourceTypeId,
            resourceType_Name: application.resourceTypeName,
            resourceTypeRoleId:
                assignmentStatus.resourceAssignment.id,
            resourceTypeRole_FriendlyName:
                assignmentStatus.resourceAssignment.friendlyName,
            targetPersonId: targetPerson.id,
        }

        if (timebased) {
            if (!endDateUtc) {
                return null
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },
    applicationsAccessToRolesToCartItem: ({
        targetPerson,
        assignmentType,
        application,
        assignmentStatus,
        timebased = false,
        startDateUtc = null,
        endDateUtc = null,
    }) => {
        if (!assignmentStatus) return null

        const itemToAdd = {
            assignmentType,
            requestableResourceId: assignmentStatus?.resourceAssignment?.id,
            resource_FriendlyName:
                assignmentStatus?.resourceAssignment?.friendlyName,
            resourceTypeId:
                assignmentStatus?.resourceAssignment?.resourceTypeId,
            resourceType_Name:
                assignmentStatus?.resourceAssignment?.resourceTypeName,
            resourceTypeRoleId: application.id,
            resourceTypeRole_FriendlyName: application.friendlyName,
            targetPersonId: targetPerson.id,
        }

        if (timebased) {
            if (!endDateUtc) {
                return null
            }
            itemToAdd.timeConstraintActive = true
            itemToAdd.startDateUtc = startDateUtc
            itemToAdd.endDateUtc = endDateUtc
        } else {
            if (startDateUtc) {
                itemToAdd.startDateUtc = startDateUtc
            }
            if (endDateUtc) {
                itemToAdd.endDateUtc = endDateUtc
            }
            itemToAdd.timeConstraintActive =
                itemToAdd.endDateUtc && itemToAdd.startDateUtc ? true : false
        }

        return itemToAdd
    },
}

export default cartHelpers
