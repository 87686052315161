import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'

const CREDENTIAL_KEY_PREFIX = 'CREDENTIALS_HISTORY'

export const useCredentialHistory = (id) => {
    const callApi = useAxios()
    return useQuery(
        [CREDENTIAL_KEY_PREFIX, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Credentials/history/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}
export const useCheckOutCredentialsRecords = (CredentialId, queryData) => {
    const callApi = useAxios()
    const endpoint = `api/Credentials/checkOutCredentialsRecordsbri`
    return useApiWithInfiniteScroll(
        [
            CREDENTIAL_KEY_PREFIX,
            'CHECKOUT_CREDENTIAL_RECORDS',
            queryData,
            CredentialId,
        ],

        (skip) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take: 15,
                    CredentialId,
                },
            })
        },
        undefined,
        15,
    )
}
