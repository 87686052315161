import { Box, Button, makeStyles } from '@material-ui/core'
import { useAppState } from 'appContext'
import ComputerConnectBtn from 'components/DynamicComponents/ComputerConnectButton'
import { PasswordModal } from 'components/PasswordModal'
import { Icon } from 'packages/eid-icons'
import MasterLockOverlay from 'packages/eid-ui/MasterLockOverlay'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useMenuStyles = makeStyles((theme) => ({
    root: {
        padding: '0 10px',
    },
    innerroot: {
        padding: '20px 16px 9px',
        borderRadius: '8px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        background: '#fff',
        marginBottom: '20px',
    },
    cardhead: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    cardimg: {
        width: '24px',
        height: '24px',
    },
    cardheading: {
        fontSize: '14px',
        fontWeight: 600,
        color: '#000',
        marginLeft: '16px',
    },
    cardbody: {},
    cardname: {
        fontSize: '12px',
        color: '#9b9b9b',
        fontWeight: 400,
        marginBottom: '10px',
    },
    cardinfo: {
        fontSize: '13px',
        color: '#000',
        fontWeight: 400,
        marginBottom: '16px',
    },
    detalsbtn: {
        padding: '7px 15px',
        border: `solid 1px ${theme.palette.secondary.main}`,
        fontSize: '14px',
        color: theme.palette.secondary.main,
        height: '30px',
        marginBottom: '15px',
        marginTop: '14px',
    },
    requestconnectbtn: {
        ' & > div div': {
            maxWidth: '100px',
        },
        ' & span svg:nth-last-child(1)': {
            marginTop: '10px',
        },
        ' & button': {
            padding: '0',

            ' & div': {
                maxWidth: 'auto !important',
            },
        },
    },
    customlockbtn: {
        maxWidth: '200px',
    },
}))

export interface ComputerCardViewProps {
    item: any
    btnLabel: string
}

export const ComputerCardView: FC<ComputerCardViewProps> = (props) => {
    const { item, btnLabel } = props
    const { t } = useTranslation()
    const classes = useMenuStyles()
    const [{ masterPassword }]: any = useAppState()
    const [open, setOpen] = useState<string>('')

    const handleMasterUnlock = () => {
        setOpen('enterPassword')
    }
    return (
        <Box className={classes.root}>
            {!masterPassword && (
                <PasswordModal
                    showDefaultContent={false}
                    open={open}
                    setOpen={setOpen}
                />
            )}
            <Box className={classes.innerroot}>
                <Box className={classes.cardhead}>
                    <Box className={classes.cardimg}>
                        {/* TODO: Need to make it dynamic as per api response */}
                        <Icon name="WindowBlue" />
                    </Box>
                    <Box className={classes.cardheading}>Friendly Name</Box>
                </Box>
                <Box className={classes.cardbody}>
                    <Box className={classes.cardname}>DNS Name</Box>
                    <Box className={classes.cardinfo}>DNS Name</Box>
                    <Box className={classes.customlockbtn}>
                        <MasterLockOverlay
                            btnLabel={t('Common_Unlock')}
                            showMasterLock={!masterPassword}
                            handleMasterUnlock={handleMasterUnlock}
                        >
                            <Box className={classes.cardname}>
                                Infrastructure
                            </Box>
                            <Box className={classes.cardinfo}>
                                <Box className={classes.requestconnectbtn}>
                                    <ComputerConnectBtn
                                        parentStyle={{}}
                                        showPrefixIcon={false}
                                        data={item}
                                        drawerView={false}
                                        showCalenderIcon={true}
                                    />
                                </Box>
                            </Box>
                        </MasterLockOverlay>
                    </Box>
                    <Box className={classes.cardname}>Environment</Box>
                    <Box className={classes.cardinfo}>Environment</Box>
                    <Box className={classes.cardname}>Type</Box>
                    <Box className={classes.cardinfo}>Windows 10 Pro x64</Box>

                    <Button className={classes.detalsbtn}>{btnLabel}</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ComputerCardView
