import { Fragment } from 'react'
import {
    Box,
    Checkbox,
    Collapse,
    makeStyles,
    TableCell,
    TableRow,
    TextField,
    useTheme,
} from '@material-ui/core'
import { TimeDurationSelector } from 'components'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import { ToggleSwitch, Tooltip } from 'packages/eid-ui'
import { useAccessRequestPolicy } from 'hooks'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'
import {
    NativeDatePicker,
    NativeTimePicker,
} from 'packages/eid-ui/DateTimePickers'
import classNames from 'classnames'

const useStyles = makeStyles({
    expansionRowActive: {
        borderBottom: 'none',
        borderTop: 'none',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    expansionRow: {
        border: 'none',
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableRowExpanded: {
        border: ' solid 1px #efeff1',
        borderBottom: 'none',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    actionCell: {
        width: '170px',
        maxWidth: '170px',
    },
    expansionCell: {
        padding: '0px',
        border: 'none',
    },
    expansionCellActive: {
        padding: '14px 0px',
        border: 'none',
    },
    infoIcon: {
        marginLeft: '16px',
        width: '12px',
    },
    actionContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        maxWidth: '100px',
    },
})

const tooltipStyles = {
    minWidth: '376px',
    boxShadow:
        '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    borderRadius: '5px',
    padding: '24px',
    marginRight: '24px',
}

const selectedIcon = (
    <Box
        width="33px"
        height="33px"
        display="flex"
        justifyContent="center"
        alignItems="center"
    >
        <Icon name="Checked" />
    </Box>
)

export const SuggestedRole = ({
    headings,
    attributes,
    item,
    isTimeConstrained,
    getSelectedItem,
    dispatch,
    isItemAlreadyInCart,
    isSelected,
    targetPerson,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()

    const accessRequestPolicy = useAccessRequestPolicy(item.requestPolicyId)

    const renderCheckbox = () => {
        if (item.isAssigned) {
            return (
                <TableCell className={classes.tableCell} padding="checkbox">
                    {selectedIcon}
                </TableCell>
            )
        } else if (!accessRequestPolicy.isRequestAllowed) {
            return (
                <TableCell className={classes.tableCell} padding="checkbox">
                    <Fragment />
                </TableCell>
            )
        } else {
            return (
                <TableCell className={classes.tableCell} padding="checkbox">
                    {!isItemAlreadyInCart(item.id) && (
                        <Checkbox
                            disabled={isItemAlreadyInCart(item.id)}
                            icon={<Icon name="CheckBox" />}
                            checkedIcon={
                                <Icon
                                    name="CheckedBox"
                                    color={theme.palette.primary.main}
                                />
                            }
                            checked={isSelected(item.id)}
                            onClick={(e) => {
                                if (e.target.checked) {
                                    dispatch({
                                        type: 'ADD_ITEM',
                                        payload: {
                                            item,
                                            targetPersonId: targetPerson.id,
                                            policy: accessRequestPolicy,
                                        },
                                    })
                                } else {
                                    dispatch({
                                        type: 'REMOVE_ITEM',
                                        payload: item,
                                    })
                                }
                            }}
                        />
                    )}
                </TableCell>
            )
        }
    }

    const selectedItem = getSelectedItem(item.id)

    const violatesPolicy =
        accessRequestPolicy?.maximumValueInMinutes &&
        selectedItem?.endDateUtc?.isAfter(
            selectedItem?.startDateUtc
                .clone()
                .add(accessRequestPolicy?.maximumValueInMinutes, 'minutes'),
        )

    const isDateInvalid =
        isNilOrEmpty(selectedItem?.endDateUtc) ||
        selectedItem?.startDateUtc?.isAfter(selectedItem?.endDateUtc) ||
        violatesPolicy

    let maxEndDate
    if (
        accessRequestPolicy.isTimeConstrained &&
        accessRequestPolicy.maximumValueInMinutes
    ) {
        maxEndDate = selectedItem?.startDateUtc
            ?.clone()
            ?.add(accessRequestPolicy.maximumValueInMinutes, 'minutes')
    }

    let validationMessage = ''
    if (violatesPolicy) {
        validationMessage = t('Common_PolicyAllowsMaxDurationInMinError', {
            count: accessRequestPolicy.maximumValueInMinutes,
        })
    }

    return (
        <Fragment>
            <Tooltip
                title={
                    isItemAlreadyInCart(item.id)
                        ? t('Common_ItemAlreadyInCart')
                        : ''
                }
            >
                <TableRow
                    className={
                        isTimeConstrained(item)
                            ? classes.tableRowExpanded
                            : classes.tableRow
                    }
                >
                    {renderCheckbox()}

                    {attributes.map((a, index) => (
                        <TableCell
                            key={`${item.id}${index}`}
                            className={classes.tableCell}
                            style={a.style}
                            data-protectedsubcomponent={
                                a?.requireAccess?.control || ''
                            }
                        >
                            {a.resolve
                                ? a.resolve(item)
                                : !isNilOrEmpty(item[a.name])
                                ? item[a.name]
                                : '-'}
                        </TableCell>
                    ))}
                    <TableCell
                        className={classNames(
                            classes.tableCell,
                            classes.actionCell,
                        )}
                    >
                        <Box className={classes.actionContent}>
                            <Tooltip
                                title={
                                    !isSelected(item.id)
                                        ? t('Common_SelectItem')
                                        : ''
                                }
                            >
                                <span>
                                    {!accessRequestPolicy.isTimeConstrained &&
                                        !item.isAssigned && (
                                            <ToggleSwitch
                                                disabled={!isSelected(item.id)}
                                                onChange={(e) => {
                                                    dispatch({
                                                        type:
                                                            'SET_TIME_CONSTRAINT',
                                                        payload: {
                                                            item,
                                                            timeConstraintActive:
                                                                e.target
                                                                    .checked,
                                                            policy: accessRequestPolicy,
                                                        },
                                                    })
                                                }}
                                                value={isTimeConstrained(item)}
                                            />
                                        )}
                                </span>
                            </Tooltip>

                            {accessRequestPolicy && (
                                <Tooltip
                                    color="#ffffff"
                                    tooltipStyles={tooltipStyles}
                                    interactive={true}
                                    title={
                                        <AccessRequestPolicyDetails
                                            policy={accessRequestPolicy}
                                        />
                                    }
                                    placement="top"
                                    arrow={false}
                                >
                                    <span className={classes.infoIcon}>
                                        <Icon
                                            name="Info"
                                            color="#307fc1"
                                            width="12px"
                                            height="12px"
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                    </TableCell>
                </TableRow>
            </Tooltip>
            <TableRow
                hover={false}
                className={
                    isTimeConstrained(item)
                        ? classes.expansionRowActive
                        : classes.expansionRow
                }
            >
                <TableCell
                    colSpan={headings.length + 2}
                    className={
                        isTimeConstrained(item)
                            ? classes.expansionCellActive
                            : classes.expansionCell
                    }
                >
                    <Collapse
                        in={isTimeConstrained(item)}
                        timeout="auto"
                        unmountOnExit
                    >
                        <TimeDurationSelector
                            rootStyles={{
                                backgroundColor: '#f7f7f8',
                                width: '95%',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                margin: 'auto',
                                boxShadow: 'none',
                            }}
                            startDateTimeSelector={
                                <Box display="flex" width="220px">
                                    <NativeDatePicker
                                        minDate={moment(new Date()).format(
                                            'YYYY-MM-DD',
                                        )}
                                        value={
                                            getSelectedItem(item.id)
                                                ?.startDateUtc?.clone()
                                                .local()
                                                .format('YYYY-MM-DD') ?? null
                                        }
                                        handleChange={(e) => {
                                            let date = moment()
                                            if (!isNilOrEmpty(e)) {
                                                date = moment(e)
                                            }
                                            const dateToSet = date.clone()
                                            const isFuture =
                                                date.diff(moment()) > 0
                                            if (isFuture) {
                                                dateToSet.startOf('day')
                                            } else {
                                                const now = moment()
                                                dateToSet.set({
                                                    hour: now.hour(),
                                                    minute: now.minute(),
                                                    second: 0,
                                                })
                                            }

                                            dateToSet.utc()
                                            dispatch({
                                                type: 'SET_START_DATE',
                                                payload: {
                                                    id: item.id,
                                                    startDateUtc: dateToSet,
                                                    policy: accessRequestPolicy,
                                                },
                                            })
                                        }}
                                        pickerStyles={{ top: '22px' }}
                                    >
                                        <TextField
                                            type="text"
                                            value={
                                                getSelectedItem(item.id)
                                                    ?.startDateUtc?.clone()
                                                    .local()
                                                    .format('L') ?? '-- -- ----'
                                            }
                                            label={t('Common_StartDate')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </NativeDatePicker>
                                    <NativeTimePicker
                                        value={
                                            getSelectedItem(item.id)
                                                ?.startDateUtc?.clone()
                                                .local()
                                                .format('HH:mm') ?? null
                                        }
                                        handleChange={(e) => {
                                            const time = moment(e, 'HH:mm')
                                            const dateToSet = getSelectedItem(
                                                item.id,
                                            )
                                                ?.startDateUtc?.clone()
                                                ?.local()

                                            dateToSet.set({
                                                hour: time.hour(),
                                                minute: time.minute(),
                                                second: 0,
                                            })

                                            dateToSet.utc()
                                            dispatch({
                                                type: 'SET_START_DATE',
                                                payload: {
                                                    id: item.id,
                                                    startDateUtc: dateToSet,
                                                    policy: accessRequestPolicy,
                                                },
                                            })
                                        }}
                                        pickerStyles={{ top: '22px' }}
                                    >
                                        <TextField
                                            label={t('Common_StartTime')}
                                            type="text"
                                            value={
                                                getSelectedItem(item.id)
                                                    ?.startDateUtc?.clone()
                                                    .local()
                                                    .format('LT') ?? '-- -- --'
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </NativeTimePicker>
                                </Box>
                            }
                            endDateTimeSelector={
                                <Box
                                    display="flex"
                                    width="220px"
                                    style={{
                                        borderBottom: isDateInvalid
                                            ? '1px solid red'
                                            : '',
                                    }}
                                >
                                    <NativeDatePicker
                                        minDate={
                                            getSelectedItem(item.id)
                                                ?.startDateUtc?.clone()
                                                ?.local()
                                                .format('YYYY-MM-DD') ?? null
                                        }
                                        maxDate={maxEndDate
                                            ?.clone()
                                            .local()
                                            .format('YYYY-MM-DD')}
                                        value={
                                            getSelectedItem(item.id)
                                                ?.endDateUtc?.clone()
                                                ?.local()
                                                .format('YYYY-MM-DD') ?? null
                                        }
                                        handleChange={(e) => {
                                            let dateToSet
                                            if (isNilOrEmpty(e)) {
                                                dateToSet = null
                                            } else {
                                                const date = moment(e)
                                                dateToSet = date
                                                    .clone()
                                                    .endOf('day')
                                                    .utc()
                                            }
                                            dispatch({
                                                type: 'SET_END_DATE',
                                                payload: {
                                                    id: item.id,
                                                    endDateUtc: dateToSet,
                                                },
                                            })
                                        }}
                                        disabled={
                                            accessRequestPolicy.isTimeConstrained &&
                                            !accessRequestPolicy.isEndDateSelectable
                                        }
                                        pickerStyles={{ top: '22px' }}
                                    >
                                        <TextField
                                            type="text"
                                            value={
                                                getSelectedItem(item.id)
                                                    ?.endDateUtc?.clone()
                                                    ?.local()
                                                    .format('L') ?? '-- -- ----'
                                            }
                                            label={t('Common_EndDate')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </NativeDatePicker>
                                    <NativeTimePicker
                                        value={
                                            getSelectedItem(item.id)
                                                ?.endDateUtc?.clone()
                                                ?.local()
                                                .format('HH:mm') ?? null
                                        }
                                        handleChange={(e) => {
                                            const time = moment(e, 'HH:mm')
                                            let dateToSet
                                            if (
                                                !isNilOrEmpty(
                                                    getSelectedItem(item.id)
                                                        ?.endDateUtc,
                                                )
                                            ) {
                                                dateToSet = getSelectedItem(
                                                    item.id,
                                                )
                                                    ?.endDateUtc?.clone()
                                                    ?.local()
                                            } else {
                                                dateToSet = moment()
                                            }

                                            dateToSet.set({
                                                hour: time.hour(),
                                                minute: time.minute(),
                                                second: 0,
                                            })

                                            dateToSet.utc()
                                            dispatch({
                                                type: 'SET_END_DATE',
                                                payload: {
                                                    id: item.id,
                                                    endDateUtc: dateToSet,
                                                },
                                            })
                                        }}
                                        disabled={
                                            accessRequestPolicy.isTimeConstrained &&
                                            !accessRequestPolicy.isEndDateSelectable
                                        }
                                        pickerStyles={{ top: '22px' }}
                                    >
                                        <TextField
                                            label={t('Common_EndTime')}
                                            type="text"
                                            value={
                                                getSelectedItem(item.id)
                                                    ?.endDateUtc?.clone()
                                                    ?.local()
                                                    .format('LT') ?? null
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </NativeTimePicker>
                                </Box>
                            }
                            validationMessage={validationMessage}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}
