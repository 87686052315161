import { Fragment, useRef, useState } from 'react'
import {
    Box,
    styled,
    withTheme,
    Paper,
    Popover,
    makeStyles,
    useTheme,
} from '@material-ui/core'
import {
    isNilOrEmpty,
    useGetControlsAccess,
    useIsSmallScreen,
    useQuery,
} from 'packages/core'
import {
    AppliedFilters,
    DeleteFilters,
    EidFilter,
    ShoppingFor,
    TargetSystem,
} from 'components'
import useConfiguration from 'useConfiguration'
import { useResourceTypeContext } from 'resourceTypeContext'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import ManageAccessTabs from './../ManageAccess/ManageAccessTabs'
import { useHistory } from 'react-router'
import useDefaultState from 'components/DeleteFilters/useDefaultState'
import { useCurrentPerson, useGuidedShopFilters, useTargetPerson } from 'hooks'
import useSubcomponents from 'useSubcomponents'
import { ButtonV2, Tooltip, WithCloseButton, Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import DomPurify from 'dompurify'
import { useAppState } from 'appContext'
import windowConfig from 'config'
import GenericButton from 'components/Button/GenericButton'

const filters = {
    ShoppingFor: ShoppingFor,
    TargetSystem: TargetSystem,
}

const GridItem = styled(withTheme(Box))((props) => ({
    width: '100%',
    [props.theme.breakpoints.up('md')]: {
        padding: '8px',
    },
}))
const usePopoverStyles = makeStyles({
    paper: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 50,
        overflow: 'hidden',
    },
})
const FiltersButton = ({
    onClick,
    label,
    loadingLabel,
    loading,
    saved,
    disabled,
    icon,
}) => {
    const theme = useTheme()
    const fontColor = saved ? '#ffffff' : '#282828'
    const bgColor = saved ? theme.palette.secondary.main : '#ffffff'
    return (
        <GenericButton
            onClick={disabled ? undefined : onClick}
            hoverColor={'rgba(255, 255, 255, 0.5)'}
            loading={loading}
            color={bgColor}
            loaderColor={theme.palette.secondary.main}
            disabled={disabled}
            rootStylesProp={{
                height: '40px',
                borderRadius: '5px',
                fontSize: '12px',
                backgroundColor: 'rgb(255, 255, 255)',
                minWidth: '110px',
                color: fontColor,
                padding: '6px 4px',
            }}
        >
            {loading ? loadingLabel : label}
            {icon}
        </GenericButton>
    )
}
const SaveFiltersPrompt = styled(WithCloseButton)({
    height: '40px',
    borderRadius: '5px',
    width: '100%',
    display: 'flex',
    padding: '10px 15px',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    fontSize: '12px',
})

const Selectors = ({
    resourceType,
    screen,
    handleSaveFilters = undefined,
    savingFilters = undefined,
    showSaveFiltersButton = true,
    showClearFiltersButton = true,
    showDeleteFiltersButton = true,
    showFilters = true,
}) => {
    const isSmallScreen = useIsSmallScreen()
    const [state, dispatch] = useResourceTypeContext()
    const defaultState = useDefaultState(resourceType)
    const { getResourceType } = useConfiguration()
    const accessControlsNames = useGetControlsAccess().map((c) => c.name)
    const {
        canSeeAtLeastOneFilter,
        canChangeOverridenFilters,
    } = useSubcomponents()
    const currentResourceType = getResourceType(resourceType)
    const anchorRef = useRef(null)
    const { data: currentPerson } = useCurrentPerson()
    const popoverClasses = usePopoverStyles()
    const [targetPerson] = useTargetPerson()
    const [{ appConfig }] = useAppState()

    const shoppingForSomeoneElse = targetPerson.id !== currentPerson.id
    const {
        getGuidedShopFilters,
        clearGuidedShopFilters,
    } = useGuidedShopFilters()
    const [showSaveFiltersTooltip, setShowSaveFiltersTooltip] = useState(
        !shoppingForSomeoneElse && getGuidedShopFilters() !== null,
    )
    const [clearFilters, setClearFilters] = useState(false)
    let filtersToRender = currentResourceType.filters.filter((f) =>
        f.requireAccess
            ? accessControlsNames.indexOf(f.requireAccess.control) >= 0
            : true,
    )
    let selectedTabInfo = null

    const query = useQuery()
    let manageAccessTabs = currentResourceType.manageAccessTabs
    const history = useHistory()
    const selectedTab = query.get('tab')
    const selectedView = query.get('view')

    if (screen === 'RequestAccess') {
        filtersToRender = filtersToRender.filter(
            (f) => f.forRequestAccess === true,
        )
    } else if (screen === 'ManageAccess') {
        filtersToRender = filtersToRender.filter(
            (f) => f.forManageAccess === true,
        )

        // To Check filters for manage access tabs
        if (currentResourceType?.manageAccessTabs) {
            if (selectedTab) {
                selectedTabInfo = currentResourceType.manageAccessTabs.find(
                    (x) => x.path === selectedTab,
                )
            }

            filtersToRender = filtersToRender.filter(
                (fl) => fl[selectedTabInfo?.path],
            )
        }
    } else {
        filtersToRender = []
    }

    const workflowButtonsToRender = currentResourceType.workflows.filter((f) =>
        f.requireAccess
            ? accessControlsNames.indexOf(f.requireAccess.control) >= 0
            : true,
    )
    const staticTypeWorkflows = workflowButtonsToRender.filter(
        (w) => w.type === 'static',
    )

    const { staticWorkflows } = appConfig
    let staticWorkflowsToRender = []
    if (
        staticTypeWorkflows &&
        staticTypeWorkflows.length > 0 &&
        staticWorkflows &&
        staticWorkflows.length > 0
    ) {
        staticTypeWorkflows.forEach((sw) => {
            let wf = staticWorkflows.filter((w) => w.name === sw.name)
            if (wf && wf.length > 0) {
                staticWorkflowsToRender.push({ ...wf[0], ...sw })
            }
        })
    }
    const workflowName = query.get('workflow')
    const hasAccessToParsedHtmlMoreInfo =
        accessControlsNames.indexOf(
            'ITShop-ParsedHtmlMoreInformation-Control',
        ) >= 0

    const isApplicationsPage = Boolean(query.get('selected'))

    if (isApplicationsPage) {
        filtersToRender = filtersToRender.filter(
            (f) => f.name !== 'Applications',
        )
    }

    if (!showFilters) {
        filtersToRender = []
    }
    const canSeeAnyFilter = canSeeAtLeastOneFilter(currentResourceType, screen)
    const clearAllFilters = () => {
        dispatch({
            type: 'SET_MULTIPLE_PROPS',
            payload: {
                ...defaultState,
                showDirectAssignedOnly: true,
                showTimeConstrained: false,
                accountStore: null,
                accountStoreUsageType: null,
                showExpiringAccess: false,
                requestedByPerson: null,
            },
        })
    }

    const resetSorting = () => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'sorting',
                value: {
                    sortOrder: null,
                    sortBy: null,
                },
            },
        })
    }
    const getFilterTitle = (filter) => {
        if (isNilOrEmpty(filter.title)) {
            return null
        } else if (typeof filter.title === 'string') {
            return filter.title
        } else {
            if (screen === 'ManageAccess') {
                return filter.title.manageAccessTitle
            } else {
                return filter.title.requestAccessTitle
            }
        }
    }
    const { t } = useTranslation()
    const parsedHtmlMoreInfo = ReactHtmlParser(
        DomPurify.sanitize(t('MoreInfo_Html'), { ADD_ATTR: ['target'] }),
    )

    return (
        <Fragment>
            {staticWorkflowsToRender.length > 0 &&
                screen !== 'ManageAccess' &&
                staticWorkflowsToRender.map((wf) => {
                    return (
                        <Box
                            padding={isSmallScreen ? '0px 0px 8px 0px' : '8px'}
                        >
                            <EidWorkflowButton
                                title={t(wf.displayName_LocaleKey)}
                                name={wf.requestWorkflowName}
                                path={wf.config?.path}
                                disabled={workflowName}
                            />
                        </Box>
                    )
                })}
            {currentResourceType.showParsedHtmlMoreInfo &&
                hasAccessToParsedHtmlMoreInfo &&
                screen !== 'ManageAccess' && (
                    <Fragment>
                        <Box
                            style={{
                                padding: isSmallScreen
                                    ? '0px 0px 8px 0px'
                                    : '8px',
                            }}
                        >
                            <Card
                                cardTitle={t('Common_More_Information')}
                                icon={
                                    <Icon
                                        name="AdvancedSearch"
                                        color="#959598"
                                    />
                                }
                                collapsible
                                expanded
                                disabled={isApplicationsPage}
                            >
                                <Box
                                    padding="0px 10px"
                                    maxWidth="350px"
                                    overflow="scroll"
                                >
                                    {parsedHtmlMoreInfo}
                                </Box>
                            </Card>
                        </Box>
                    </Fragment>
                )}
            {screen === 'ManageAccess' &&
                manageAccessTabs &&
                manageAccessTabs.length && (
                    <Box
                        style={{
                            padding: isSmallScreen ? '0px 0px 8px 0px' : '8px',
                            width: '100%',
                        }}
                    >
                        <Paper>
                            <ManageAccessTabs
                                tabs={manageAccessTabs}
                                active={
                                    selectedTab
                                        ? selectedTab
                                        : manageAccessTabs.filter(
                                              (t) => t.default,
                                          )[0].path
                                }
                                onChange={(tab) => {
                                    clearAllFilters()
                                    resetSorting()
                                    history.push(
                                        `${history.location.pathname}?tab=${
                                            tab.path
                                        }${selectedView ? '&view=card' : ''}`,
                                    )
                                    dispatch({
                                        type: 'SET_MANAGE_ACCESS_FILTERS',
                                        payload: {
                                            selectedTab: tab,
                                        },
                                    })
                                }}
                            ></ManageAccessTabs>
                        </Paper>
                    </Box>
                )}
            {canSeeAnyFilter &&
                (canChangeOverridenFilters ||
                    windowConfig.ALLOW_FILTERS_OVERRIDE?.toLowerCase() !==
                        'true') && (
                    <Box
                        style={{
                            padding: isSmallScreen ? '0px 0px 8px 0px' : '8px',
                        }}
                    >
                        <AppliedFilters
                            resourceType={currentResourceType.name}
                            clearAll={clearFilters}
                            onClearAllFilters={() => setClearFilters(false)}
                        />
                    </Box>
                )}

            {(canChangeOverridenFilters ||
                windowConfig.ALLOW_FILTERS_OVERRIDE?.toLowerCase() !==
                    'true') && (
                <Box
                    style={{
                        display: 'flex',
                        gap: 10,
                        justifyContent: 'space-between',
                        padding: isSmallScreen ? '0px 0px 16px 0px' : '8px',
                        overflow: 'auto',
                    }}
                    data-protectedsubcomponent={canChangeOverridenFilters}
                >
                    {canSeeAnyFilter && showClearFiltersButton && (
                        // Added below Box to adjust the width automatically
                        <Box>
                            <FiltersButton
                                label={t('Common_ClearAllFilters')}
                                onClick={() => setClearFilters(true)}
                                icon={
                                    <Icon
                                        style={{ margin: '0px 5px' }}
                                        name="Close"
                                        color="#959598"
                                    />
                                }
                            />
                        </Box>
                    )}
                    {canSeeAnyFilter && showSaveFiltersButton && (
                        <div ref={anchorRef}>
                            <Tooltip
                                title={
                                    shoppingForSomeoneElse
                                        ? t(
                                              'Common_SaveFiltersDisableForSomeoneElseHelpText',
                                          )
                                        : t('Common_SaveFiltersHelpText')
                                }
                            >
                                <Box display="flex" justifyContent="flex-end">
                                    <FiltersButton
                                        label={t('Common_SaveFilters')}
                                        loadingLabel={t('Common_Saving')}
                                        loading={savingFilters}
                                        saved={false}
                                        onClick={handleSaveFilters}
                                        disabled={shoppingForSomeoneElse}
                                    />
                                </Box>
                            </Tooltip>
                            {anchorRef.current && (
                                <Popover
                                    open={showSaveFiltersTooltip}
                                    anchorEl={anchorRef.current}
                                    onClose={() => {
                                        clearGuidedShopFilters()
                                        setShowSaveFiltersTooltip(false)
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    classes={popoverClasses}
                                >
                                    <SaveFiltersPrompt
                                        iconColor="#000000"
                                        onClick={() => {
                                            clearGuidedShopFilters()
                                            setShowSaveFiltersTooltip(false)
                                        }}
                                        style={{
                                            color: '#000',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Box marginRight="20px">
                                            {t('Common_SaveFiltersTooltipText')}
                                        </Box>
                                    </SaveFiltersPrompt>
                                </Popover>
                            )}
                        </div>
                    )}
                    {canSeeAnyFilter && showDeleteFiltersButton && (
                        <Box>
                            <DeleteFilters
                                resourceType={currentResourceType.name}
                                showIcon={false}
                            />
                        </Box>
                    )}
                </Box>
            )}
            {workflowButtonsToRender
                .filter((wf) => wf.type !== 'static')
                .map((w) => {
                    return isSmallScreen ? (
                        <Box marginBottom={1}>
                            <EidWorkflowButton {...w} key={w.name} />
                        </Box>
                    ) : (
                        <GridItem
                            data-protectedsubcomponent={
                                w?.requireAccess?.control || ''
                            }
                            key={w.name}
                        >
                            <EidWorkflowButton {...w} />
                        </GridItem>
                    )
                })}

            {filtersToRender
                .sort((a, b) => a.order - b.order)
                .map(({ dynamic, ...f }) => {
                    if (dynamic === true) {
                        if (
                            f.showOnly &&
                            isNilOrEmpty(state[f.showOnly.contextProp])
                        ) {
                            return <Fragment key={f.name} />
                        }

                        return isSmallScreen ? (
                            <Box marginBottom={'16px'}>
                                <EidFilter
                                    {...f}
                                    key={f.name}
                                    resourceType={resourceType}
                                />
                            </Box>
                        ) : (
                            <GridItem
                                data-protectedsubcomponent={
                                    f?.requireAccess?.control || ''
                                }
                                key={f.name}
                            >
                                <EidFilter {...f} resourceType={resourceType} />
                            </GridItem>
                        )
                    } else {
                        const DefinedFilter = filters[f.name]

                        return isSmallScreen ? (
                            <Box marginBottom={'16px'}>
                                <DefinedFilter
                                    key={f.name}
                                    title={getFilterTitle(f)}
                                    resourceType={resourceType}
                                    screen={screen}
                                    tab={selectedTabInfo}
                                />
                            </Box>
                        ) : (
                            <GridItem
                                data-protectedsubcomponent={
                                    f?.requireAccess?.control || ''
                                }
                                key={f.name}
                            >
                                <DefinedFilter
                                    title={getFilterTitle(f)}
                                    resourceType={resourceType}
                                    screen={screen}
                                    tab={selectedTabInfo}
                                />
                            </GridItem>
                        )
                    }
                })}
        </Fragment>
    )
}

export default Selectors
