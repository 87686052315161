import React from 'react'
import { styled } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { Tooltip } from 'packages/eid-ui'
import { getURLTarget } from 'packages/core'

const StyledLink = styled('a')(({ style }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    width: '40px',
    //padding: '5px 16px',
    border: '1px solid #FFB800 ',
    //margin: '0px 8px',
    height: '40px',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
    ...style,
}))

const ViewPendingAccess = ({ label, url, style }) => {
    return (
        <Tooltip title={label} aria-label={label}>
            <StyledLink target={getURLTarget()} href={url} style={style}>
                <Icon name="MyTasks" color="#FFB800" />
            </StyledLink>
        </Tooltip>
    )
}

export default ViewPendingAccess
