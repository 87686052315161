import { preApprovedOnlyFilterColors } from './colors'

const filters = [
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyPreApproved',
        title: 'Common_ShowOnlyPreApproved',
        requireAccess: {
            control: 'ITShop-PreApprovedSharepoint-Control',
        },
        order: 1,
        colorCode: preApprovedOnlyFilterColors,
        label: 'Common_ShowOnlyPreApproved',
        type: 'checkbox',
        contextProp: 'showPreApproved',
        trueValueIndicator: 'Common_ShowOnlyPreApproved',
        default: true,
        queryParam: {
            name: 'showPreApproved',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'SiteType',
        title: 'Common_SiteType',
        requireAccess: {
            control: 'ITShop-SharepointSiteType-Control',
        },
        order: 2,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'select',
        placeholder: 'Common_SiteType',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        //TODO:  Change Below when backend is ready
        url: '/api/AzureRoles/roleTypes',
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'roleType',
        queryParam: {
            name: 'roleTypeId',
            valueProp: 'id',
        },
        basedOn: {
            type: 'optional',
            contextProp: 'roleGroupType',
            valueProp: 'id',
            queryParamName: 'roleGroupType',
        },
    },

    {
        name: 'URL',
        type: 'text',
        placeholder: 'Common_URL',
        dynamic: true,
        title: 'Common_URL',
        colorCode: {
            primary: 'rgb(93, 4, 112)',
            secondary: 'rgba(93, 4, 112, 0.09)',
        },
        forRequestAccess: true,
        forManageAccess: true,
        order: 3,
        requireAccess: {
            control: 'ITShop-SharepointURL-Control',
        },
        // TODO: Change Below when backend is ready
        contextProp: 'siteUrl',
        queryParam: {
            name: 'url',
            type: 'string',
        },
    },
]

const attributes = [
    {
        name: 'name',
        label: 'Common_DisplayName',
        sortable: true,
    },
    {
        name: 'ownerFriendlyName',
        sortable: true,
        label: 'Owner',
        component: {
            config: {
                imgUrlProp: 'ownerImageThumbUrl',
                friendlyNameProp: 'ownerFriendlyName',
                personIdProp: 'ownerId',
            },
            name: 'Person',
        },
        hideInManageAccess: true,
        headerCellStyles: {
            width: '170px',
            minWidth: '170px',
            fontSize: '13px',
        },
        styles: {
            width: '170px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'type',
        label: 'Common_SiteType',
        sortable: true,
    },
    {
        name: 'permissionLevel',
        label: 'Common_PermissionLevel',
        sortable: true,
        manageAccessOnlyAttribute: true,
    },
    {
        name: 'assignedVia',
        label: 'Common_AssignedVia',
        sortable: true,
        manageAccessOnlyAttribute: true,
    },

    {
        name: 'description',
        label: 'Common_Description',
        sortable: true,
    },
]

const sharepointConfig = {
    name: 'Sharepoint',
    resourceTypeName: 'Sharepoint',
    title: 'Common_Sharepoint',
    route: '/sharepoint',
    requireAccess: {
        page: 'ITShop-Sharepoint-Page',
    },
    order: 11,
    workflows: [],
    filters: filters,

    attributes: attributes,
    // TODO: Change Below when backend is ready
    icon: {
        small: 'MailboxesSmall',
        large: 'MailboxesLarge',
    },
    dataSource: {
        type: 'MS_DS',
        url: '/api/ProtectedAppResources',
    },
}

export default sharepointConfig
