import React from 'react'
import { CardHeader, Typography, Box, useTheme } from '@material-ui/core'
import HeaderBgImage from './header-bg.png'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { Tooltip } from 'packages/eid-ui'
import config from 'config'
const useCardContentStyles = makeStyles({
    headingBG: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 0,
        right: 0,
        top: 0,
        borderRadius: '8px',
    },
    Oval: {
        position: 'absolute',
        zIndex: 1,
        left: 5,
        top: -4,
        width: '88px',
        height: '88px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        border: 'solid 1px rgba(0, 0, 0, 0.15)',
        borderRadius: '50%',
        backgroundColor: '#fff',
        padding: '24px',
    },
    headingContentBox: (props) => ({
        position: 'relative',
        padding: '20px',
        paddingLeft: props.mobile ? '20px' : '26px',
        paddingRight: '10px',
        minHeight: '80px',
        width: '100%',
    }),
    heading: {
        width: '100%',
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        maxWidth: '690px',
    },
    image: {
        height: '37px',
        width: '37px',
    },
})

const MyCardHeader = withStyles(() => ({
    root: {
        padding: 0,
        width: '100%',
        overflow: 'hidden',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
    },
    content: {
        width: '100%',
    },
    title: {
        fontSize: '24px',
        fontWeight: '600',
    },
}))(CardHeader)

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '500px',
}

const ItemHeader = ({ title, url, iconComponent, showIcon }) => {
    const isSmallScreen = useIsSmallScreen()
    const theme = useTheme()
    const contentClasses = useCardContentStyles()
    const maxCharacters = isSmallScreen ? 40 : 120
    let titleText = isNilOrEmpty(title) ? '' : title
    titleText =
        titleText.length > maxCharacters
            ? `${titleText.substring(0, maxCharacters)}...`
            : titleText

    const titleJsx = (
        <Typography
            className={contentClasses.heading}
            style={{
                fontWeight: 'bold',
                fontSize: '18px',
                lineHeight: '38px',
                color: '#fff',
                display: 'block',
                wordBreak: 'break-word',
                wordWrap: 'break-word',
                marginLeft: url || showIcon ? '80px' : '0px',
            }}
        >
            {titleText}
        </Typography>
    )

    let wrappedTitle

    if (titleText.length > maxCharacters) {
        wrappedTitle = (
            <Tooltip
                title={title}
                color="#5D6870"
                fontColor="#ffffff"
                enterDelay={500}
                enterNextDelay={500}
                interactive={true}
                tooltipStyles={tooltipStyles}
            >
                {titleJsx}
            </Tooltip>
        )
    } else {
        wrappedTitle = titleJsx
    }

    return (
        <MyCardHeader
            className={contentClasses.cardHeader}
            isSmallScreen={isSmallScreen}
            title={
                <Box
                    className={contentClasses.headingContentBox}
                    display="flex"
                    alignItems="center"
                    overflow="hidden"
                    style={{
                        background:
                            theme.palette.secondary.gradient ??
                            'linear-gradient(99deg, #682a94, #05afe0)',
                    }}
                >
                    {wrappedTitle}
                    {url && (
                        <Box className={contentClasses.Oval}>
                            <img
                                className={contentClasses.image}
                                src={url}
                                alt="header icon"
                            />
                        </Box>
                    )}
                    {showIcon && (
                        <Box
                            className={contentClasses.Oval}
                            style={{ padding: '30px' }}
                        >
                            {iconComponent}
                        </Box>
                    )}

                    <img
                        className={contentClasses.headingBG}
                        src={`${config.APP_SUBPATH}${HeaderBgImage}`}
                        alt="background"
                    />
                </Box>
            }
        />
    )
}

export default ItemHeader
