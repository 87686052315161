import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Card, CardContent, Box, useTheme } from '@material-ui/core'
import Attribute from './Attribute'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowSmallIcon, ClockIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import ArrayFormatter from './ArrayFormatter'
import moment from 'moment'
import MasterPasswordLock from 'components/MasterPasswordLock'
import { useAppState } from 'appContext'
import { Instructions } from 'components/ApplicationBanner/Instructions'
import GenericButton from 'components/Button/GenericButton'

const useCardStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',

        boxShadow: 'none !important',
        position: 'relative',
        overflow: 'visible',
        border: 'none !important',
        borderBottomLeftRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
    },
})

const useCardContentStyles = makeStyles({
    cta: {
        fontSize: '16px',
        textTransform: 'none',
        padding: '6px 17px',
        position: 'absolute',
        width: '100%',
        'border-top-right-radius': 0,
        'border-top-left-radius': 0,
        bottom: '-60px',
        transition: 'all 0.5s ease 0s',
        opacity: 0,
    },
    active: {
        bottom: '-40px',
        opacity: 1,
        transition: 'all 0.5s ease 0s',
    },
    cardContent: (props) => ({
        padding: '0 20px 0px 32px',
        position: 'relative',
        paddingLeft: props.mobile && '20px',
        marginTop: '20px',
    }),
    contentBox: {
        '&:not(:last-child)': {
            marginBottom: '18px',
        },
        position: 'relative',
    },
    description: {
        paddingRight: '5px',
    },
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
    },

    cardSubTitle: (props) => ({
        color: '#000000',
        fontSize: '14px',
        lineHeight: '15px',
        marginTop: '8px',
        padding: '0px 10px 15px 32px ',
        paddingLeft: props.mobile && '20px',
        letterSpacing: '-0.06px',
    }),
    cardContentLock: () => ({
        position: 'absolute',
        width: '65%',
        height: '190px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '9',
    }),
    descriptionTilte: {
        paddingRight: '5px',
        position: 'relative',
        marginBottom: '8px',
    },
    cardimg: {
        position: 'absolute',
        right: '25px',
        top: '8px',
    },
})

const RequestableResourceDetails = ({
    item,
    assignmentDetails,
    cardStyles,
    mobile,
    attributes,
    additionalData,
    isManageAccess = false,
    data,
    resourceType,
}) => {
    const { t } = useTranslation()
    const [{ masterPassword }] = useAppState()
    const nonHeaderAttributes = attributes.filter((x) => !x.isHeader)

    const contentClasses = useCardContentStyles({ mobile })
    const cardClasses = useCardStyles({ cardStyles })
    const theme = useTheme()

    let filteredAttributes = nonHeaderAttributes

    if (!isManageAccess) {
        filteredAttributes = nonHeaderAttributes.filter(
            (x) => !x.manageAccessOnlyAttribute,
        )
    }

    let showMasterLock = false

    if (resourceType && resourceType.name === 'Credentials') {
        const isMasterLockRequired = filteredAttributes.find(
            (x) => x.showMasterLock,
        )
        if (isMasterLockRequired) {
            showMasterLock = true
        }
    }

    const masterPasswordLockStyle = {
        height: '190px',
        width: '100%',
    }

    return (
        <Card classes={cardClasses} role="button">
            {item.instructions && (
                <Instructions
                    title={item.friendlyName}
                    instructions={item.instructions}
                    button={(onClick) => (
                        <GenericButton
                            onClick={() => onClick(true)}
                            color="#8b909a"
                            rootStylesProp={{
                                borderRadius: '4px',
                                width: '100px',
                                height: '32px',
                                position: 'absolute',
                                right: 15,
                                top: 20,
                                fontSize: '14px',
                                padding: '6px',
                                zIndex: 10,
                            }}
                        >
                            <>
                                {t('Common_MoreInfo')}
                                <ArrowSmallIcon
                                    color={'white'}
                                    direction={'right'}
                                    fill={'white'}
                                    style={{
                                        marginLeft: 5,
                                    }}
                                />
                            </>
                        </GenericButton>
                    )}
                />
            )}

            <CardContent className={contentClasses.cardContent}>
                {!masterPassword && showMasterLock && (
                    <Box className={contentClasses.cardContentLock}>
                        <MasterPasswordLock style={masterPasswordLockStyle} />
                    </Box>
                )}
                <Box
                    className={clsx(
                        contentClasses.contentBox,
                        contentClasses.meta,
                    )}
                >
                    {filteredAttributes
                        .filter((a) => a.showInCard !== false)
                        .map((a, index) => {
                            if (
                                a.name === 'dnsHostName' &&
                                resourceType?.name === 'Computers'
                            ) {
                                return (
                                    <Attribute
                                        data={data}
                                        item={item}
                                        key={a.name}
                                        label={t(a.label)}
                                        type={a.type}
                                        value={
                                            <div
                                                title={item[a.name]}
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    paddingRight: '70px',
                                                    width:
                                                        index === 0 &&
                                                        item?.instructions &&
                                                        '60%',
                                                }}
                                            >
                                                {item[a.name]}
                                            </div>
                                        }
                                        labelStyles={{
                                            fontSize: 12,
                                            color: '#9b9b9b',
                                            textTransform: 'uppercase',
                                            fontWeight: '600',
                                        }}
                                        valueStyles={{
                                            fontSize: 14,
                                            color: '#000000',
                                        }}
                                        maxWidth="100%"
                                        orientation="vertical"
                                        attribute={a}
                                    />
                                )
                            }
                            if (a.name === 'DifferentiationValue') {
                                return (
                                    <Attribute
                                        key="diffValue"
                                        label={t(a.label)}
                                        value={
                                            additionalData.assignment
                                                .resourceAssignment
                                                .locationFriendlyName
                                        }
                                        labelStyles={{
                                            fontSize: 12,
                                            color: '#9b9b9b',
                                            textTransform: 'uppercase',
                                            fontWeight: '600',
                                        }}
                                        valueStyles={{
                                            fontSize: 14,
                                            color: '#000000',
                                            width:
                                                index === 0 &&
                                                item?.instructions &&
                                                '60%',
                                        }}
                                        maxWidth="100%"
                                        orientation="vertical"
                                        attribute={a}
                                    />
                                )
                            }
                            if (a.name === 'assignee') {
                                return (
                                    <Attribute
                                        key={a.name}
                                        label={t(a.label)}
                                        value={
                                            additionalData.assignment
                                                .resourceAssignment
                                                .assigneePreviewName
                                        }
                                        labelStyles={{
                                            fontSize: 12,
                                            color: '#9b9b9b',
                                            textTransform: 'uppercase',
                                            fontWeight: '600',
                                        }}
                                        valueStyles={{
                                            fontSize: 14,
                                            color: '#000000',
                                            width:
                                                index === 0 &&
                                                item?.instructions &&
                                                '60%',
                                        }}
                                        maxWidth="100%"
                                        orientation="vertical"
                                        attribute={a}
                                    />
                                )
                            }

                            if (a.name === 'description') {
                                return (
                                    <Attribute
                                        key={a.name}
                                        value={item.description}
                                        valueStyles={{
                                            fontSize: 14,
                                            color: '#000000',
                                            maxHeight: 100,
                                            overflow: 'auto',
                                            marginTop: 16,
                                            paddingRight: 5,
                                            width:
                                                index === 0 &&
                                                item?.instructions &&
                                                '60%',
                                        }}
                                        maxWidth="100%"
                                        orientation="vertical"
                                        attribute={a}
                                    />
                                )
                            }

                            if (a.type === 'array') {
                                return (
                                    item[a.name] && (
                                        <ArrayFormatter
                                            key={a.name}
                                            showMax={a.showMax}
                                            labelProp={a.labelProp}
                                            label={t(a.label)}
                                            keyProp={a.keyProp}
                                            items={item[a.name]}
                                            style={{
                                                color: '#307fc1',
                                                fontSize: '16px',
                                                textDecoration: 'underline',
                                            }}
                                        />
                                    )
                                )
                            }

                            const value = item[a.name]
                            return (
                                <Attribute
                                    data={data}
                                    item={item}
                                    key={a.name}
                                    label={t(a.label)}
                                    type={a.type}
                                    value={value}
                                    labelStyles={{
                                        fontSize: 12,
                                        color: '#9b9b9b',
                                        textTransform: 'uppercase',
                                        fontWeight: '600',
                                    }}
                                    valueStyles={{
                                        fontSize: 14,
                                        color: '#000000',
                                        width:
                                            index === 0 &&
                                            item?.instructions &&
                                            '60%',
                                    }}
                                    maxWidth="100%"
                                    orientation="vertical"
                                    attribute={a}
                                />
                            )
                        })}

                    {assignmentDetails && (
                        <Box marginTop="10px">
                            <Attribute
                                label={t('Common_EndDateTime')}
                                value={
                                    <Box
                                        component="span"
                                        minHeight="26px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <ClockIcon
                                            color={theme.palette.secondary.main}
                                        />
                                        <Box component="span" padding="0 10px">
                                            {!assignmentDetails?.timeConstraintActive ? (
                                                t('Common_Never').toUpperCase()
                                            ) : (
                                                <>
                                                    {moment
                                                        .utc(
                                                            assignmentDetails.endDateUtc,
                                                        )
                                                        .clone()
                                                        .local()
                                                        .format('LLL')}
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                }
                                labelStyles={{
                                    fontSize: 12,
                                    color: '#9b9b9b',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                }}
                                valueStyles={{ color: '#000000' }}
                                maxWidth="100%"
                                orientation="vertical"
                            />
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    )
}

RequestableResourceDetails.propTypes = {
    item: PropTypes.object,
    assignmentDetails: PropTypes.object,
    className: PropTypes.string,
}

export default RequestableResourceDetails
