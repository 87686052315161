import { FC, useState } from 'react'
import { Box, Collapse, createStyles, styled } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

export type PreApprovedItemProps = {
    title: string
    itemCount: number
    defaultExpand?: any
    showExpand?: any
}

const PaddedDiv = styled(Box)({
    margin: '0 31px',
})

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: '#fbfbfd',
            marginBottom: '16px',
        },
        approveaccordionleft: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        approveaccordionright: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        approveaccordion: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 35px',
            borderBottom: '1px solid #d8dadd',
            background: 'transparent',
        },
        approveheading: {
            fontSize: '14px',
            color: '#282828',
        },
        approvecounting: {
            padding: '0px 8px',
            marginLeft: '8px',
            background: '#8b909a',
            borderRadius: '8px',
            fontSize: '14px',
            color: '#fff',
        },
        approvehide: {
            fontSize: '14px',
            color: '#307fc1',
            marginRight: '8px',
            cursor: 'pointer',
        },
        expandIcon: {
            width: '18px',
            height: '18px',
            marginLeft: '8px',
            borderRadius: '50%',
            border: '2px solid #307fc1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            color: '#307fc1',
        },
        expandButton: {
            textTransform: 'uppercase',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }),
)

export const PreApprovedItemWrapper: FC<PreApprovedItemProps> = (props) => {
    const { title, itemCount, children, defaultExpand = true, showExpand = true } = props
    const { t } = useTranslation()
    const [showExpanded, setShowExpanded] = useState<boolean>(defaultExpand)
    const classes = useStyles()

    return (
        <>
            <Box className={classes.approveaccordion}>
                <Box className={classes.approveaccordionleft}>
                    <Box className={classes.approveheading}>
                        {title ? title : '-'}
                    </Box>
                    <Box className={classes.approvecounting}>
                        {itemCount ? itemCount : 0}
                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    fontSize="12px"
                    fontWeight="bold"
                    color="#307fc1"
                >
                    {showExpand &&
                        <Box
                            component="span"
                            display="flex"
                            className={classes.expandButton}
                            onClick={() => setShowExpanded(!showExpanded)}
                        >
                            {showExpanded ? t('Hide') : t('Show')}
                            <Box className={classes.expandIcon}>
                                {showExpanded ? '-' : '+'}
                            </Box>
                        </Box>}
                </Box>
            </Box>
            <Collapse in={showExpanded} timeout={400} unmountOnExit>
                <PaddedDiv className={classes.root}>{children}</PaddedDiv>
            </Collapse>
        </>
    )
}
