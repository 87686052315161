import { Box, Grid, Typography } from '@material-ui/core'
import ItemCardSkeleton from './ItemCardSkeleton'
import Selectors from 'containers/Selectors'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import { CardList } from 'packages/eid-controls'
import ItemCard from './ItemCard'
import SideDrawer from './SideDrawer'
import { useTranslation } from 'react-i18next'
import { Loader, useViewIndicator } from 'packages/eid-ui'
import TableView from './TableView'
import useSubcomponents from 'useSubcomponents'
import { subHeaderHeight } from 'utils'
import ComputerCardView from 'components/ComputerCardView'
import { useAppState } from 'appContext'
import Search from 'components/Layout/ITShopHeader/Search'
import config from 'config'

const DesktopVersion = ({
    loading,
    loadingMore,
    infiniteScrollObervableDiv,
    id,
    data,
    onItemClick,
    currentResourceType,
    resourceTypeAttributes,
    handleSaveFilters,
    savingFilters,
    onSort,
    sortBy,
    sortOrder,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const query = useQuery()

    const { canSeeSimpleTextSearch } = useSubcomponents()

    const CURRENT_SCREEN = 'RequestAccess'

    const [{ appConfig }] = useAppState()

    const searchConfig = appConfig.style?.search

    const { view } = useViewIndicator({
        view: query.get('view'),
        onCardViewClick: () => {
            query.set('view', 'card')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        onListViewClick: () => {
            query.delete('view')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
    })

    const showFilterSaveDelete = currentResourceType.name !== 'Applications'
    return (
        <div
            style={{
                flexGrow: 1,
                marginTop: `${subHeaderHeight + 20}px`,
            }}
        >
            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    flex="27%"
                    maxWidth="370px"
                >
                    <Selectors
                        resourceType={currentResourceType.name}
                        screen={CURRENT_SCREEN}
                        handleSaveFilters={handleSaveFilters}
                        savingFilters={savingFilters}
                        showDeleteFiltersButton={showFilterSaveDelete}
                        showSaveFiltersButton={showFilterSaveDelete}
                    />
                </Box>
                <Box flex="73%" display="flex" flexWrap="wrap" height="100%">
                    <Grid
                        container
                        spacing={0}
                        style={
                            view === 'card'
                                ? {
                                      margin: '0 14px',
                                  }
                                : {}
                        }
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            width="100%"
                            padding="8px"
                        >
                            {searchConfig?.show && canSeeSimpleTextSearch && (
                                <div
                                    data-protectedsubcomponent={
                                        canSeeSimpleTextSearch
                                    }
                                    style={{
                                        height: '100%',
                                        display: 'flex',
                                        flex: 1,
                                        minWidth: '140px',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Search styleData={searchConfig?.style} />
                                </div>
                            )}
                        </Box>
                        {view === 'card' ? (
                            <CardList
                                // TODO: Enable this when we have new UI
                                // cardType={currentResourceType?.name}
                                infiniteScrollObervableDiv={
                                    infiniteScrollObervableDiv
                                }
                                noItemMessage={
                                    <Typography
                                        variant="h5"
                                        style={{ color: '#b4b4b4' }}
                                    >
                                        {t('Common_NoItemsFound', {
                                            itemType: t(
                                                currentResourceType.title,
                                            ),
                                        })}
                                    </Typography>
                                }
                                loadingIndicator={
                                    <>
                                        <ItemCardSkeleton />
                                        <ItemCardSkeleton />
                                        <ItemCardSkeleton />
                                        <ItemCardSkeleton />
                                    </>
                                }
                                fetcher={() => ({
                                    loading,
                                    loadingMore,
                                    list: data ? data : [],
                                })}
                                renderItem={(item) => (
                                    <>
                                        {/* Keepin this as discussed on call as we need thsi in future */}
                                        {currentResourceType?.name ===
                                        'NewUI' ? (
                                            <Box key={item.id}>
                                                <ComputerCardView
                                                    btnLabel={t(
                                                        'Common_RequestAccess',
                                                    )}
                                                    item={item}
                                                />
                                            </Box>
                                        ) : (
                                            <div
                                                key={item.id}
                                                style={{
                                                    height: 'inline-block',
                                                    width: '33.33%',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <ItemCard
                                                    cardStyles={{
                                                        margin: '8px',
                                                    }}
                                                    key={item.id}
                                                    attributes={
                                                        resourceTypeAttributes
                                                    }
                                                    buttonStyles={{
                                                        '&:hover': {
                                                            backgroundColor:
                                                                '#02997E',
                                                        },
                                                    }}
                                                    item={item}
                                                    resourceType={
                                                        currentResourceType
                                                    }
                                                    onItemClick={() =>
                                                        onItemClick(item)
                                                    }
                                                    color={
                                                        currentResourceType.color
                                                    }
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        ) : (
                            <Box padding="8px" flex="1">
                                <TableView
                                    loading={loading}
                                    onSort={onSort}
                                    sortOrder={sortOrder}
                                    sortBy={sortBy}
                                    fallbackMessage={t('Common_NoItemsFound', {
                                        itemType: t(currentResourceType.title),
                                    })}
                                    data={data}
                                    attributes={resourceTypeAttributes}
                                    onItemClick={(item) => onItemClick(item)}
                                    currentResourceType={currentResourceType}
                                />
                                {loadingMore && <Loader />}
                                {infiniteScrollObervableDiv}
                            </Box>
                        )}
                    </Grid>
                </Box>
            </div>
            <SideDrawer
                id={id}
                toggleDrawer={() => {
                    query.delete('selected')
                    history.push(
                        `${config.APP_SUBPATH}/${
                            currentResourceType.name
                        }?${query.toString()}`,
                    )
                }}
                attributes={resourceTypeAttributes}
                type={currentResourceType.name}
            />
        </div>
    )
}

export default DesktopVersion
