import React from 'react'
import { InputBaseProps, makeStyles, InputBase } from '@material-ui/core'

export interface StyleProps {
    fontColor?: string
    color?: string
    tooltipStyles: {}
}

const useStyles = makeStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(2),
        },
    },

    input: () => ({
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        padding: '11px 10px',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: '#000000',

        margin: 'auto',
        borderBottomRightRadius: '3px',
        borderTopRightRadius: '3px',
        borderRadius: '5px',

        '&:hover': {
            borderColor: '#307fc1 !important',
        },
        '&:focus': {
            borderColor: '#307fc1',
            boxShadow: '0 2px 4px 0 rgba(48, 127, 193, 0.15)',
            borderRadius: '5px !important',
            backgroundColor: '#ffffff',
        },
    }),
}))

export interface InputProps extends InputBaseProps {}

export const Input: React.FC<InputProps> = ({ placeholder, ...rest }) => {
    const classes = useStyles()

    return <InputBase classes={classes} fullWidth {...rest} />
}
