import {
    directAssignedOnlyFilterColors,
    timeConstrainedOnlyFilterColors,
} from './colors'

const filters = [
    {
        forManageAccess: true,
        forRequestAccess: true,
        name: 'ShoppingFor',
        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        title: {
            requestAccessTitle: 'Common_TargetPerson',
            manageAccessTitle: 'Common_ManageFor',
        },
        order: 1,
        appRights: true,
        appManagementRoles: true,
        roleDefinitions: true,
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-Application-DirectAssignedApplications-Control',
        },
        order: 2,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        default: {
            appRights: true,
            roleDefinitions: true,
            appManagementRoles: true,
        },
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
        appRights: true,
        appManagementRoles: true,
        roleDefinitions: true,
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyTimeConstrained',
        title: 'ManageAccess_ShowTimeConstrained',
        requireAccess: {
            control:
                'ITShop-Application-TimeConstrainedApplicationRoles-Control',
        },
        order: 3,
        colorCode: timeConstrainedOnlyFilterColors,
        label: 'ManageAccess_ShowTimeConstrained',
        type: 'checkbox',
        default: true,
        contextProp: 'showTimeConstrained',
        trueValueIndicator: 'ManageAccess_ShowTimeConstrained',
        queryParam: {
            name: 'showTimeConstrained',
        },
        appRights: true,
        appManagementRoles: true,
        roleDefinitions: true,
    },
    {
        dynamic: true,
        name: 'UNCPath',
        title: 'Applications_UncPath',
        requireAccess: { control: 'ITShop-ApplicationsUncPath-Control' },
        order: 2,
        icon: {
            name: 'Applications',
            color: '#959598',
        },
        colorCode: {
            primary: '#307fc1',
            secondary: 'rgba(48, 127, 193, 0.09)',
        },
        type: 'autocomplete',
        primitiveType: true,
        label: 'Applications_UncPath',
        placeholder: 'Applications_SelectUncPath',
        optionConfig: {
            type: 'label',
            labelProp: 'value',
        },
        url: '/api/Applications/uncPaths',
        minCharactersReqForSearch: 0,
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'uncPath',
        queryParam: {
            name: 'uncPath',
            valueProp: 'value',
        },
        basedOn: {
            contextType: 'global',
            type: 'required',
            contextProp: 'targetPerson',
            valueProp: 'id',
            queryParamName: 'targetPersonId',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'ShowAzureApplication',
        title: 'Common_ShowAzureApplication',
        requireAccess: {
            control: 'ITShop-ShowOnlyAzureApplications-Control',
        },
        order: 4,
        label: 'On',
        withoutColor: true,
        type: 'checkbox',
        contextProp: 'showOnlyAzureApplications',
        queryParam: {
            name: 'showOnlyAzureApplications',
        },
    },
    {
        forManageAccess: true,
        appRights: true,
        appManagementRoles: true,
        roleDefinitions: true,
        dynamic: true,
        name: 'Applications',
        title: 'Common_Applications',
        requireAccess: {
            control: 'ITShop-ApplicationsManageAccess-Applications-Control',
        },
        order: 5,
        colorCode: {
            primary: '#ff5e65',
            secondary: '#fee8f2',
        },
        type: 'autocomplete',
        label: 'Common_Applications',
        placeholder: 'Common_SelectApplication',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/ProtectedAppResources',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'application',
        queryParam: {
            name: 'applicationId',
            valueProp: 'id',
        },
        basedOn: {
            type: 'required',
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
            queryParamName: 'targetPersonId',
        },
    },

    {
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-ApplicationsAdvancedSearch-Control',
        },
        order: 3,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'ShareName',
                    type: 'text',
                    placeholder: 'Applications_ShareName',
                    contextProp: 'advancedSearch.forms.shareName',
                    queryParam: {
                        name: 'shareName',
                        type: 'string',
                    },
                },
                {
                    name: 'Computer',
                    type: 'text',
                    placeholder: 'Applications_Computer',
                    contextProp: 'advancedSearch.forms.computer',
                    queryParam: {
                        name: 'Computer',
                        type: 'string',
                    },
                },
                {
                    name: 'DnsHostName',
                    type: 'text',
                    placeholder: 'Applications_DnsHostName',
                    contextProp: 'advancedSearch.forms.dnsHostName',
                    queryParam: {
                        name: 'DnsHostName',
                        type: 'string',
                    },
                },

                {
                    name: 'Description',
                    type: 'text',
                    placeholder: 'Common_Description',
                    contextProp: 'advancedSearch.forms.description',
                    queryParam: {
                        name: 'Description',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    {
        name: 'applicationLogoImageUrl',
        component: {
            name: 'ApplicationImage',
        },
        showInCard: false,
        hideInManageAccess: true,
    },
    {
        name: 'friendlyName',
        label: 'Common_FriendlyName',
        isHeader: true,
        sortable: true,
        appRights: true,
        appManagementRoles: true,
        roleDefinitions: true,
    },
    {
        name: 'name',
        label: 'Common_TechnicalName',
        sortable: true,
        requestAccessOnly: true,
    },
    {
        name: 'ownerFriendlyName',
        sortable: true,
        label: 'Applications_Owner',
        component: {
            config: {
                imgUrlProp: 'ownerImageThumbUrl',
                friendlyNameProp: 'ownerFriendlyName',
                personIdProp: 'ownerId',
                showOnlyIfIsPerson: true,
                isPersonFlagProp: 'ownerIsPerson',
            },
            name: 'Person',
        },
        hideInManageAccess: true,
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'applicationName',
        label: 'Common_Application',
        isHeader: true,
        sortable: true,
        appRights: true,
        appManagementRoles: true,
        roleDefinitions: true,
        manageAccessOnlyAttribute: true,
    },
    {
        name: 'description',
        label: 'Common_Description',
        isHeader: true,
        sortable: true,
        appRights: true,
        appManagementRoles: true,
        roleDefinitions: true,
    },
    {
        name: 'rightTypeName',
        label: 'Common_Type',
        isHeader: true,
        sortable: true,
        appRights: true,
        appManagementRoles: false,
        roleDefinitions: false,
        manageAccessOnlyAttribute: true,
    },
    {
        name: 'roleTypeName',
        label: 'Common_Type',
        isHeader: true,
        sortable: true,
        appRights: false,
        appManagementRoles: false,
        roleDefinitions: true,
        manageAccessOnlyAttribute: true,
    },
    {
        name: 'typeFriendlyName',
        label: 'Common_Type',
        sortable: true,
        appRights: false,
        appManagementRoles: true,
        roleDefinitions: false,
        manageAccessOnlyAttribute: true,
    },

    {
        name: 'endDateUtc',
        label: 'Common_EndDate',
        sortable: false,
        manageAccessOnlyAttribute: true,
        appRights: true,
        appManagementRoles: true,
        roleDefinitions: true,
        component: { name: 'EndDateManageAccess' },
    },
]

const manageAccessTabs = [
    {
        label: 'Applications_AppRights',
        path: 'appRights',
        icon: 'AppRole',
        display: true,
        default: true,
        controller: 'LocalRights',
        showMasterLock: false,
        contextProp: 'LocalRightsCount',
        showCount: true,
        requireAccess: {
            control: 'Applications-AppRights',
        },
    },
    {
        label: 'Application_RoleDefinitions',
        path: 'roleDefinitions',
        icon: 'AzureRole',
        display: true,
        controller: 'azureroles',
        showMasterLock: false,
        contextProp: 'azurerolesCount',
        showCount: true,
        requireAccess: {
            control: 'Applications-RoleDefinitions',
        },
    },
    {
        label: 'Application_AppManagementRoles',
        path: 'appManagementRoles',
        icon: 'ManagementRole',
        display: true,
        controller: 'ManagementRoles',
        showMasterLock: false,
        contextProp: 'ManagementRolesCount',
        showCount: true,
        requireAccess: {
            control: 'Applications-AppManagementRoles',
        },
    },
]

const applicationsConfig = {
    name: 'Applications',
    resourceTypeName: 'ProtectedApplicationResource',
    title: 'Common_Applications',
    route: '/applications',
    requireAccess: {
        page: 'ITShop-Applications-Page',
    },
    order: 1,
    icon: {
        small: 'ApplicationsSmall',
        large: 'ApplicationsLarge',
    },

    dataSource: {
        type: 'MS_DS',
        url: '/api/ProtectedAppResources',
    },
    showTimeConstrainedFilter: false,
    showDirectAssignedOnlyFilter: true,
    showParsedHtmlMoreInfo: true,
    workflows: [
        {
            name: 'OnboardAzureApplication',
            requireAccess: {
                control: 'ITShop-CreateAzureApplicationWorkflow-Control',
            },
            config: {
                path: '/applications',
            },
            type: 'static',
        },
    ],
    filters: filters,
    attributes: attributes,
    manageAccessTabs,
}

export default applicationsConfig
