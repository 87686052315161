import { useState } from 'react'
import { Box, Typography, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
    useAccessRequestPolicy,
    useComputerOwners,
    useTargetPerson,
} from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import moment from 'moment'
import { Divider } from 'packages/eid-ui'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'
import ActivateNowButton from 'containers/ListingPage/ActivateNowButton'
import { RenewRevoke } from 'components/RenewRevoke'
import { ResourceTypesNamespace, useRegistry } from 'core'
import useConfiguration from 'useConfiguration'
import cartHelpers from 'containers/Cart/cartHelpers'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const role = item.resource
    const { t } = useTranslation()
    const requestPolicy = useAccessRequestPolicy(role?.requestPolicyId)

    const attributeOrientation = 'horizontal'
    const DateComponentForManageAccess = (attributeName, data) => {
        const timeConstraintActive =
            data.assignment?.assignmentDetails?.timeConstraintActive

        return (
            <>
                {!timeConstraintActive
                    ? '-'
                    : moment
                          .utc(
                              data?.assignment?.assignmentDetails[
                                  attributeName
                              ],
                          )
                          .clone()
                          .local()
                          .format('LLL')}
            </>
        )
    }
    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_FriendlyName')}
                    value={role?.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={role?.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={role?.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_Type')}
                    value={role?.typeFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_StartDate')}
                    value={DateComponentForManageAccess('startDateUtc', item)}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_EndDate')}
                    value={DateComponentForManageAccess('endDateUtc', item)}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <Typography
                    style={{
                        fontSize: '14px',
                        textTransform: 'uppercase',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        margin: '16px 0px',
                    }}
                >
                    {role?.isAzureApplication
                        ? t('AzureApplicationDetails')
                        : t('ApplicationDetails')}
                </Typography>
                <ItemDetails.Attribute
                    label={t('Common_FriendlyName')}
                    value={role?.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={role?.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {role?.azureApplicationId && (
                    <ItemDetails.Attribute
                        label={t('AzureApplicationId')}
                        value={role?.azureApplicationId}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}

                {role?.azureApplicationObjectId && (
                    <ItemDetails.Attribute
                        label={t('AzureApplicationObjectId')}
                        value={role?.azureApplicationObjectId}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                {role?.servicePrincipalObjectId && (
                    <ItemDetails.Attribute
                        label={t('ServicePrincipalObjectId')}
                        value={role?.servicePrincipalObjectId}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                {requestPolicy && (
                    <>
                        <Divider style={{ margin: '16px 0px' }} />
                        <AccessRequestPolicyDetails
                            policy={requestPolicy}
                            orientation={attributeOrientation}
                        />
                    </>
                )}
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const role = item.resource
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const { data: owners, isLoading: ownersLoading } = useComputerOwners(
        role?.id,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('Common_Owners')}
                            value={<PeopleList list={owners} />}
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}
const ManagementRoleDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()

    const registry = useRegistry()

    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType('ManagementRoles')

    const managementRoleType = registry.get(
        ResourceTypesNamespace,
        'ManagementRoles',
    )
    const [activeTab, setActiveTab] = useState('overview')
    const [targetPerson] = useTargetPerson()

    const preRevoke = () => {
        const itemToRevoke = cartHelpers.managementRoleToCartItem({
            targetPerson,
            assignmentType: 'Remove',
            managementRole: item.resource,
            assignmentStatus: item.assignment,
        })
        if (!itemToRevoke) return undefined

        return itemToRevoke
    }

    return (
        <>
            <ItemDetails.Tabs
                variant="scrollable"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />

                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />

                {/* <ItemDetails.Tabs.Tab
                    value="actions"
                    label={t('Common_Actions')}
                /> */}
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}

            {item?.assignment.canActivateNow ? (
                <ActivateNowButton
                    resource={item.resource}
                    resourceType={currentResourceType}
                    forDetailsDrawer={true}
                    width="300px"
                    assignment={item?.assignment}
                />
            ) : (
                <RenewRevoke
                    resourceType={managementRoleType}
                    resource={item.resource}
                    assignment={item.assignment}
                    postRenew={toggleDrawer}
                    preRevoke={preRevoke}
                    postRevoke={toggleDrawer}
                    item={item}
                />
            )}
        </>
    )
}

export default ManagementRoleDetails
