import React from 'react'
import {
    Tooltip as MuiTooltip,
    TooltipProps as MuiTooltipProps,
    makeStyles,
} from '@material-ui/core'

export interface StyleProps {
    fontColor?: string
    color?: string
    tooltipStyles: {}
}

const useStyles = makeStyles({
    tooltip: ({ color, fontColor, tooltipStyles }: StyleProps) => ({
        margin: '0',
        backgroundColor: color,
        color: fontColor,
        fontSize: '12px',
        borderRadius: '3px',
        padding: '3px 8px',
        ...tooltipStyles,
    }),
    arrow: {
        color: (props) => props.color,
        fontSize: 5.5, // Adjust this value to change the size of the arrow
    },
})

export interface TooltipProps extends MuiTooltipProps {
    fontColor?: string
    color?: string
    arrow?: boolean
    tooltipStyles?: {}
}

const Tooltip: React.FC<TooltipProps> = ({
    fontColor = '#ffffff',
    color = '#000000',

    /*
    Overriding default hover/focus props to fix tooltip "hanging" after click.
    Revisit after MUI v5 update.
    */
    disableFocusListener = 'true',
    leaveTouchDelay = '200',
    enterTouchDelay = '00',

    arrow = true,
    tooltipStyles = {
        marginBottom: '5px',
    },
    ...rest
}) => {
    const classes = useStyles({ color, fontColor, tooltipStyles })

    return <MuiTooltip arrow={arrow} classes={classes} {...rest} />
}

export default Tooltip
