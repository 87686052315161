import { Fragment } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const RemainingTime = ({ endTime, expiredLabelLocaleKey = 'Expired' }) => {
    const { t } = useTranslation()
    const currentTime = new Date().toISOString()

    const getRemainingTime = (currentTime, endTime) => {
        if (!endTime) {
            return '-'
        }
        if (currentTime > endTime) {
            return t(expiredLabelLocaleKey)
        }
        const duration = moment.duration(
            moment.utc(endTime).diff(moment.utc(currentTime)),
        )

        const hours = duration.hours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })
        const minutes = duration.minutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })

        const time = `${hours} ${t('hours')} ${minutes} ${t('mins')}`
        const daysLabel = duration.days() > 1 ? t('days') : t('day')

        const durationInDays = duration.days() + ' ' + daysLabel + ' ' + time

        return duration.days() > 0 ? durationInDays : time
    }
    return <Fragment>{getRemainingTime(currentTime, endTime)}</Fragment>
}

export default RemainingTime
