import React, { useState, useRef, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useIsSmallScreen, getFullPathFromUrl } from 'packages/core'
import { useUiActionsByResource } from 'hooks'
import { EidWorkflow, Dropdown, Loader } from 'packages/eid-ui'

import config from 'config'
import {
    makeStyles,
    createStyles,
    MenuItem,
    Button,
    Backdrop,
    styled,
    Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import appConfig from 'config'
import MasterLockOverlay from 'packages/eid-ui/MasterLockOverlay'
import { PasswordModal } from 'components/PasswordModal'
import { useAppState } from 'appContext'

const useBlueStyles = makeStyles(
    createStyles({
        root: (dropdownOpen) => ({
            position: 'relative',
            height: '31px',
            padding: '0px 0px 0px 0px',
            borderRadius: '4px',
            minWidth: '32px',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',

            textTransform: 'capitalize',

            backgroundColor: dropdownOpen ? '#3f3f55' : 'transparent',
            border: 'solid 1px #d8d8dd',

            zIndex: dropdownOpen ? '1202' : '0',
            '&:hover': {
                backgroundColor: '#3f3f55',
                color: 'white',
                cursor: 'hand',
            },
        }),
        dropdownAnchor: {
            position: 'absolute',
            top: '40px',
            left: '115px',
        },
        noActionMessage: {
            padding: '0 10px',
        },
        disabled: {
            backgroundColor: '#efeff1 !important',
            cursor: 'no-drop',
            '&:hover': {
                boxShadow: 'none',
            },
        },
    }),
)

const StyledBackdrop = styled(Backdrop)(() => ({
    zIndex: '1201',
}))

export const CredentialsActionsButton = ({ item }) => {
    const query = useQuery()
    const history = useHistory()
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const UI_ACTIONS_NOUN = ''
    const UI_ACTIONS_VERB = ''

    const disabled = item.isExpired
    const iconColor = disabled ? '#8b909a' : '#3f3f55'

    const [actionsButtonIconColor, setActionsButtonIconColor] = useState(
        iconColor,
    )
    const [anchorEl, setAnchorEl] = useState(null)
    const buttonClasses = useBlueStyles(Boolean(anchorEl))

    const { data, isLoading } = useUiActionsByResource(
        item.id,
        UI_ACTIONS_NOUN,
        UI_ACTIONS_VERB,
        Boolean(anchorEl),
    )

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget)
        setActionsButtonIconColor('white')
    }

    const anchorRef = useRef(null)

    const closeMenu = () => {
        setAnchorEl(null)
        setActionsButtonIconColor(iconColor)
    }

    const onActionClicked = (workflowName) => {
        query.set('workflowName', workflowName)
        query.set('SelectedResourceGuid', item.id)
        query.set('returnUrl', getFullPathFromUrl())
        history.push(
            `${
                appConfig.APP_SUBPATH
            }/credentials/workflows?${query.toString()}`,
        )
    }

    const [{ masterPassword }] = useAppState()
    const [open, setOpen] = useState('')

    const handleMasterUnlock = () => {
        setOpen('enterPassword')
    }

    return (
        <>
            {!masterPassword && (
                <PasswordModal
                    showDefaultContent={false}
                    open={open}
                    setOpen={setOpen}
                />
            )}
            <MasterLockOverlay
                showMasterLock={!masterPassword}
                handleMasterUnlock={handleMasterUnlock}
                styles={{ minHeight: 'auto' }}
                buttonStyles={{ padding: '2px' }}
            >
                <Button
                    onClick={openMenu}
                    onMouseEnter={() => {
                        setActionsButtonIconColor('white')
                    }}
                    onMouseLeave={() => {
                        !Boolean(anchorEl) &&
                            setActionsButtonIconColor(iconColor)
                    }}
                    classes={buttonClasses}
                    disabled={disabled}
                >
                    <Icon name="Actions" color={actionsButtonIconColor} />
                    <span
                        ref={anchorRef}
                        className={buttonClasses.dropdownAnchor}
                    />
                </Button>
                <StyledBackdrop
                    sx={{ color: '#fff' }}
                    open={Boolean(anchorEl)}
                    onClick={closeMenu}
                />
                <Dropdown
                    anchorEl={anchorRef.current}
                    showCone={!isSmallScreen}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={closeMenu}
                    width={'auto'}
                    listStyles={{
                        paddingBottom: '0px',
                        whiteSpace: 'normal',
                        '& > ul': {
                            paddingBottom: '7px',
                            paddingTop: '0px',
                        },
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    iconPosition={{
                        top: '-8px',
                    }}
                    disableScrollLock={false}
                >
                    {isLoading && <Loader height={40} />}
                    {data && data.length > 0 ? (
                        <>
                            {data.map((d) =>
                                isSmallScreen ? (
                                    <EidWorkflow
                                        key={d.id}
                                        baseEidUrl={config.BASE_EID_URL}
                                        title={d.friendlyName}
                                        workflowName={d.requestWorkflowName}
                                        workflowParams={[
                                            `SelectedResourceID=${item.id}`,
                                        ]}
                                    >
                                        <MenuItem
                                            onClick={closeMenu}
                                            style={{ whiteSpace: 'normal' }}
                                        >
                                            {d.friendlyName}
                                        </MenuItem>
                                    </EidWorkflow>
                                ) : (
                                    <MenuItem
                                        key={d.id}
                                        onClick={() => {
                                            closeMenu()
                                            onActionClicked(
                                                d.requestWorkflowName,
                                            )
                                        }}
                                    >
                                        {d.friendlyName}
                                    </MenuItem>
                                ),
                            )}
                        </>
                    ) : data && data.length === 0 && !isLoading ? (
                        <Typography className={buttonClasses.noActionMessage}>
                            {t('NoActionFound')}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Dropdown>
            </MasterLockOverlay>
        </>
    )
}
