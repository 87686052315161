import React, { useEffect, useState } from 'react'
import { Typography, styled, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { EidAutocomplete } from 'packages/eid-controls'
import { Avatar, WithCloseButton, Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useTargetPerson } from 'hooks'
import config from 'config'
import { getFullImageUrl } from 'utils'
import useSubcomponents from 'useSubcomponents'

const styles = {
    '& > div': {
        fontFamily: 'Rubik !important',
        '& > input': {
            fontSize: '14px',
            height: '36px',
            padding: '12px 10px',
        },

        '&:hover': {
            borderColor: '#307fc1 !important',
        },
        '&:focus': {
            borderColor: '#307fc1 !important',
        },
    },
}

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'person',
    cdnUrl: config.BASE_EID_URL,
})

const queryParams = {
    take: 25,
}

const getOptionLabel = (option) => option.friendlyName

const ValueContainer = styled(WithCloseButton)({
    borderRadius: '5px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
})
const Loader = styled(Skeleton)({
    width: '45%',
    height: '64px',
})

const Indicator = ({ value, onClose }) => (
    <>
        <div
            style={{
                borderRadius: '5px',
                padding: '3px',
                border: ' solid 1px #307fc1',
                boxShadow: '0 2px 4px 0 rgba(48, 127, 193, 0.15)',
            }}
        >
            <ValueContainer onClose={onClose}>
                <Avatar src={getFullImageUrl(value.imageThumbUrl)} />
                <Typography
                    style={{
                        marginLeft: '21px',
                        wordBreak: 'break-all',
                    }}
                    variant="body1"
                >
                    {value.friendlyName}
                </Typography>
            </ValueContainer>
        </div>
    </>
)

const ErrorContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    height: '32px',
    borderRadius: '5px',
    border: '1px solid #d8d8dd',
    boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.05)',
    margin: '12px 0px',
})

const HeaderContainer = ({ value }) => (
    <>
        <Box
            style={{
                fontSize: '14px',
                lineHeight: 1.33,
                textTransform: 'uppercase',
                minWidth: '204px',
                display: 'block',
                wordBreak: 'break-word',
                marginBottom: '11px',
                color: '#8b909a',
            }}
        >
            <span>{value}</span>
        </Box>
    </>
)

const ApproverSelection = ({
    lineManager,
    loadingLineManager,
    selectedApprover,
    setSelectedApprover,
}) => {
    const { canReassignApprover } = useSubcomponents()

    const { t } = useTranslation()
    const getTooltipTitle = () => t('Common_ChangeApproverSectionInfo')

    const [manager, setManager] = useState()

    useEffect(() => {
        if (lineManager && !selectedApprover) {
            setManager(lineManager)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineManager])

    useEffect(() => {
        if (lineManager?.id === selectedApprover?.id) {
            setSelectedApprover(null)
            setManager(lineManager)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedApprover])

    const [targetPerson] = useTargetPerson()

    const selfRequestor = loadingLineManager ? (
        <Loader />
    ) : (
        <>
            <HeaderContainer value={t('Common_ApproverSectionTitle')} />
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box margin="10px 0px" width={'480px'}>
                    {manager ? (
                        <>
                            <Indicator value={manager} onClose={() => { }} />
                        </>
                    ) : (
                        <>
                            <ErrorContainer>
                                <Typography>
                                    {t('Common_LineManagerNotAvailable')}
                                </Typography>
                            </ErrorContainer>
                        </>
                    )}
                </Box>
                <Tooltip title={getTooltipTitle()}>
                    <Box alignSelf={'center'}>
                        <Icon name="Info" color="#307fc1" />
                    </Box>
                </Tooltip>
            </Box>
        </>
    )

    const userCoordinator = loadingLineManager ? (
        <Loader />
    ) : (
        <>
            <HeaderContainer data-protectedsubcomponent={canReassignApprover} value={t('Common_ApproverSectionTitle')} />
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box margin="10px 0px" width={'480px'}>
                    {manager ? (
                        <>
                            <Indicator
                                value={manager}
                                onClose={() => setManager(null)}
                            />
                        </>
                    ) : (
                        <>
                            {selectedApprover ? (
                                <>
                                    <Indicator
                                        value={selectedApprover}
                                        onClose={() =>
                                            setSelectedApprover(null)
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <EidAutocomplete
                                        placeholder={t(
                                            'Common_ChangeCartApprover',
                                        )}
                                        rootStyleProps={styles}
                                        url={`/api/cart/searchApprover/${targetPerson.id}`}
                                        queryParams={queryParams}
                                        dedupingInterval={60000}
                                        value={
                                            selectedApprover
                                                ? selectedApprover
                                                : null
                                        }
                                        getOptionLabel={getOptionLabel}
                                        onChange={(_, selectedItem) =>
                                            setSelectedApprover(selectedItem)
                                        }
                                        optionComponent={OptionComponent}
                                    />
                                    <ErrorContainer>
                                        <Typography>
                                            {t(
                                                'Common_LineManagerNotAvailable',
                                            )}
                                        </Typography>
                                    </ErrorContainer>
                                </>
                            )}
                        </>
                    )}
                </Box>
                <Tooltip title={getTooltipTitle()}>
                    <Box alignSelf={'center'}>
                        <Icon name="Info" color="#307fc1" />
                    </Box>
                </Tooltip>
            </Box>
        </>
    )

    return !canReassignApprover ? selfRequestor : userCoordinator
}

export default ApproverSelection
