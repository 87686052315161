import React, { useEffect } from 'react'
import { Box, makeStyles, styled } from '@material-ui/core'
import Filter from './Filter'
import { CloseRoundIcon } from 'packages/eid-icons'
import useAppliedFilterValues from './useAppliedFilterValues'
import { useIsSmallScreen, useQuery } from 'packages/core'
import { useTranslation } from 'react-i18next'
import useConfiguration from 'useConfiguration'
import useDefaultState from '../DeleteFilters/useDefaultState'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useAppState } from 'appContext'

const useStyles = makeStyles({
    margin: {
        margin: '2.5px 5px',
    },
})

const MobileAppliedFiltersBox = styled('Box')({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '5px 10px 5px 10px',
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    minHeight: '40px',
})

const DesktopAppliedFiltersBox = styled('Box')({
    backgroundColor: '#ffffff',
    minHeight: '40px',
    borderRadius: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '5px 10px 5px 10px',
    overflow: 'hidden',
})

const AppliedFilters = ({ resourceType, clearAll, onClearAllFilters }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const classes = useStyles()
    const [, dispatch] = useResourceTypeContext()
    const [{ urlFilters }] = useAppState()
    const { getResourceType } = useConfiguration()

    const currentResourceType = getResourceType(resourceType)

    const query = useQuery()

    const isApplicationScreen = Boolean(query.get('applicationId'))

    const allAppliedFilterValues = useAppliedFilterValues(currentResourceType)
    const defaultState = useDefaultState(resourceType)

    let appliedFilterValues = isApplicationScreen
        ? allAppliedFilterValues.filter((a) => a.name !== 'Applications')
        : allAppliedFilterValues.filter((a) => a.name !== 'ManageFor')

    const protectedFilters = appliedFilterValues.filter(
        (f) => f.urlOverrideProp && urlFilters[f.urlOverrideProp],
    )

    const appliedProtectedFilters = protectedFilters
        .map((f) => Boolean(f.label))
        .some((x) => x)

    // remove protected filters (overriden by url)  from applied filters
    appliedFilterValues = appliedFilterValues.filter(
        (x) => !protectedFilters.includes(x),
    )

    const content = () => (
        <>
            {appliedFilterValues.length === 0 && !appliedProtectedFilters && (
                <Box className={classes.margin}>
                    <div style={{ color: '#b4b4b4' }}>
                        {t('Common_NoFiltersApplied')}
                    </div>
                </Box>
            )}

            {appliedFilterValues.map((f, index) => {
                return (
                    <>
                        {!f.withoutColor && (
                            <Box
                                key={f.label + index}
                                maxWidth="100%"
                                className={classes.margin}
                            >
                                <Filter
                                    label={f.label}
                                    onClose={f.onRemove}
                                    bgColor={f.colorCode.secondary}
                                    textColor={f.colorCode.primary}
                                    icon={
                                        <CloseRoundIcon
                                            color={f.colorCode.primary}
                                        />
                                    }
                                />
                            </Box>
                        )}
                    </>
                )
            })}
            {protectedFilters.map((f, index) => {
                return (
                    <>
                        {!f.withoutColor && f.label && (
                            <Box
                                key={f.label + index}
                                maxWidth="100%"
                                className={classes.margin}
                            >
                                <Filter
                                    label={f.label}
                                    onClose={() => {
                                        dispatch({
                                            type: 'SET_PROP',
                                            payload: {
                                                key: f.contextProp,
                                                value:
                                                    defaultState[f.contextProp],
                                            },
                                        })
                                    }}
                                    bgColor={f.colorCode.secondary}
                                    textColor={f.colorCode.primary}
                                    icon={
                                        defaultState[f.contextProp] && (
                                            <CloseRoundIcon
                                                color={f.colorCode.primary}
                                            />
                                        )
                                    }
                                />
                            </Box>
                        )}
                    </>
                )
            })}
        </>
    )
    const active = appliedFilterValues.length + protectedFilters.length > 0
    const clearAllFilters = () => {
        if (active) {
            dispatch({
                type: 'SET_MULTIPLE_PROPS',
                payload: { ...defaultState },
            })
            onClearAllFilters()
        }
    }
    useEffect(() => {
        if (clearAll) {
            clearAllFilters()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearAll])

    return isSmallScreen ? (
        <MobileAppliedFiltersBox
            onClose={clearAllFilters}
            iconColor={active ? 'red' : '#b4b4b4'}
            closeTooltip={t('Common_ClearAllFilters')}
        >
            {content()}
        </MobileAppliedFiltersBox>
    ) : (
        <DesktopAppliedFiltersBox
            iconColor={active ? 'red' : '#b4b4b4'}
            closeTooltip={t('Common_ClearAllFilters')}
        >
            {content()}
        </DesktopAppliedFiltersBox>
    )
}

export default AppliedFilters
