import { Box, styled } from '@material-ui/core'
import { ItemDetails } from 'components'
import { useComputerSessionRequest, useCurrentPerson } from 'hooks'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComputerSessionRequestFilters from './Filters'
import ComputerSessionRequestTile from './SessionRequestTile'
import ComputersPagination from './Pagination'
import { useIsSmallScreen } from 'packages/core'

const PaddedDiv = styled(Box)((props: any) => ({
    padding: props.isSmallScreen ? '0 16px' : '0 31px',
}))

export interface ComputerSessionRequestProps {
    item: any
}
const defaultTake = 10
export const ComputerSessionRequest: FC<ComputerSessionRequestProps> = (
    props,
) => {
    const { item } = props
    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()
    const [isCheckedOut, setIsCheckedout] = useState<boolean>(false)
    const [selectedPerson, setSelectedPerson] = useState<any>(currentPerson)
    const [startDate, setStartDate] = useState<any>(null)
    const [endDate, setEndDate] = useState<any>(null)
    const [page, setPage] = useState(1)
    const [perPageItems, setPerPageItems] = useState(defaultTake) //take

    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }

    const handlePageSelection = (value: any) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value: any) => {
        setPage(1)
        setPerPageItems(value)
    }
    const skip = (page - 1) * perPageItems
    const isSmallScreen = useIsSmallScreen()
    const [
        getComputerSessionRequest,
        { data: sessionRequests, isLoading },
    ] = useComputerSessionRequest()

    const numberOfPages = sessionRequests
        ? Math.ceil(sessionRequests.totalCount / perPageItems)
        : 0

    useEffect(() => {
        const dataObject: any = {
            RequestedByPersonId: selectedPerson ? selectedPerson.id : null,
            computerId: item.id,
            startDate: startDate,
            endDate: endDate,
            checkedOut: isCheckedOut,
            skip: skip,
            take: perPageItems,
        }
        getComputerSessionRequest(dataObject)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckedOut, startDate, endDate, page, selectedPerson, perPageItems])

    const handleFilterChange = (change: any) => {
        switch (change.prop) {
            case 'startDate':
                setStartDate(change.value)
                break
            case 'endDate':
                setEndDate(change.value)
                break
            case 'isCheckedOut':
                setIsCheckedout(change.value)
                break
            case 'selectedPerson':
                setSelectedPerson(change.value)
                break
        }
        setPage(1)
    }

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv isSmallScreen={isSmallScreen}>
                <ComputerSessionRequestFilters
                    setEndDate={(e: string) =>
                        handleFilterChange({ prop: 'endDate', value: e })
                    }
                    setStartDate={(e: string) =>
                        handleFilterChange({ prop: 'startDate', value: e })
                    }
                    endDate={endDate}
                    startDate={startDate}
                    isCheckedOut={isCheckedOut}
                    checkboxLabel={t('Connected')}
                    handleCheckedClick={(
                        e: React.ChangeEvent<HTMLInputElement>,
                    ) =>
                        handleFilterChange({
                            prop: 'isCheckedOut',
                            value: e.target.checked,
                        })
                    }
                    onPersonSelect={(e: any) =>
                        handleFilterChange({ prop: 'selectedPerson', value: e })
                    }
                    selectedPerson={selectedPerson}
                />
            </PaddedDiv>
            {isLoading && (
                <PaddedDiv>
                    <ItemDetails.AttributesLoader />
                </PaddedDiv>
            )}
            {sessionRequests?.data?.length > 0 && (
                <>
                    {sessionRequests.data.map((sesReq: any, index: number) => (
                        <ComputerSessionRequestTile
                            parent={item}
                            key={`session-request-${index}`}
                            item={sesReq}
                        />
                    ))}
                    <ComputersPagination
                        page={page}
                        handlePageChange={handlePageChange}
                        pageCount={numberOfPages}
                        handlePageSelection={handlePageSelection}
                        totalCount={sessionRequests?.totalCount}
                        perPageItems={perPageItems}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                    />
                </>
            )}
            {!isLoading &&
                sessionRequests &&
                sessionRequests.data &&
                sessionRequests.data.length <= 0 && (
                    <PaddedDiv>
                        <p>
                            {t('Common_NoItemsFound', {
                                itemType: t('Common_SessionRequest'),
                            })}
                        </p>
                    </PaddedDiv>
                )}
        </ItemDetails.AttributesContainer>
    )
}

export default ComputerSessionRequest
