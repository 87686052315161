import {
    Box,
    FormControlLabel,
    makeStyles,
    styled,
    Typography,
} from '@material-ui/core'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import {
    Checkbox,
    TextField,
    WithCloseButton,
    Avatar,
    Tooltip,
} from 'packages/eid-ui'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl } from 'utils'
import PeopleSearch from 'components/PeopleSearch'
import { useIsSmallScreen } from 'packages/core'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'

const useStyles = makeStyles({
    label: {
        color: '#b4b4b4',
        fontSize: '10px',
        textTransform: 'uppercase',
        width: '65px',
        position: 'absolute',
        left: '10px',
        top: '4px',
        zIndex: 50,
    },
    dateMaincontainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '18px',
        marginBottom: '16px',
        '&  span': {
            fontSize: '14px',
        },
        [`@media (max-width:${MOBILE_SCREEN_WIDTH}px)`]: {
            flexDirection: 'column',
            alignItems: 'start',
            gap: '16px',
        },
    },

    dateMainwidthcontainer: {
        '& > div': {
            width: '90%',
        },
        [`@media (max-width:${MOBILE_SCREEN_WIDTH}px)`]: {
            width: '100%',
            '& > div': {
                width: '100%',
            },
        },
    },
    dueDateInputContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',

        '& svg': {
            marginTop: '-10px',
            marginRight: '4px',
        },
        '& input': {
            padding: '10px',
            paddingLeft: '80px',
            color: '#5d6870',
            fontSize: '16px',
        },
        '& fieldset': {
            border: 'none',
        },
        '& > div:nth-child(1)': {
            width: '100%',
        },
        '&:hover': {
            cursor: 'pointer',
            '& input': {
                cursor: 'pointer',
            },
        },
    },
    peoplesearchcontaner: {
        maxWidth: '250px',
        '& label': {
            fontSize: '10px',
            marginBottom: '10px',
        },
        '& svg': {
            position: 'relative',
            left: '10px',
        },
        '& > div >div': {
            height: '40px',
            border: 'solid 1px #ebebed !important',
            overflow: 'hidden',
        },
        [`@media (max-width:${MOBILE_SCREEN_WIDTH}px)`]: {
            maxWidth: '100%',
        },
    },
    personindicatorcontainer: {
        maxWidth: '250px',
        [`@media (max-width:${MOBILE_SCREEN_WIDTH}px)`]: {
            maxWidth: '100%',
        },
    },
})
const PersonIndicator = styled(WithCloseButton)({
    borderRadius: '5px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
})

const Label = styled('label')({
    padding: '0 0 4px 8px',
    lineHeight: 1.5,
    fontSize: '10px',
    display: 'block',
    color: ' #b4b4b4',
    textTransform: 'uppercase',
    marginBottom: '10px',
})
const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '800px',
}
export interface ComputerSessionHistoryFiltersProps {
    isActiveConnectionOnly: boolean
    handleCheckedClick: (e: React.ChangeEvent<HTMLInputElement>) => void
    startDate: string
    endDate: string
    setStartDate: (e: any) => void
    setEndDate: (e: any) => void
    onPersonSelect: (e: any) => void
    selectedPerson: any
}

export const ComputerSessionHistoryFilters: FC<ComputerSessionHistoryFiltersProps> = (
    props,
) => {
    const {
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        handleCheckedClick,
        isActiveConnectionOnly,
        onPersonSelect,
        selectedPerson,
    } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    return (
        <>
            <Box className={classes.dateMaincontainer}>
                <Box className={classes.dateMainwidthcontainer}>
                    <NativeDatePicker
                        value={startDate}
                        handleChange={(date: string) => {
                            setStartDate(date)
                        }}
                    >
                        <Box className={classes.dueDateInputContainer}>
                            <Box component="p" className={classes.label}>
                                {t('Common_StartDate')}
                            </Box>
                            <TextField
                                type="text"
                                margin="none"
                                readOnly
                                value={
                                    startDate
                                        ? moment
                                              .utc(startDate)
                                              .local()
                                              .format('L')
                                        : ''
                                }
                                style={{
                                    backgroundColor: '#fff',
                                    width: '100%',
                                    boxShadow:
                                        '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                                    borderRadius: '5px',
                                }}
                                placeholder={t('Common_SelectDate')}
                            />
                            <Box position="absolute" right="8px" top="16px">
                                <Icon
                                    name="Tasks"
                                    width={19}
                                    height={20}
                                    color="#aab0b4"
                                />
                            </Box>
                        </Box>
                    </NativeDatePicker>
                </Box>
                <Box className={classes.dateMainwidthcontainer}>
                    {/* End Date picker */}
                    <NativeDatePicker
                        value={endDate}
                        handleChange={(date: string) => {
                            setEndDate(date)
                        }}
                    >
                        <Box className={classes.dueDateInputContainer}>
                            <Box component="p" className={classes.label}>
                                {t('Common_EndDate')}
                            </Box>
                            <TextField
                                type="text"
                                margin="none"
                                readOnly
                                value={
                                    endDate
                                        ? moment
                                              .utc(endDate)
                                              .local()
                                              .format('L')
                                        : ''
                                }
                                style={{
                                    backgroundColor: '#fff',
                                    width: '100%',
                                    boxShadow:
                                        '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                                    borderRadius: '5px',
                                    color: '#5d6870',
                                }}
                                placeholder={t('Common_SelectDate')}
                            />
                            <Box position="absolute" right="8px" top="16px">
                                <Icon
                                    name="Tasks"
                                    width={19}
                                    height={20}
                                    color="#aab0b4"
                                />
                            </Box>
                        </Box>
                    </NativeDatePicker>
                </Box>

                {/* Checkbox for checkout */}
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                edge="start"
                                color="primary"
                                onChange={(e) => {
                                    handleCheckedClick(e)
                                }}
                                checked={isActiveConnectionOnly}
                                style={{
                                    marginLeft: isSmallScreen ? '8px' : '0px',
                                }}
                            />
                        }
                        label={t('Common_ActiveSession')}
                    />
                </Box>
            </Box>
            {selectedPerson ? (
                <Box className={classes.personindicatorcontainer}>
                    <Label>{t('Common_WhoConnected')}</Label>
                    <PersonIndicator
                        // @ts-ignore
                        onClose={() => {
                            onPersonSelect(null)
                        }}
                    >
                        <Avatar
                            size="small"
                            src={getFullImageUrl(selectedPerson.imageThumbUrl)}
                        />
                        <Tooltip
                            color="#5D6870"
                            fontColor="#ffffff"
                            enterDelay={500}
                            enterNextDelay={500}
                            tooltipStyles={tooltipStyles}
                            title={selectedPerson.friendlyName}
                        >
                            <Typography
                                style={{
                                    marginLeft: '21px',
                                    wordBreak: 'break-all',
                                }}
                                display="block"
                                variant="body1"
                                noWrap={true}
                            >
                                {selectedPerson.friendlyName}
                            </Typography>
                        </Tooltip>
                    </PersonIndicator>
                </Box>
            ) : (
                <Box className={classes.peoplesearchcontaner}>
                    <PeopleSearch
                        label={t('Common_RequestedBy')}
                        value={selectedPerson}
                        onChange={(_: any, value: any) => {
                            if (value) {
                                onPersonSelect({
                                    id: value.id,
                                    friendlyName: value.friendlyName,
                                    imageThumbUrl: value.imageThumbUrl,
                                })
                            }
                        }}
                    />
                </Box>
            )}
        </>
    )
}

export default ComputerSessionHistoryFilters
