import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUiActionsByResource } from 'hooks'
import { Skeleton } from '@material-ui/lab'
import { Box, makeStyles, MenuItem, Popover } from '@material-ui/core'
import { Button } from 'components'
import { EidWorkflow } from 'packages/eid-ui'
import config from 'config'
import { ArrowIcon } from 'packages/eid-icons'
import { useHistory } from 'react-router'
import { getFullPathFromUrl, useIsSmallScreen, useQuery } from 'packages/core'
import appConfig from 'config'

const useStyles = makeStyles({
    root: {
        minWidth: '177px',
    },
    menuItem: {
        minWidth: '177px',
    },
})

const useButtonStyles = makeStyles({
    root: {
        padding: '4px 16px',
        color: '#3b454d',
        fontSize: '14px',
        textTransform: 'capitalize',
        backgroundColor: '#ffffff',
        border: 'solid 1px #d8d8dd',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
        '& >span': {
            display: 'flex',
            justifyContent: 'space-between',
        },
        '&:hover': {
            backgroundColor: '#ffffff',
            border: 'solid 1px #d8d8dd',
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
        },
    },
})

export type ActionProps = {
    application: {
        id: string
        resourceID: number
    }
}

export const Actions: FC<ActionProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const isSmallScreen = useIsSmallScreen()

    const buttonClasses = useButtonStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const openMenu = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const { data, isLoading } = useUiActionsByResource(props.application.id)
    const query = useQuery()
    const history = useHistory()

    const onClick = (name: any) => {
        query.set('workflow', name)
        query.set('returnUrl', getFullPathFromUrl())
        history.push(
            `${appConfig.APP_SUBPATH}/applications?${query.toString()}`,
        )
    }

    return (
        <Box className={classes.root}>
            {isLoading ? (
                <Skeleton height={40} />
            ) : (
                <Fragment>
                    <Button
                        onClick={openMenu}
                        fullWidth
                        classes={buttonClasses}
                        endIcon={
                            <ArrowIcon
                                color="#aab0b4"
                                direction={Boolean(anchorEl) ? 'up' : 'down'}
                            />
                        }
                    >
                        {t('Common_Actions')}
                    </Button>
                    <Popover
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        {data.map((d: any) =>
                            isSmallScreen ? (
                                <EidWorkflow
                                    key={d.id}
                                    baseEidUrl={config.BASE_EID_URL}
                                    title={d.friendlyName}
                                    workflowName={d.requestWorkflowName}
                                    workflowParams={[
                                        `SelectedResourceID=${props.application.resourceID}`,
                                    ]}
                                >
                                    <MenuItem
                                        onClick={closeMenu}
                                        className={classes.menuItem}
                                    >
                                        {d.friendlyName}
                                    </MenuItem>
                                </EidWorkflow>
                            ) : (
                                <MenuItem
                                    key={d.id}
                                    onClick={() => {
                                        closeMenu()
                                        onClick(d.requestWorkflowName)
                                    }}
                                    className={classes.menuItem}
                                >
                                    {d.friendlyName}
                                </MenuItem>
                            ),
                        )}
                    </Popover>
                </Fragment>
            )}
        </Box>
    )
}
