import React, { useState } from 'react'
import { Tooltip, WarningModal } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useTargetPerson, useAddItemToCart, useCart } from 'hooks'
import cartHelpers from 'containers/Cart/cartHelpers'
import { ItemDetails } from 'components'
import { Box, Typography, useTheme } from '@material-ui/core'

const RevokeButton = ({ resourceType, item, buttonStyles, postRevoke }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const [showConfirm, setConfirm] = useState(false)
    const [targetPerson] = useTargetPerson()
    const { data: cart } = useCart()

    const [addItemToCart, { isLoading: isAdding }] = useAddItemToCart()

    const itemAlreadyInCart = resourceType.alreadyInCart(
        cart.cartItems,
        item.resource,
        item.assignment,
    )

    const getRevokeTooltipTitle = () =>
        itemAlreadyInCart ? t('Common_ItemAlreadyInCart') : ''

    const handleRevoke = () => {
        try {
            const itemToRevoke = cartHelpers.accessItemToCartItem(
                resourceType?.name !== 'AzureRoles'
                    ? resourceType?.name
                    : item.resource.roleGroupType === 'Rbac'
                    ? 'AzureRbacRoles'
                    : 'AzureAdminRoles',
                targetPerson,
                item,
                'Remove',
            )
            if (!itemToRevoke) return

            addItemToCart(itemToRevoke).then(() => {
                setConfirm(false)
                postRevoke()
            })
        } catch (err) {}
    }

    return (
        <>
            <Tooltip title={getRevokeTooltipTitle()}>
                <Box style={buttonStyles}>
                    <ItemDetails.ActionButton
                        fontColor={
                            itemAlreadyInCart
                                ? '#ffffff'
                                : theme.palette.error.main
                        }
                        border={`solid 1px ${
                            itemAlreadyInCart
                                ? ' #c5cbda'
                                : theme.palette.error.main
                        }`}
                        loaderColor={theme.palette.error.main}
                        bgColor={itemAlreadyInCart ? '#c5cbda' : '#ffffff'}
                        width="100%"
                        height="32px"
                        onClick={() => setConfirm(true)}
                        disabled={getRevokeTooltipTitle() !== ''}
                    >
                        <Box display="flex" alignItems="center">
                            <Typography
                                style={{
                                    color: itemAlreadyInCart
                                        ? '#ffffff'
                                        : theme.palette.error.main,
                                    fontSize: '14px',
                                }}
                            >
                                {t('Common_Revoke')}
                            </Typography>
                        </Box>
                    </ItemDetails.ActionButton>
                    <WarningModal
                        title={t('Common_Confirm')}
                        description={t('Common_ConfirmationBeforeRevocation')}
                        yesLabel={t('Common_Yes')}
                        noLabel={t('Common_No')}
                        open={showConfirm}
                        cancel={() => setConfirm(false)}
                        proceeding={isAdding}
                        proceed={handleRevoke}
                    />
                </Box>
            </Tooltip>
        </>
    )
}
export default RevokeButton
