import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { isNilOrEmpty } from '../../core'
import {
    AppMenuIcon,
    EmpowerIdAppLogoIcon,
    CombinedShapeIcon,
    Icon,
} from '../../eid-icons'

const MenuRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '56px',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.04)',
    borderRadius: 5,
    border: '1px solid #dfe0e2',
    backgroundColor: '#ffffff',
    transition: 'box-shadow .25s ease-in-out',

    '&:hover, &:focus': {
        boxShadow: '2px 2px 16px 0 rgba(0, 0, 0, 0.1)',
    },
})

const MenuHeader = styled('div')({
    backgroundColor: '#f7f8fa',
    borderBottom: 'solid 1px #dfe0e2',
    borderTopLeftRadius: 5.3,
    borderTopRightRadius: 5.3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 47,
    marginBottom: 3,

    '& > svg': {
        width: 20,
        height: 20,
    },
})

const Divider = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '3px',
    marginBottom: '4px',
})

const AppIconLink = styled('a')({
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '48px',
    margin: 0,
    textDecoration: 'none',
    position: 'relative',

    '&:first-of-type': {
        marginTop: '0px',
        marginBottom: '3px',
    },

    '& > svg': {
        zIndex: 2,
        transition: 'color .1s ease-in-out',
    },

    '&::after': {
        content: 'attr(aria-label)',
        display: 'block',
        lineHeight: '51px',
        whiteSpace: 'nowrap',
        paddingLeft: 'calc(100% + 8px)',
        paddingRight: '14px',
        position: 'absolute',
        top: '0',
        left: '-6px',
        transform: 'scale(0.8, 1)',
        transformOrigin: '24px 50%',
        opacity: 0,
        transition:
            'transform 0.2s ease-in-out, opacity .2s ease-in-out, color .1s ease-in-out, visibility .2s',
        fontWeight: 'bold',
        color: '#fff',
        height: 'calc(100% + 2px)',
        borderRadius: '4px',
        background: '#fff',
        boxShadow: '0 2px 8px 0 rgba(2, 153, 255, 0.15)',
        backgroundImage: 'linear-gradient(105deg, #019cae, #307fc1 100%)',
        zIndex: 1,
        letterSpacing: '0.05em',
        visibility: 'hidden',
    },

    '&:hover, &:focus': {
        borderRadius: 4,
        border: 'solid 1.3px rgba(214, 214, 214, 0.55)',
        backgroundColor: '#fff',
        borderColor: 'transparent',
        color: '#307fc1',

        '& > svg': {
            color: '#fff',
        },

        '&::after': {
            transform: 'scale(1)',
            opacity: 1,
            transitionDuration: '.25s',
            visibility: 'visible',
        },
    },
})

const CurrentAppIconLink = styled(AppIconLink)({
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '3px',
        bottom: '8px',
        width: 'calc(100% - 6px)',
        height: 'calc(100% - 16px)',
        borderRadius: 4,
        backgroundColor: ' #f8f8f8',
        border: '1px solid #d0d9e2',
    },
})

const GlobalMenu = ({ eid, apps, loading, current }) => {
    const isCurrentApp = (appName) => appName === current

    const renderLink = (app) => {
        const LinkComponent = isCurrentApp(app.name)
            ? CurrentAppIconLink
            : AppIconLink

        return (
            <LinkComponent
                aria-label={app.displayName ? app.displayName : app.name}
                href={app.url}
                key={app.name}
            >
                <Icon
                    name={app.name}
                    color={isCurrentApp(app.name) ? '#307fc1' : '#000'}
                />
            </LinkComponent>
        )
    }

    return (
        <MenuRoot>
            <MenuHeader>
                <AppMenuIcon color="#959598" />
            </MenuHeader>
            {loading ? (
                <Box marginTop={2} padding={1} width="100%">
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        height={64}
                        style={{ borderRadius: '2px' }}
                    />
                </Box>
            ) : (
                <>
                    {!isNilOrEmpty(eid) && (
                        <>
                            <AppIconLink
                                aria-label={
                                    eid.displayName ? eid.displayName : eid.name
                                }
                                href={eid.url}
                            >
                                <EmpowerIdAppLogoIcon color="#000" />
                            </AppIconLink>
                            <Divider>
                                <CombinedShapeIcon color="#DFE0E2" />
                            </Divider>
                        </>
                    )}
                    {apps.map((app) => renderLink(app))}
                </>
            )}
        </MenuRoot>
    )
}

GlobalMenu.propTypes = {
    eid: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        displayName: PropTypes.string,
    }),
    apps: PropTypes.array,
    current: PropTypes.string.isRequired,
}

export default GlobalMenu
