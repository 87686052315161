import { Box } from '@material-ui/core'
import { useApplicationManagementRoleAssignments, useTargetPerson } from 'hooks'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { ProtectedApplicationAssignmentInfo } from '../AssignmentTile'

const take = 3

export type ApplicationManagementRolesAssignmentsProps = {
    applicationId: string
    applicationsType: any
}

export const ApplicationManagementRolesAssignments: FC<ApplicationManagementRolesAssignmentsProps> = (
    props: ApplicationManagementRolesAssignmentsProps,
) => {
    const [page, setPage] = useState(1)
    const { applicationId, applicationsType } = props

    const [targetPerson] = useTargetPerson()
    const { t } = useTranslation()
    const { data, isLoading } = useApplicationManagementRoleAssignments(
        applicationId,
        targetPerson.id,
        (page - 1) * take,
        take,
    )
    const pageCount = data?.totalCount ? Math.ceil(data.totalCount / take) : 0
    return (
        <Box>
            {isLoading ? (
                <Skeleton height={40} />
            ) : data?.data?.length ? (
                <Box>
                    <ProtectedApplicationAssignmentInfo
                        totalCount={data?.totalCount}
                        setPage={(value: any) => setPage(value)}
                        pageCount={pageCount}
                        resourceAssignment={data?.data || []}
                        title={t('Common_AppManagementRoles')}
                        resourceType={applicationsType}
                        manageAccessRedirectTab="appManagementRoles"
                    />
                </Box>
            ) : null}
        </Box>
    )
}
