import { useEffect, useState } from 'react'
import { TimeConstrainedControl } from 'components/TimeConstrainedControl/TimeConstrainedControl'
import { StartDateControl } from 'components/TimeConstrainedControl/StartDateControl'
import { EndDateControl } from 'components/TimeConstrainedControl/EndDateControl'
import { isNilOrEmpty } from 'packages/core'
import moment from 'moment'
import { IAccessRequestPolicy } from 'core'
import { useTranslation } from 'react-i18next'

const useTimeConstrainedControl = (
    policy?: IAccessRequestPolicy,
    defaultTimeConstrained = false,
) => {
    const { t } = useTranslation()

    const [timeConstrained, setTimeConstrained] = useState(true)

    useEffect(() => {
        if (policy && policy.isRequestAllowed && policy.isTimeConstrained) {
            if (defaultTimeConstrained) {
                setTimeConstrained(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy])

    const currentDatetime = moment.utc().second(0)

    const [
        { selectedStartDate, selectedEndDate },
        setSelectedDate,
    ]: any = useState({
        selectedStartDate: currentDatetime,
        selectedEndDate: null,
    })

    useEffect(() => {
        if (policy && selectedEndDate === null) {
            if (policy.defaultValueInMinutes) {
                const initialEndDate = selectedStartDate
                    .clone()
                    .add(policy.defaultValueInMinutes, 'minutes')

                setSelectedDate((prev: any) => {
                    return { ...prev, selectedEndDate: initialEndDate }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy, selectedEndDate])

    if (!policy) {
        return {
            timeConstrained,
            selectedStartDate,
            selectedEndDate,
            hasInvalidDates: false,
            violatesPolicy: false,
            timeConstrainedControl: undefined,
            startDateControl: undefined,
            endDateControl: undefined,
        }
    }

    const violatesPolicy =
        policy.maximumValueInMinutes &&
        selectedEndDate?.isAfter(
            selectedStartDate
                .clone()
                .add(policy.maximumValueInMinutes, 'minutes'),
        )

    const hasInvalidDates =
        timeConstrained &&
        (isNilOrEmpty(selectedEndDate) ||
            selectedStartDate.isAfter(selectedEndDate) ||
            violatesPolicy)

    let maxEndDate
    if (policy.isTimeConstrained && policy.maximumValueInMinutes) {
        maxEndDate = selectedStartDate
            .clone()
            .add(policy.maximumValueInMinutes, 'minutes')
    }

    let validationMessage = ''
    if (violatesPolicy) {
        validationMessage = t('Common_PolicyAllowsMaxDurationInMinError', {
            count: policy.maximumValueInMinutes,
        })
    }

    const handleStartDate = (startDateToSet: any) => {
        setSelectedDate((prev: any) => {
            return { ...prev, selectedStartDate: startDateToSet }
        })

        if (policy && policy.defaultValueInMinutes) {
            let endDateToSet = startDateToSet
                .clone()
                .add(policy.defaultValueInMinutes, 'minutes')

            setSelectedDate((prev: any) => {
                return { ...prev, selectedEndDate: endDateToSet }
            })
        }
    }

    const handleEndDate = (endDateToSet: any) => {
        setSelectedDate((prev: any) => {
            return { ...prev, selectedEndDate: endDateToSet }
        })
    }

    const timeConstrainedControl = (
        <TimeConstrainedControl
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={handleStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={handleEndDate}
            maxEndDate={maxEndDate}
            hasInvalidDates={hasInvalidDates}
            validationMessage={validationMessage}
            toggle={timeConstrained}
            setToggle={setTimeConstrained}
            disabled={true}
            hideSwitch={true}
            isEndDateDisabled={
                policy.isTimeConstrained && !policy.isEndDateSelectable
            }
        />
    )
    const startDateControl = (
        <StartDateControl
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={handleStartDate}
        />
    )

    const endDateControl = (
        <EndDateControl
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={handleEndDate}
            maxEndDate={maxEndDate}
            hasInvalidDates={hasInvalidDates}
            disabled={policy.isTimeConstrained && !policy.isEndDateSelectable}
        />
    )

    return {
        timeConstrained,
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        violatesPolicy,
        timeConstrainedControl,
        startDateControl,
        endDateControl,
    }
}

export {
    useTimeConstrainedControl,
    TimeConstrainedControl,
    StartDateControl,
    EndDateControl,
}
