import React from 'react'
import { styled } from '@material-ui/core'
import { WithCloseButton } from 'packages/eid-ui'

const Wrapper = styled('div')(({ styles }) => ({
    backgroundColor: '#ffffff',
    width: '100%',
    maxWidth: '425px',
    margin: 'auto',
    ...styles,
    overflow: 'hidden',
}))

const ItemsDetailsMobileWrapper = ({
    children,
    styles,
    toggleDrawer,
    withCloseIcon,
}) => {
    return (
        <Wrapper styles={styles}>
            {withCloseIcon ? (
                <WithCloseButton
                    color="#000000"
                    iconPosition={{ top: '20px', right: 0 }}
                    onClose={toggleDrawer}
                >
                    {children}
                </WithCloseButton>
            ) : (
                children
            )}
        </Wrapper>
    )
}

export default ItemsDetailsMobileWrapper
