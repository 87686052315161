import React from 'react'
import { Grid, Box, Link, makeStyles } from '@material-ui/core'
import { useTrimmedDownList } from 'packages/core'
import ItemDetails from './ItemDetails'
import config from 'config'

const useOverflowStyles = makeStyles(() => ({
    root: ({ expanded }) => ({
        maxHeight: '200px',
        overflowY: 'auto',
        transition: 'padding 0.5s ease',
        ...(expanded && {
            backgroundColor: '#f5f5f6',
            borderRadius: '8px',
            boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.05)',
            border: '1px solid #d8d8dd',
            paddingTop: '8px',
            paddingBottom: '8px',
            marginBottom: '8px',
        }),
    }),
}))

const PeopleList = ({ list, expandedStyles = true }) => {
    const minCount = 2;
    const { data, showAll, showLess, remainingItemsCount } = useTrimmedDownList(
        list.map((p) => (
            <Grid item key={p.id}>
                <ItemDetails.Person
                    name={p.friendlyName}
                    imgSrc={`${config.BASE_EID_URL}${p.imageThumbUrl}`}
                />
            </Grid>
        )),
        minCount,
        <Grid item>
            <Box fontSize="14px" color="#5d6870">
                -
            </Box>
        </Grid>,
    )

    const overflowClasses = useOverflowStyles({
        expanded: Boolean(data && data.length > 2 && expandedStyles),
    })

    return (
        <Grid container spacing={3} classes={overflowClasses}>
            {data}
            {remainingItemsCount !== 0 && (
                <Box height="100%" display="flex" width='100%' padding='12px'>
                    <Link
                        component="button"
                        underline="always"
                        onClick={showAll}
                    >
                        +{remainingItemsCount} more
                    </Link>
                </Box>
            )}
            {remainingItemsCount === 0 && list.length > minCount && (
                <Box height="100%" display="flex" width='100%' padding='12px'>
                    <Link
                        component="button"
                        underline="always"
                        onClick={showLess}
                    >
                        Less
                    </Link>
                </Box>

            )}
        </Grid>
    )
}

export default PeopleList
