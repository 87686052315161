import { getKeyFromSessionStorage } from "packages/core"


export const initialState = {
    appConfig: null,
    targetPerson: null,
    disableSwitchTargetPerson: null,
    masterPassword: getKeyFromSessionStorage('MasterPassword'),
    urlFilters: {},
    savedFilters: null,
    devFeaturesEnabled: false,
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_APP_CONFIG':
            return {
                ...state,
                appConfig: payload,
            }

        case 'SET_TARGET_PERSON':
            return {
                ...state,
                targetPerson: payload,
            }

        case 'SET_MASTER_PASSWORD':
            sessionStorage.setItem('MasterPassword', payload)
            return {
                ...state,
                masterPassword: payload,
            }

        case 'SET_DISABLE_SWITCH_TARGET_PERSON':
            return {
                ...state,
                disableSwitchTargetPerson: payload,
            }

        case 'SET_URL_FILTERS':
            return {
                ...state,
                urlFilters: payload,
            }

        case 'ENABLE_DEV_FEATURES':
            return {
                ...state,
                devFeaturesEnabled: true,
            }
        case 'SAVE_FILETRS':
            return {
                ...state,
                savedFilters: payload,
            }

        default:
            return state
    }
}

export default reducer
