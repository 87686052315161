import React, { useEffect, useState } from 'react'
import { Box, useTheme } from '@material-ui/core'
import {
    Drawer,
    Loader,
    ValidationMessage,
    ValidationMessages,
    WithCloseButton,
} from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import TextField from './TextField'
import { useTranslation } from 'react-i18next'
import {
    isDevelopmentEnv,
    isNilOrEmpty,
    isTestEnv,
    isWhitespace,
    useIsSmallScreen,
} from 'packages/core'
import CartItem from './CartItem'
import CartSubmissionMessageModal from 'containers/Cart/CartSubmissionMessageModal'
import {
    useCart,
    useDefaultLineManager,
    useEnvironment,
    useEvaluateCart,
    usePreviousEvaluationResults,
    useSubmitCart,
    useTargetPerson,
    useEidGlobalSetting,
} from 'hooks'
import ApproverSelection from './ApproverSelection'
import BusinessRequestTypes from './BusinessRequestTypes'
import DueDate from './DueDate'
import { MyContainer, MyTypography, useStyles } from './styles'
import EmptyCartButton from './EmptyCartButton'
import useSubcomponents from 'useSubcomponents'
import { CartViolations } from './CartViolations'
import CartButton from './CartButton'

const COMMENT_MAX_LENGTH = 500
const BUSINESS_REQUEST_NAME_MAX_LENGTH = 500

const initialCartState = {
    selectedApprover: null,
    businessRequestType: null,
    cartDescription: '',
    cartComment: '',
    dueDate: null,
    acknowledged: false,
    showValidationErrors: false,
}

const showCartBusinessRequestType = 'ITShop_Cart_Show_BusinessRequestType'

const shouldShowEvaluationPrompt = (cart) => {
    return cart.cartItems.length > 0 && cart.requiresEvaluation
}

const shouldShowSubmitData = (cart) =>
    cart.cartItems.length > 0 && !cart.requiresEvaluation

const getUniqueRisks = (risks) => {
    return risks.reduce((array, item) => {
        if (!array.some((r) => r.id === item.id)) {
            array.push(item)
        }
        return array
    }, [])
}

const getUniqueRisksForItem = (risks, itemId) =>
    risks
        .filter((ri) => ri.cartItemId === itemId)
        .reduce((array, item) => {
            if (
                !array.some(
                    (r) =>
                        r.id === item.id &&
                        r.riskReason_ResourceFriendlyName ===
                            item.riskReason_ResourceFriendlyName,
                )
            ) {
                array.push(item)
            }
            return array
        }, [])

const ShoppingCartDrawer = ({ open, toggleDrawer }) => {
    const {
        hasAccessToSeeApproverControl,
        hasAccessToCartDueDate,
        hasAccessToSeeBusinessRequestTypesControl,
    } = useSubcomponents()

    const {
        data: eidCartSettings,
        isLoading: isLoadingCartSetting,
    } = useEidGlobalSetting()

    let showLineManager = false
    let showRequestTypes = false

    if (!isLoadingCartSetting && eidCartSettings) {
        const showLineManagerSetting =
            eidCartSettings.find(
                (x) => x.name === 'ITShop_Cart_Show_LineManager',
            )?.value === 'true'

        const showRequestTyeSetting =
            eidCartSettings.find((x) => x.name === showCartBusinessRequestType)
                ?.value === 'true'

        showLineManager =
            showLineManagerSetting && hasAccessToSeeApproverControl
        showRequestTypes =
            showRequestTyeSetting && hasAccessToSeeBusinessRequestTypesControl
    }

    const classes = useStyles()

    const { data: env } = useEnvironment()
    const isDevOrTest = isDevelopmentEnv(env) || isTestEnv(env)

    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()

    const theme = useTheme()
    const isSmallScreen = useIsSmallScreen()

    const { data: cart } = useCart()

    const [evaluateShoppingCart, { isLoading: evaluating }] = useEvaluateCart()
    const {
        data: evaluateCartData,
        isFetching: isLoadingEvaluation,
    } = usePreviousEvaluationResults()

    const [
        submitCart,
        { data: submitResponse, isLoading: submittingCart, error: submitError },
    ] = useSubmitCart()

    const {
        data: lineManager,
        isLoading: loadingLineManager,
    } = useDefaultLineManager(targetPerson.id, showLineManager)

    const [
        {
            selectedApprover,
            businessRequestType,
            cartDescription,
            cartComment,
            dueDate,
            acknowledged,
            showValidationErrors,
        },
        setCartState,
    ] = useState(initialCartState)

    const setShowValidationErrors = (newShowValidationErrors) =>
        setCartState((prev) => ({
            ...prev,
            showValidationErrors: newShowValidationErrors,
        }))

    const setAcknowledged = (newAcknowledged) =>
        setCartState((prev) => ({ ...prev, acknowledged: newAcknowledged }))

    const setSelectedApprover = (approver) =>
        setCartState((prev) => ({
            ...prev,
            selectedApprover: approver,
        }))

    useEffect(() => {
        setCartState(initialCartState)
    }, [open])

    const setBusinessRequestType = (brType) =>
        setCartState((prev) => ({ ...prev, businessRequestType: brType }))

    const setCartDescription = (description) =>
        setCartState((prev) => ({ ...prev, cartDescription: description }))

    const setCartComment = (comment) =>
        setCartState((prev) => ({ ...prev, cartComment: comment }))

    const setCartDueDate = (dueDate) =>
        setCartState((prev) => ({ ...prev, dueDate: dueDate }))

    const [cartItemsComment, setCartItemsComment] = useState({})

    const [toggle, setToggle] = useState(false)

    const closeCart = () => {
        toggleDrawer()
        setCartState(initialCartState)
    }

    const [submissionMessage, setSubmissionMessage] = useState({
        data: null,
        open: false,
        error: false,
    })

    useEffect(() => {
        if (submitResponse) {
            setSubmissionMessage({
                data: submitResponse.data,
                open: true,
                error: false,
            })
            closeCart(!toggle)
        }
        if (submitError) {
            setSubmissionMessage({
                data: null,
                open: true,
                error: true,
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitResponse, submitError])

    if (!cart) {
        return isSmallScreen ? (
            open && <Loader />
        ) : (
            <Drawer
                width="768px"
                open={open}
                toggleDrawer={closeCart}
                closeOnOutsideClick
                closeLabel={t('Common_Close')}
            >
                <Loader />
            </Drawer>
        )
    }

    const isCartItemsCommentInvalid = Object.keys(cartItemsComment).filter(
        (itemComment) =>
            cartItemsComment[itemComment].length > COMMENT_MAX_LENGTH ||
            isWhitespace(cartItemsComment[itemComment]),
    )
    const isBrNameInvalid =
        !cartDescription ||
        cartDescription.length > BUSINESS_REQUEST_NAME_MAX_LENGTH ||
        isWhitespace(cartDescription)

    const isCartCommentInvalid =
        cartComment &&
        (cartComment.length > COMMENT_MAX_LENGTH || isWhitespace(cartComment))

    const isViolationsNotAcknowledged =
        evaluateCartData && evaluateCartData.risks.length > 0 && !acknowledged

    const isJustificationCommentMissing =
        evaluateCartData &&
        evaluateCartData.risks.length > 0 &&
        cart.cartItems.filter(
            (c) =>
                evaluateCartData.risks.filter((ri) => ri.cartItemId === c.id)
                    .length > 0 && !Boolean(c.comment),
        ).length > 0

    const notSubmittable =
        isBrNameInvalid ||
        isCartCommentInvalid ||
        loadingLineManager ||
        !isNilOrEmpty(isCartItemsCommentInvalid) ||
        evaluating ||
        submittingCart ||
        isViolationsNotAcknowledged ||
        isJustificationCommentMissing ||
        isLoadingEvaluation

    const isValidationErrors =
        isBrNameInvalid ||
        isCartCommentInvalid ||
        !isNilOrEmpty(isCartItemsCommentInvalid) ||
        isViolationsNotAcknowledged ||
        isJustificationCommentMissing

    const handleSubmit = () => {
        if (notSubmittable) {
            setShowValidationErrors(true)
        }

        if (submittingCart || notSubmittable) return

        const submissionRequest = {
            businessRequestName: cartDescription,
            comment: cartComment ? cartComment : null,
            targetPersonId: targetPerson.id,
            dueDate: dueDate,
        }

        if (showLineManager) {
            submissionRequest.approvers =
                selectedApprover &&
                (!lineManager || selectedApprover.id !== lineManager.id)
                    ? [selectedApprover.id]
                    : []
        }

        if (isDevOrTest && businessRequestType) {
            submissionRequest.businessRequestTypeId = businessRequestType.id
        }

        submitCart(submissionRequest)
    }

    return (
        <>
            <Drawer
                width="768px"
                open={open}
                toggleDrawer={closeCart}
                closeOnOutsideClick
                className={classes.drawer}
                top={56}
            >
                <WithCloseButton
                    iconPosition={{
                        right: '18px',
                        top: '50%',
                    }}
                    iconColor="rgb(139, 144, 154)"
                    onClose={closeCart}
                >
                    <Box className={classes.roleReceiver}>
                        <MyTypography
                            align="left"
                            display="block"
                            style={{
                                wordBreak: 'break-word',
                                fontSize: '12px',
                                color: '#8b909a',
                            }}
                        >
                            {t('Common_RoleReceiver')}
                        </MyTypography>
                        <MyTypography
                            style={{
                                wordBreak: 'break-word',
                                fontSize: '24px',
                                lineHeight: 1.25,
                                fontWeight: 600,
                            }}
                        >
                            {targetPerson.friendlyName}
                        </MyTypography>
                    </Box>
                </WithCloseButton>
                <Box className={classes.roleReceiverDivider} />
                <MyContainer>
                    {cart.cartItems.length === 0 ? (
                        <MyTypography
                            align="left"
                            variant="caption"
                            display="block"
                        >
                            {t('Common_CartIsEmpty', { text: 'Your' })}!
                        </MyTypography>
                    ) : (
                        <>
                            {cart.cartItems.map((item, index) => (
                                <CartItem
                                    onToggle={() => setToggle(!toggle)}
                                    item={item}
                                    index={index + 1}
                                    key={index}
                                    onCommentChange={(id, comment) => {
                                        const newComments = {
                                            ...cartItemsComment,
                                        }
                                        newComments[id] = comment
                                        setCartItemsComment(newComments)
                                    }}
                                    risks={
                                        evaluateCartData &&
                                        getUniqueRisksForItem(
                                            evaluateCartData.risks,
                                            item.id,
                                        )
                                    }
                                />
                            ))}
                        </>
                    )}
                </MyContainer>

                {cart.cartItems.length > 0 &&
                    evaluateCartData &&
                    evaluateCartData.risks &&
                    evaluateCartData.risks.length > 0 && (
                        <Box className={classes.box}>
                            <Box marginBottom="24px">
                                <CartViolations
                                    risks={getUniqueRisks(
                                        evaluateCartData.risks,
                                    )}
                                    acknowledged={acknowledged}
                                    setAcknowledged={setAcknowledged}
                                />
                            </Box>
                        </Box>
                    )}

                {shouldShowEvaluationPrompt(cart) && (
                    <Box className={classes.box}>
                        <Box
                            marginTop="48px"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <CartButton
                                icon={
                                    <Icon
                                        width={20}
                                        height={20}
                                        name="Tasks"
                                        color="#ffffff"
                                    />
                                }
                                bgcolor={theme.palette.primary.main}
                                label={t('Common_EvaluateRequest')}
                                loading={evaluating}
                                disabled={evaluating}
                                onClick={() => {
                                    setAcknowledged(false)
                                    evaluateShoppingCart()
                                }}
                            />
                            <EmptyCartButton />
                        </Box>
                    </Box>
                )}

                {shouldShowSubmitData(cart) && showLineManager && (
                    <Box
                        data-protectedsubcomponent={`ITShop_Cart_Show_LineManager AND ${hasAccessToSeeApproverControl}`}
                        className={classes.lineManagerContainer}
                    >
                        <Box
                            className={classes.lineManager}
                            style={{ maxWidth: '100%' }}
                        >
                            <ApproverSelection
                                lineManager={lineManager}
                                loadingLineManager={loadingLineManager}
                                selectedApprover={selectedApprover}
                                setSelectedApprover={setSelectedApprover}
                            />
                        </Box>
                    </Box>
                )}

                {showRequestTypes && shouldShowSubmitData(cart) && (
                    <Box
                        data-protectedsubcomponent={`EidSetting-${showCartBusinessRequestType} AND ${hasAccessToSeeBusinessRequestTypesControl}`}
                        className={classes.lineManagerContainer}
                    >
                        <Box className={classes.lineManager}>
                            <BusinessRequestTypes
                                selectedRequestType={businessRequestType}
                                setSelectedRequestType={setBusinessRequestType}
                            />
                        </Box>
                    </Box>
                )}

                {shouldShowSubmitData(cart) && (
                    <Box className={classes.box}>
                        <Box className={classes.formContainer}>
                            <Box className={classes.requestName}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    placeholder={t(
                                        'Common_EnterBusinessRequestName',
                                    )}
                                    maxCharacters={
                                        BUSINESS_REQUEST_NAME_MAX_LENGTH
                                    }
                                    margin="none"
                                    multiline
                                    rowsMax={5}
                                    value={cartDescription}
                                    onChange={(e) =>
                                        setCartDescription(e.target.value)
                                    }
                                    style={{
                                        backgroundColor: '#ffffff',
                                    }}
                                />
                            </Box>
                            {hasAccessToCartDueDate && (
                                <Box
                                    data-protectedsubcomponent={
                                        hasAccessToCartDueDate
                                    }
                                    className={classes.dueDate}
                                >
                                    <DueDate
                                        dueDate={dueDate}
                                        setDueDate={setCartDueDate}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box className={classes.cartComment}>
                            <TextField
                                fullWidth
                                multiline
                                rowsMax={5}
                                rows={2}
                                maxCharacters={COMMENT_MAX_LENGTH}
                                placeholder={t('Common_AddComment')}
                                margin="none"
                                value={cartComment}
                                onChange={(e) => setCartComment(e.target.value)}
                                style={{
                                    backgroundColor: '#ffffff',
                                }}
                            />
                        </Box>

                        {showValidationErrors && isValidationErrors && (
                            <ValidationErrors
                                isCartItemsCommentInvalid={
                                    isCartItemsCommentInvalid
                                }
                                isJustificationCommentMissing={
                                    isJustificationCommentMissing
                                }
                                isViolationsNotAcknowledged={
                                    isViolationsNotAcknowledged
                                }
                                isBrNameInvalid={isBrNameInvalid}
                                isCartCommentInvalid={isCartCommentInvalid}
                            />
                        )}
                        <Box
                            marginTop="48px"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <CartButton
                                icon={
                                    <Icon
                                        width={20}
                                        height={20}
                                        name="SubmitCart"
                                        color="#8b909a"
                                    />
                                }
                                bgcolor={theme.palette.primary.main}
                                label={t('Common_Submit')}
                                loading={submittingCart}
                                onClick={handleSubmit}
                            />

                            <EmptyCartButton />
                        </Box>
                    </Box>
                )}
            </Drawer>
            <CartSubmissionMessageModal
                submissionMessage={submissionMessage}
                setSubmissionMessage={setSubmissionMessage}
                successMessage={t('Common_CartSubmitted')}
                errorMessage={t('Common_CartSubmissionError')}
            />
        </>
    )
}

export default ShoppingCartDrawer

const validationMessages = {
    cartItemsComment: 'Common_InvalidCartItemComment',
    justificationComments: 'Common_JustificationRequiredOnItems',
    violationsAcknowledgement: 'Common_AcknowledgementRequired',
    businessRequestName: 'Common_RequestNameRequired',
    cartComment: 'Common_InvalidCartComment',
}

const ValidationErrors = ({
    isCartItemsCommentInvalid,
    isJustificationCommentMissing,
    isViolationsNotAcknowledged,
    isBrNameInvalid,
    isCartCommentInvalid,
}) => {
    const { t } = useTranslation()

    return (
        <ValidationMessages>
            {!isNilOrEmpty(isCartItemsCommentInvalid) && (
                <ValidationMessage
                    message={t(`${validationMessages['cartItemsComment']}`)}
                />
            )}

            {isJustificationCommentMissing && (
                <ValidationMessage
                    message={t(
                        `${validationMessages['justificationComments']}`,
                    )}
                />
            )}
            {isViolationsNotAcknowledged && (
                <ValidationMessage
                    message={t(
                        `${validationMessages['violationsAcknowledgement']}`,
                    )}
                />
            )}
            {isBrNameInvalid && (
                <ValidationMessage
                    message={t(`${validationMessages['businessRequestName']}`)}
                />
            )}

            {isCartCommentInvalid && (
                <ValidationMessage
                    message={t(`${validationMessages['cartComment']}`)}
                />
            )}
        </ValidationMessages>
    )
}
