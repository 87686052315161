import React from 'react'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    makeStyles,
    Checkbox,
    useTheme,
} from '@material-ui/core'
import clsx from 'clsx'
import { Loader } from 'packages/eid-ui'
import { Trans } from 'react-i18next'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        maxWidth: '220px',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '12px',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
})

const TableGrid = (props) => {
    const {
        headings,
        data,
        attributes,
        loading,
        rowSelection,
        fallbackMessage,
    } = props
    const classes = useStyles({})
    const theme = useTheme()

    const [selected, setSelected] = React.useState([])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.id)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    const handleClick = (item) => {
        const selectedIndex = selected.indexOf(item.id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, item.id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    const getValue = (attribute, item) => {
        if (attribute.resolve) {
            return attribute.resolve(item)
        }
        return item[attribute.name] ?? '-'
    }

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow className={classes.tableRow}>
                    {rowSelection && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                icon={<Icon name="CheckBox" />}
                                checkedIcon={
                                    <Icon
                                        name="CheckedBox"
                                        color={theme.palette.primary.main}
                                    />
                                }
                                checked={
                                    data.length > 0 &&
                                    selected.length === data.length
                                }
                                onChange={handleSelectAllClick}
                            />
                        </TableCell>
                    )}

                    {headings.map((heading, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                            align={heading.align}
                            data-protectedsubcomponent={
                                heading?.requireAccess?.control || ''
                            }
                        >
                            {heading}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {loading && (
                    <TableRow>
                        <TableCell
                            colSpan={headings.length}
                            style={{ textAlign: 'center' }}
                        >
                            <Loader />
                        </TableCell>
                    </TableRow>
                )}
                {data &&
                    (data.length === 0 ? (
                        <TableRow className={classes.tableRow}>
                            <TableCell
                                className={classes.tableCell}
                                colSpan={headings.length}
                                style={{ textAlign: 'center' }}
                            >
                                {fallbackMessage}
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {data.map((item) => (
                                <TableRow
                                    key={item.id}
                                    className={classes.tableRow}
                                >
                                    {rowSelection && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                icon={<Icon name="CheckBox" />}
                                                checkedIcon={
                                                    <Icon
                                                        name="CheckedBox"
                                                        color={
                                                            theme.palette
                                                                .primary.main
                                                        }
                                                    />
                                                }
                                                checked={isSelected(item.id)}
                                                onClick={() =>
                                                    handleClick(item)
                                                }
                                            />
                                        </TableCell>
                                    )}

                                    {attributes.map((a, index) => (
                                        <TableCell
                                            key={`${item.id}${index}`}
                                            className={classes.tableCell}
                                            style={a.style}
                                        >
                                            {getValue(a, item)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </>
                    ))}
            </TableBody>
        </Table>
    )
}

TableGrid.defaultProps = {
    fallbackMessage: <Trans i18nKey="Common_NoDataFound" />,
}
export default TableGrid
