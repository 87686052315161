import appConfig from 'config'
import { Avatar, Box, makeStyles, Typography } from '@material-ui/core'
import { getFullImageUrl } from 'utils'
import moment from 'moment'
import { WorkflowIFrame } from 'packages/eid-ui'
import { GuacamolePlayer } from 'components'

const useStyles = makeStyles({
    root: {
        padding: '30px',
    },
    player: {
        backgroundColor: '#fff',
        border: 'solid 1px #e8e8ea',
        borderRadius: '8px',
        overflow: 'hidden',
    },
    innerconatiner: {
        padding: '24px',
    },
    innerheading: {
        color: '#000',
        fontSize: '14px',
        fontWeight: '600',
    },
    continfo: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
    },
    innername: {
        color: '#7d7c7c',
        fontSize: '13px',
        fontWeight: '600',
        '& span': {
            fontWeight: '500',
        },
    },
    avatar: {
        '& div': {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            marginRight: '10px',
        },
    },
})

const RecordingsTab = ({ item }) => {
    const classes = useStyles()
    const getLoginTimeValue = () => {
        try {
            const loginAttemptTime = item?.loginAttemptDate
                ? item?.loginAttemptDate
                : item?.startTimeUtc
            const currentTime = moment()
            const years = currentTime.diff(moment(loginAttemptTime), 'year')
            const months = currentTime.diff(moment(loginAttemptTime), 'months')
            const days = currentTime.diff(moment(loginAttemptTime), 'days')

            if (years > 0) {
                return `${years} ${years > 1 ? 'years' : 'year'} ago`
            } else if (months > 0) {
                return `${months} ${months > 1 ? 'months' : 'month'} ago`
            } else if (days > 0) {
                return `${days} ${days > 1 ? 'days' : 'day'} ago`
            }
        } catch (err) {
            return ''
        }
    }
    return (
        <Box className={classes.root}>
            <Box className={classes.player}>
                {item.canTerminateSession ? (
                    <GuacamolePlayer session={item} />
                ) : (
                    <WorkflowIFrame
                        baseEidUrl={appConfig.BASE_EID_URL}
                        workflowUrl={`${appConfig.BASE_EID_URL}/UI/Common/SAML2/ViewSessionRecording?id=${item.id}`}
                        height="54vh"
                        allowFullScreen={true}
                    />
                )}
                <Box className={classes.innerconatiner}>
                    <Box className={classes.continfo}>
                        <Box className={classes.avatar}>
                            <Avatar
                                src={getFullImageUrl(
                                    item?.checkedOutByPersonThumbUrl,
                                )}
                            />
                        </Box>
                        <Box>
                            <Typography
                                variant="h2"
                                className={classes.innername}
                            >
                                {item?.checkedOutByPersonName} -
                                <span> {getLoginTimeValue()} </span>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default RecordingsTab
